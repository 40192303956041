import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { FaRegQuestionCircle } from "react-icons/fa";
import { RootState } from "../../../../redux/store";
import { ChatContext } from "../../../../components/context/ChatContext";
import { OrderProps } from "../getOrders/GetOrders.admin";
import Langu from "../../../../components/languge/languge";
import Refund from "../../../../client/market/payPage/Refund/Refund";
import BtnComplete from "./BtnComplete";




const OrderDetalsMobileAdmin: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData);
    let params = useParams()
    const { t } = useTranslation()
    const conext = useContext(ChatContext)
    const { currency } = conext!
    const [dataOrder, setDataOrder] = useState<OrderProps>()
    const [refundTriggered, setRefundTriggered] = useState<boolean>(false);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Orders/GetOrder/${params.id}`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => setDataOrder(doc.data))
            .catch((err) => console.log(err))
        window.scrollTo(0, 0)
    }, [refundTriggered, params])

    const stagesArray = ["new", "viewed", "in preparation", "on the way", "delivery done"]


    const subTotal: number = dataOrder?.dataItems?.reduce((total, cartitem) => {
        return total + (cartitem.price || 0) * cartitem.quantity!
    }, 0)!

    const finelTotall: number = dataOrder?.dataItems?.reduce((total, cartitem) => {
        const totall = total +
            (((cartitem.newPrice || cartitem.price || 0) * cartitem.quantity!) -
                ((cartitem.newPrice || cartitem.price || 0) -
                    (((cartitem.offerForTwoProducts && cartitem.quantity! > 1) ? cartitem.priceOfferForTwoProducts! : (cartitem.newPrice || cartitem.price || 0)))));
        return totall;
    }, 0)!;

    const finelTotal = dataOrder?.useWallet ? finelTotall - dataOrder.valueWallet.currency! : finelTotall

    const ProductDiscount: number = dataOrder?.dataItems?.reduce((total, cartitem) => {
        return total + (cartitem.newPrice! ? ((cartitem.price! - cartitem.newPrice!) || 0) : 0) * cartitem.quantity!
    }, 0)!

    const offerForTwoProducts = dataOrder?.dataItems?.reduce((total, cartitem) => {
        const totall = total + ((cartitem.offerForTwoProducts && cartitem.quantity! >= 2 && (((cartitem.newPrice! || cartitem.price!) - cartitem.priceOfferForTwoProducts!) || 0)) || 0);
        return totall;
    }, 0)!;

    const isStageCompleted = (stage: string) => {
        const currentIndex = stagesArray.indexOf(dataOrder?.status!);
        const stageIndex = stagesArray.indexOf(stage);
        return stageIndex <= currentIndex;
    };

    const finshNumber = (num: number) => {
        const Currency = dataOrder?.currency
        if (currency === "ILS") {
            const afterNum = (Number(num))
            return (afterNum.toFixed(2)) + " " + Currency
        }
        else {
            return (Number(num)).toFixed(2) + " " + Currency
        }
    }

    const handleRefund = () => {
        setRefundTriggered((prev) => !prev);
    };

    return (<>
        <div style={{ backgroundColor: "#dddddd", padding: "20px 0" }}>
            <div style={{ backgroundColor: "white" }}>
                <div style={{ margin: " 0", borderBottom: "1px solid #dddddd", padding: "20px 10px " }}>
                    <div>
                        {t("Market.PayPage.id Order")} {dataOrder?._id}
                    </div>
                    <div>
                        {t("Home.phone")}: {dataOrder?.phone}
                    </div>
                    <div style={{}}>
                        {t("Market.Timing")} :{new Date(dataOrder?.createdAt!).toLocaleString('en-GB', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false
                        })}
                    </div>
                    <div>
                        {t("Market.PayPage.Payment")} : {t(`Market.PayPage.${dataOrder?.paymentMethod}`)}
                    </div>
                </div>
                <div style={{ padding: "20px 10px " }}>
                    <div style={{ display: "flex", fontSize: 17, fontWeight: 500, color: "green" }}>
                        <div style={{ width: 200 }}>
                            {t("Market.PayPage.Subtotal")}:
                        </div>
                        <div style={{ width: 100 }}>
                            {finshNumber(subTotal)}+
                        </div>
                    </div>
                    <div style={{ display: "flex", fontSize: 17, fontWeight: 500, color: "green" }}>
                        <div style={{ width: 200 }}>
                            {t("Market.PayPage.shipping")} :
                        </div>
                        <div style={{ width: 100 }}>
                            {finshNumber(dataOrder?.delivery!)}+
                        </div>
                    </div>
                    {
                        dataOrder?.useWallet &&
                        <div style={{ display: "flex", fontSize: 17, fontWeight: 500, color: "red" }}>
                            <div style={{ width: 200 }}>
                                {t("Market.PayPage.useWallet")} :
                            </div>
                            <div style={{ width: 100 }}>
                                {finshNumber(dataOrder.valueWallet.currency)}-
                            </div>
                        </div>
                    }
                    {
                        ProductDiscount !== 0 &&
                        <div style={{ display: "flex", fontSize: 17, fontWeight: 500, color: "red" }}>
                            <div style={{ width: 200 }}>
                                {t("Market.PayPage.Product discount discount")} :
                            </div>
                            <div style={{ width: 100 }}>
                                {finshNumber(ProductDiscount)}-
                            </div>
                        </div>
                    }
                    {
                        offerForTwoProducts !== 0 &&
                        <div style={{ display: "flex", fontSize: 17, fontWeight: 500, color: "red" }}>
                            <div style={{ width: 200 }}>
                                {t("Market.PayPage.Second product discount")} :
                            </div>
                            <div style={{ width: 100 }}>
                                {finshNumber(offerForTwoProducts)}-
                            </div>
                        </div>
                    }
                    <div style={{ display: "flex", fontSize: 20, fontWeight: 600 }}>
                        <div style={{ width: 200 }}>
                            {t("Market.PayPage.Total")} :
                        </div>
                        <div style={{ width: 120 }}>
                            {finshNumber(finelTotal + dataOrder?.delivery!)}
                        </div>
                    </div>
                </div>
            </div>
            {dataOrder?.dataItems?.map((product, index) => (
                <div key={index} style={{ backgroundColor: "white", marginTop: 20, padding: 10, display: "flex", flexDirection: 'column', gap: 10 }}>
                    <div style={{ fontSize: 20, fontWeight: 600 }}>
                        <Langu {...product?.product!} /> X {product.quantity}
                    </div>
                    <div style={{ display: "flex", gap: 10 }}>
                        <div style={{ width: "30%" }}>
                            <img key={index} draggable={false} alt="وصف الصورة" style={{ width: "100%" }} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${product.file ? product.file : ""}`} />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                            <div style={{ display: "flex", gap: 10 }}>
                                {t("Market.color")} : <div> {product.color && <div style={{ display: "flex", gap: 5 }}>  <div style={{ minWidth: 20, maxWidth: 20, minHeight: 20, maxHeight: 20, backgroundColor: product.color }}></div></div>}</div>
                            </div>
                            <div>
                                {t("Market.price")} :
                                {product.newPrice ?
                                    <div style={{ display: "flex", gap: 5 }}>
                                        <div style={{ textDecoration: "line-through", color: "gray", fontSize: 18, fontWeight: 500, textAlign: "start" }}>
                                            {finshNumber(product.price!)}
                                        </div>
                                        <div style={{ color: "red", fontSize: 18, fontWeight: 500, textAlign: "start" }}>
                                            {finshNumber(product.newPrice)}
                                        </div>
                                    </div>
                                    :
                                    <div style={{ textAlign: "start", color: "gray", fontSize: 18, fontWeight: 500 }}>
                                        {finshNumber(product.price!)}
                                    </div>
                                }
                            </div>
                            <div>
                                {t("Market.Total price")} :
                                {product.newPrice ?
                                    <div style={{ display: "flex", gap: 5 }}>
                                        <div style={{ textDecoration: "line-through", color: "gray", fontSize: 18, fontWeight: 500, textAlign: "start" }}>
                                            {finshNumber(product.price! * product.quantity!)}
                                        </div>
                                        <div style={{ color: "red", fontSize: 18, fontWeight: 500, textAlign: "start" }}>
                                            {finshNumber(product.newPrice * product.quantity!)}
                                        </div>
                                    </div>
                                    :
                                    <div style={{ textAlign: "start", color: "gray", fontSize: 18, fontWeight: 500 }}>
                                        {finshNumber(product.price! * product.quantity!)}
                                    </div>
                                }
                            </div>
                            <div>
                                {product.comment &&
                                    <div>
                                        <OverlayTrigger
                                            trigger="click"
                                            key={"top"}
                                            placement={"top"}
                                            overlay={
                                                <Popover id={`popover-positioned-top`}>
                                                    <Popover.Header as="h3">{`ملاحظة المنتج`}</Popover.Header>
                                                    <Popover.Body>
                                                        {product.comment}
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <Button bsPrefix="BtnBNBIcon"><FaRegQuestionCircle /> </Button>
                                        </OverlayTrigger>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            <div style={{ backgroundColor: "white", marginTop: 20, padding: 10, fontSize: 18 }}>
                <div style={{ display: "flex", gap: "3px" }}>
                    <div>
                        {t("Market.PayPage.Country")} : {dataOrder?.address?.country} ,
                    </div>
                    <div>
                        {t("Market.PayPage.Neighborhood")} : {dataOrder?.address?.neighborhood}
                    </div>
                </div>
                <div style={{ display: "flex", gap: "3px", flexWrap: "wrap" }}>
                    <div>
                        {t("Market.PayPage.Street")} : {dataOrder?.address?.street} ,
                    </div>
                    <div>
                        {t("Market.PayPage.Building number")} : {dataOrder?.address?.buildingNumber} ,
                    </div>
                    <div>
                        {t("Market.PayPage.floor")} : {dataOrder?.address?.floor} ,
                    </div>
                    <div>
                        {t("Market.PayPage.Apartment No")} : {dataOrder?.address?.apartmentNo}
                    </div>
                </div>
            </div>
            {
                dataOrder?.status !== "canceled" &&
                <div style={{ display: "flex", justifyContent: "center", marginTop: "20px", backgroundColor: "white", padding: 40 }}>
                    {t("Market.status")} :  {t(`Market.PayPage.${dataOrder?.status}`)}
                </div>
            }
            <div style={{ marginTop: 20, backgroundColor: "white", padding: "30px 10px" }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 7 }}>
                    {
                        dataOrder?.status !== "canceled" && stagesArray.map((status, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                                <StageCircle completed={isStageCompleted(status)} text={status} />
                            </div>
                        ))
                    }
                </div>
                <div style={{ display: "flex", justifyContent: "center", margin: "20px", gap: "20px" }}>
                    <div>
                        <BtnComplete status={dataOrder?.status!} id={dataOrder?._id!} onRefund={handleRefund} />
                    </div>
                    <div>
                        <Refund dataOrder={dataOrder!} onRefund={handleRefund} />
                    </div>
                </div>
            </div>
        </div >
    </>)
}

interface StageCircleProps {
    completed: boolean;
    text: string;
}

const StageCircle: React.FC<StageCircleProps> = ({ completed, text }) => {
    const { t } = useTranslation()
    return (<>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 5px' }}>
            <div style={{
                width: '15px',
                height: '15px',
                borderRadius: '50%',
                backgroundColor: completed ? '#7c1eff' : 'white',
                border: "1px solid #7c1eff",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white'
            }}>
            </div>
            <p>{t(`Market.PayPage.${text}`)}</p>
        </div>
    </>)
};

export default OrderDetalsMobileAdmin;