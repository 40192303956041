import axios from "axios";
import { useEffect, useState } from "react";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { ChatProps, UsersProps } from "../../Interfaces";

interface UserChatProps {
    chat?: ChatProps;
    user?: {
        _id?: any;
    };
}

export const UseFetchRecipientUser = ({ chat, user }: UserChatProps) => {
    const userData = useSelector((state: RootState) => state.userData)
    const [recipientUser, setRecipientUser] = useState<UsersProps>()
    const [error, setError] = useState<any>(null)

    const recipientId: any = userData.user.userDetals?.role
        ? chat?.members?.find((id: any) => id !== true)
        : chat?.members?.find((id) => id !== user?._id);



    useEffect(() => {
        const getUser = async () => {
            if (!recipientId || recipientId === true) return null
            if (recipientId === true) return { userName: "Admin" }
            await axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${recipientId}`,
                {
                    headers: {
                        Authorization: "Bearer " + userData.user.token,
                    }
                }
            )
                .then((doc) => setRecipientUser(doc.data))
                .catch((err) => { console.log(err); setError(err) })
        }
        getUser()
    }, [recipientId])
    return { recipientUser }
}


