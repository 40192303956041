import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { UsersProps } from '../Interfaces';

export interface UserState {
    user: {
        token?: string;
        refreshToken?: string;
        userDetals?: UsersProps;
    }
}

const initialState: UserState = {
    user: {
        token: undefined,
        refreshToken: undefined,
        userDetals: {
            _id: undefined,
            userName: undefined,
            pointsInvite: 0,
            theOneWhoInvitedHim: '',
            invitationName: '',
            email: undefined,
            phone: undefined,
            active: undefined,
            role: undefined,
            favorite: [],
            cart: [{
                itemId: undefined,
                quantity: undefined,
                color: undefined,
            }],
            address: {
                country: undefined,
                neighborhood: undefined,
                street: undefined,
                buildingNumber: undefined,
                floor: undefined,
                apartmentNo: undefined
            },
        }
    }
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<Partial<UserState['user']>>) => {
            state.user = { ...state.user, ...action.payload };
        },
        setFavorites: (state, action: PayloadAction<string>) => {
            const productId = action.payload;
            const userDetails = state.user.userDetals;

            if (userDetails && userDetails.favorite) {
                if (userDetails.favorite.includes(productId)) {
                    userDetails.favorite = userDetails.favorite.filter(id => id !== productId);
                } else {
                    userDetails.favorite = [...userDetails.favorite, productId];
                }
            } else if (userDetails) {
                userDetails.favorite = [productId];
            }
        },
        setCart: (state, action: PayloadAction<{ itemId: string, quantity: number }>) => {
            const { itemId, quantity } = action.payload;
            const userDetails = state.user.userDetals;

            if (userDetails && userDetails.cart) {
                const cartItem = userDetails.cart.find(item => item.itemId === itemId);

                if (cartItem) {
                    // إذا كان المنتج موجودًا، قم بتحديث الكمية أو إزالته إذا كانت الكمية 0
                    if (quantity === 0) {
                        userDetails.cart = userDetails.cart.filter(item => item.itemId !== itemId);
                    } else {
                        cartItem.quantity = quantity;
                    }
                } else {
                    // إذا لم يكن المنتج موجودًا، أضفه للسلة
                    userDetails.cart = [...userDetails.cart, { itemId, quantity }];
                }
            } else if (userDetails) {
                // إذا لم تكن السلة موجودة بعد، قم بإنشائها وأضف المنتج
                userDetails.cart = [{ itemId, quantity }];
            }
        }
    },
})

export const { setUser, setFavorites } = userSlice.actions

export default userSlice.reducer