import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios';
import { Product } from '../Interfaces';

export interface CartItemProp {
    quantity: number;
    data: Product;
    userId?: string;
    color?: string
    comments?: string
}

export interface CounterState {
    cartItems: CartItemProp[];
    offerHedar: boolean;
    useWallet: boolean;
    valueWallet: number;
}

const loadStateFromLocalStorage = (): CartItemProp[] => {
    try {
        const serializedState = localStorage.getItem('cartItems');
        return serializedState ? JSON.parse(serializedState) : [];
    } catch (e) {
        console.error("Could not load state from localStorage", e);
        return [];
    }
}
const loadStateFromLocalStorageUseWallet = (): boolean => {
    try {
        const serializedState = localStorage.getItem('useWallet');
        return serializedState ? JSON.parse(serializedState) : false;
    } catch (e) {
        console.error("Could not load state from localStorage", e);
        return false;
    }
}

const loadStateFromLocalStorageVlaueWallet = (): number => {
    try {
        const serializedState = localStorage.getItem('valueWallet');
        return serializedState ? JSON.parse(serializedState) : 0;
    } catch (e) {
        console.error("Could not load state from localStorage", e);
        return 0;
    }
}

// const loadStateFromLocalStorageofferHedar = (): boolean => {
//     try {
//         const serializedState = localStorage.getItem('offerHedar');
//         return serializedState ? JSON.parse(serializedState) : false;
//     } catch (e) {
//         console.error("Could not load state from localStorage", e);
//         return false;
//     }
// }

const initialState: CounterState = {
    cartItems: loadStateFromLocalStorage(),
    offerHedar: false,
    useWallet: loadStateFromLocalStorageUseWallet(),
    valueWallet: loadStateFromLocalStorageVlaueWallet(),
}

export const counterSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        incrementItems: (state, action: PayloadAction<{ quantity: number, data: Product, userId: string, color: string, comments?: string }>) => {
            const { quantity, data, userId, color, comments } = action.payload;
            const existingItem = state.cartItems.find(item => ((item.data._id === data._id) && (item.color === color)));
            if (existingItem) {
                existingItem.quantity += quantity;
            } else {
                state.cartItems.push({ quantity, data, color, comments });
            }

            localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
            axios.put(`${process.env.REACT_APP_URL_API}/API/Users/PutAddCart/${userId}`, { quantity: existingItem ? existingItem.quantity : quantity, data: data, color, comments },)
        },
        decrementItems: (state, action: PayloadAction<{ quantity: number, data: Product, userId: string, color: string, comments?: string }>) => {
            const { quantity, data, userId, color } = action.payload;
            const existingItem = state.cartItems.find(item => ((item.data._id === data._id) && (item.color === color)));

            if (existingItem) {
                if (existingItem.quantity > quantity) {
                    existingItem.quantity -= quantity;
                    axios.put(`${process.env.REACT_APP_URL_API}/API/Users/PutAddCart/${userId}`, { quantity: existingItem.quantity, data: data, color })
                } else {
                    color ?
                        state.cartItems = state.cartItems.filter(item => ((item.data._id !== data._id) || (item.color !== color)))
                        :
                        state.cartItems = state.cartItems.filter(item => (item.data._id !== data._id));
                    axios.put(`${process.env.REACT_APP_URL_API}/API/Users/PutAddCart/${userId}`, { quantity: 0, data: data, color })
                }

                localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
            }
        },
        cleenItems: (state) => {
            state.cartItems = []
            localStorage.removeItem('cartItems');
        },
        offerHedarRedux: (state, action: PayloadAction<{ offerHedar: boolean }>) => {
            const offerHedar = action.payload.offerHedar
            state.offerHedar = offerHedar
        },
        WalletRedux: (state, action: PayloadAction<{ useWallet: boolean, valueWallet: number }>) => {
            const useWallet = action.payload.useWallet
            const valueWallet = action.payload.valueWallet
            state.useWallet = useWallet
            state.valueWallet = valueWallet
            localStorage.setItem('useWallet', JSON.stringify(state.useWallet));
            localStorage.setItem('valueWallet', JSON.stringify(state.valueWallet));
        },
    },
})

export const getItemQuantity = (state: CounterState, id: string, color: string): number => {
    return state.cartItems.find((item) => ((item.data._id === id) && (item.color === color)))?.quantity || 0;
}

export const subTotall = (CarentItem: CounterState) => {
    const subTotal: number = CarentItem.cartItems.reduce((total, cartitem) => {
        return total + ((cartitem.data.newPrice || cartitem.data.price || 0) * cartitem.quantity) - ((cartitem.data.newPrice || cartitem.data.price || 0) - (cartitem.data.offerForTwoProducts && cartitem.quantity > 1 && cartitem.data.priceOfferForTwoProducts || cartitem.data.newPrice || cartitem.data.price || 0))
    }, 0)
    return CarentItem.useWallet ? subTotal - CarentItem.valueWallet : subTotal
}

export const totall = (CarentItem: CounterState) => {
    const total: number = CarentItem.cartItems.reduce((total, cartitem) => {
        return total + ((cartitem.data.price || 0) * cartitem.quantity)
    }, 0)
    return total
}


export const { incrementItems, decrementItems, cleenItems, offerHedarRedux, WalletRedux } = counterSlice.actions

export default counterSlice.reducer
