import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import Langu from "../../../../components/languge/languge";
import AdminYouMayAlsoLike from "../../../components/CardsProducts/AdminYouMayAlsoLike";
import AdminCardPeoduct from "../../../components/CardsProducts/AdminCardProduct";
import { Brand, ProductSearch } from "../../../../Interfaces";
import img from "../../../../files/HomeGuest/4.png"
import imgMo from "../../../../files/HomeGuest/4Mo.jpg"
import { ChatContext } from "../../../../components/context/ChatContext";
import { Loading, LoadingBtn } from "../../../../components/laoding/laoding";
import { OrderContext } from "../../../../components/context/OrderContext";

const GetPackagesAdmin: React.FC = () => {
    const { t } = useTranslation()
    const chatContext = useContext(ChatContext)
    const { currency, baseCurrency } = chatContext!
    const orderContext = useContext(OrderContext);
    const { isMobile } = orderContext!
    // const [price, setPrice] = useState<string>("All")
    const [dataProducts, setDataProducts] = useState<ProductSearch>()
    const [dataBrands, setDataBrands] = useState<Brand[]>()
    const [checkBrand, setCheckBrand] = useState<string[]>([])
    // const [checkTarget, setCheckTarget] = useState<string>("Alll")
    const [minPrice, setMinPrice] = useState<number>(0)
    const [maxPrice, setMaxPrice] = useState<number>(1000)
    const [limit, setLimit] = useState<number>(30)
    const [statusBtnViewMore, setStatusBtnViewMore] = useState<any>(t("Market.View more"))
    const [disabledBtnViewMore, setDisabledViewMore] = useState<boolean>(false)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Brands/GetBrands`)
            .then((doc) => setDataBrands(doc.data))
            .catch((err) => console.log(err))
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => { window.scrollTo(0, 0) }, [])

    const dataSearch = useMemo(() => {
        return { minPrice: minPrice / baseCurrency, maxPrice: maxPrice / baseCurrency, brand: checkBrand, offers: true };
    }, [minPrice, maxPrice, checkBrand, baseCurrency, currency]);

    useEffect(() => {

        const getProducts = async () => {
            await axios.put(`${process.env.REACT_APP_URL_API}/API/Products/PutSearchProductsCategory/Packages/${limit}`, dataSearch)
                .then((doc) => setDataProducts(doc.data))
                .catch((err) => console.log(err))
                .finally(() => {
                    setStatusBtnViewMore(t("Market.View more"))
                    setDisabledViewMore(false)
                })
        }
        const debounce = setTimeout(() => {
            getProducts()
        }, 800)
        return () => clearTimeout(debounce)
    }, [dataSearch, baseCurrency, limit]);

    // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setPrice(e.target.value);
    // };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;
        if (checked) {
            setCheckBrand([...checkBrand!, value]);
        } else {
            setCheckBrand(checkBrand!.filter((brand) => brand !== value));
        }
    };
    // const handleCheckboxChangeTarget = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setCheckTarget(e.target.value);
    // };

    const handleMinPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (parseFloat(e.target.value) <= maxPrice - 10) {
            setMinPrice(parseFloat(e.target.value))
        }
    }

    const handleMaxPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (parseFloat(e.target.value) >= minPrice + 10) {
            setMaxPrice(parseFloat(e.target.value))
        }
    }

    return (<>
        <div>
            <div style={{ background: isMobile ? `url(${imgMo}) no-repeat center` : `url(${img}) no-repeat center center`, backgroundSize: 'cover', minHeight: "20vh", width: '100%' }}>
                {/* <div className="nameOffer" style={{ top: "calc(10vh + 60px)" }}>{t("Home.ForYouMyLady")}</div> */}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                {!isMobile &&
                    <div style={{ width: "20%", height: "100%", maxWidth: "250px", border: "1px solid #dddddd", margin: "50px 20px 50px 0", padding: "10px" }}>
                        <div style={{ minHeight: '30vh' }}>
                            <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Home.You may also like")}</div>
                            <div>
                                <AdminYouMayAlsoLike />
                            </div>
                        </div>
                        <div style={{ minHeight: '10vh' }}>
                            <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Market.price")}</div>
                            <div>
                                {/* <Form.Group className="mb-3" controlId="exampleForm.newPrice">
                                <div className="radio-container" style={{ borderBottom: "1px solid #dddddd", paddingBottom: "10px", display: "flex", gap: "30px", flexDirection: "column" }}>
                                {["All", "0-49", "50-99", "100-299"].map((pricee, index) => (
                                    <label key={index} className="radio-label" htmlFor={`${pricee}`} style={{ display: "flex", gap: "5px" }}>
                                    <input
                                    type="radio"
                                    id={`${pricee}`}
                                    name="priceFiltar"
                                    value={`${pricee}`}
                                    checked={price === `${pricee}`}
                                    onChange={handleChange}
                                    />
                                    {`${pricee}`}
                                    <span className="radio-custom"></span>
                                    </label>
                                    ))}
                                    <label className="radio-label" htmlFor="300" style={{ display: "flex", gap: "5px" }}>
                                    <input
                                            type="radio"
                                            id="300"
                                            name="priceFiltar"
                                            value="300"
                                            checked={price === "300"}
                                            onChange={handleChange}
                                            />
                                            {t("Market.more than 300")}
                                            <span className="radio-custom"></span>
                                            </label>
                                            </div>
                                            </Form.Group> */}
                                <div style={{ margin: "20px auto" }}>
                                    <label htmlFor="minPrice">السعر الأدنى: <span>{minPrice}</span> {currency}</label>
                                    <input style={{ width: "100%" }} type="range" id="minPrice" min="0" max="1000" onChange={handleMinPrice} value={minPrice} step="10" />

                                    <label htmlFor="maxPrice">السعر الأقصى: <span>{maxPrice}</span> {currency}</label>
                                    <input style={{ width: "100%" }} type="range" id="maxPrice" min="0" max="1000" onChange={handleMaxPrice} value={maxPrice} step="10" />
                                </div>
                            </div>
                        </div>
                        <div style={{ minHeight: '20vh' }}>
                            <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Market.brand")}</div>
                            <div>
                                <Form style={{ display: "flex", flexDirection: 'column', gap: '10px' }}>
                                    {
                                        dataBrands?.map((brand, index) => (
                                            <Form.Check
                                                key={index}
                                                type="checkbox"
                                                id={brand.name.en}
                                                label={<Langu {...brand.name} />}
                                                value={brand.name.en}
                                                onChange={handleCheckboxChange}
                                            />
                                        ))
                                    }
                                </Form>
                            </div>
                        </div>
                        {/* <div style={{ minHeight: '20vh' }}>
                        <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Market.target")}</div>
                        <div className="radio-container" style={{ paddingBottom: "10px", display: "flex", gap: "20px", flexDirection: "column" }}>
                        {["Alll", "Men's", "Women's"].map((target) => (
                            <label key={`target-${target}`} className="radio-label" htmlFor={`${target}`} style={{ display: "flex", gap: "5px" }}>
                            <input
                            type="radio"
                            id={`${target}`}
                            name="target"
                            value={`${target}`}
                            checked={checkTarget === `${target}`}
                            onChange={handleCheckboxChangeTarget}
                            />
                            {t(`Market.${target}`)}
                            <span className="radio-custom"></span>
                            </label>
                            ))}
                            </div>
                            </div> */}
                    </div>
                }
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "1440px", width: "100%" }}>
                    <div style={{ display: "flex", gap: isMobile ? 5 : "20px", marginTop: "30px", padding: isMobile ? 5 : "0 40px", flexWrap: "wrap", justifyContent: "center", maxWidth: "1440px", width: "100%", alignContent: "start" }}>

                        {dataProducts ?
                            dataProducts?.movies?.length! > 0 ? dataProducts?.movies?.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()).map((Product, index) => (
                                <AdminCardPeoduct Product={Product} dragging={false} key={index} />
                            ))
                                :
                                <div style={{ width: "100%", display: 'flex', justifyContent: "center", minHeight: "50vh", alignItems: "center", fontSize: "22px" }}>
                                    {t("Market.There are no products with these specifications.")}
                                </div>
                            :
                            <Loading />
                        }
                    </div>
                    <div style={{ margin: 30 }}>
                        <Button disabled={disabledBtnViewMore} onClick={() => { setLimit(limit + 20); setStatusBtnViewMore(<LoadingBtn />); setDisabledViewMore(true) }} bsPrefix="BtnBNB">{statusBtnViewMore}</Button>
                    </div>
                </div>
            </div >
        </div >
    </>)
}

export default GetPackagesAdmin;