import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { BiSupport } from "react-icons/bi";
import "./SupportIcon.css"
import { Alert, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SiWhatsapp } from "react-icons/si";
import { IoIosChatbubbles } from "react-icons/io";
import { BsFillTelephoneFill } from "react-icons/bs";
import SocialMediaIcons from '../../../client/components/SocialMediaIcons/SocialMediaIcons';
import { OrderContext } from '../../../components/context/OrderContext';
import { FcAssistant } from 'react-icons/fc';

const FloatingButton: React.FC = () => {
  const { t, i18n } = useTranslation()
  const ordercontext = useContext(OrderContext)
  const { isMobile } = ordercontext!
  const [isOpen, setIsOpen] = useState(false);
  const [showAlirtMsg, setShowAlirtMsg] = useState<boolean>(false)
  const [showMessage, setShowMessage] = useState(true);
  const [hasScrolled, setHasScrolled] = useState(true);
  const [showWhatsapp, setShowWhatsapp] = useState<boolean>(false)
  const [showTelephone, setShowTelephone] = useState<boolean>(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const isBottom = window.innerHeight + scrollY >= document.documentElement.scrollHeight;

      if (!isBottom) {
        setHasScrolled(true);
        setShowMessage(true);
      }
      else if (isBottom) {
        setHasScrolled(false);
        setShowMessage(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolled]);

  return (<>
    {showMessage &&
      <div className="floating-container" style={{ right: i18n.language === "en" ? '30px' : undefined, left: i18n.language === "ar" ? '35px' : undefined }}>
        <div className={`icon-container ${isOpen ? 'open' : ''}`}>
          <Button onClick={() => setShowWhatsapp(true)} variant="light" className="floating-icon" style={{ fontSize: "30px", padding: '8px' }}>
            <SiWhatsapp color='#00a884' />
          </Button>
          <Button onClick={() => setShowAlirtMsg(true)} variant="light" className="floating-icon" style={{ fontSize: "30px", padding: '6px' }}>
            <IoIosChatbubbles color='#6f24d7' />
          </Button>
          <Button onClick={() => setShowTelephone(true)} variant="light" className="floating-icon" style={{ fontSize: "30px", padding: '10px' }}>
            <BsFillTelephoneFill color='#008069' />
          </Button>
        </div>

        <Button onClick={toggleMenu} bsPrefix='BtnBNB' style={{ position: 'fixed', bottom: '30px', padding: "0 10px", borderRadius: "50%", height: "50px", left: i18n.language === "ar" ? '30px' : undefined, }}>
          <BiSupport size={"30px"} />
        </Button>
        <Alert show={showAlirtMsg} variant={"danger"} style={{ position: "fixed", top: '80px', left: "30px", minWidth: "20%", zIndex: "10" }} onClose={() => setShowAlirtMsg(false)} dismissible>
          <Alert.Heading style={{ textAlign: "start" }}>
            {t("Market.Error message")}
          </Alert.Heading>
          <p>
            {t("Market.You are not registered, please log in to continue")}
          </p>
        </Alert>
        <Modal show={showWhatsapp} onHide={() => setShowWhatsapp(false)}>
          <Modal.Header className="CloseBtn" style={{ backgroundColor: "#22cd5b" }} closeButton>
            <Modal.Title style={{ color: "white" }}>
              {t("Hedar.We are here to help you, contact us on WhatsApp")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <a style={{ textDecoration: "none", fontSize: 30, color: "rgb(0, 168, 132)", alignItems: "center", display: "flex", gap: 10, width: "100%" }} href='https://api.whatsapp.com/send/?phone=905527864653&text&type=phone_number&app_absent=0'>   <FcAssistant size={40} />  {"  "}  هلال {" "} </a>
          </Modal.Body>
          <Modal.Footer style={{ alignItems: "center", justifyContent: "center" }}>
            <Button variant="secondary" onClick={() => setShowWhatsapp(false)}>{t("Hedar.Close")}</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showTelephone} onHide={() => setShowTelephone(false)}>
          <Modal.Header className="CloseBtn" style={{ backgroundColor: "#008069" }} closeButton>
            <Modal.Title style={{ color: "white" }}>
              {t("Home.phone")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ display: "flex", justifyContent: "center", fontSize: 30 }}>
            +905527864653
          </Modal.Body>
          <Modal.Footer style={{ alignItems: "center", justifyContent: "center" }}>
            <Button variant="secondary" onClick={() => setShowTelephone(false)}>{t("Hedar.Close")}</Button>
          </Modal.Footer>
        </Modal>
        {!isMobile &&
          <SocialMediaIcons />
        }
      </div>
    }
  </>
  );
}


export default FloatingButton;