import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { RootState, store } from './redux/store'
import { Provider, useSelector } from 'react-redux';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import i18n from "i18next";
import { ChatContextProvider } from './components/context/ChatContext';
import { OrderContextProvider } from './components/context/OrderContext';


i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    debug: false,
    fallbackLng: "en",
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'sessionStorage', 'navigator', 'path', 'subdomain'],
      caches: ["cookie"]
    },
    backend: {
      loadPath: '/locale/{{lng}}/{{ns}}.json',
    }
  });
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const RootComponent = () => {
  const userData = useSelector((state: RootState) => state.userData);

  return (
    <OrderContextProvider user={userData?.user?.userDetals!}>
      <ChatContextProvider user={userData?.user?.userDetals!}>
        <App />
      </ChatContextProvider>
    </OrderContextProvider>
  );
};

root.render(

  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <RootComponent />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
