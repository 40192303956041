import { Button, Stack } from "react-bootstrap";
import Langu from "../languge/languge";
import { decrementItems, incrementItems } from "../../redux/counterSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ChatContext } from "../context/ChatContext";
import { Product } from "../../Interfaces";
import { OrderContext } from "../context/OrderContext";


interface CartItemPayPageProps {
    data: {
        data: Product;
        quantity: number;
        color: string;
    }
}


const CartItemPayPage: React.FC<CartItemPayPageProps> = ({ data }) => {
    const dispatch = useDispatch()
    const userData = useSelector((state: RootState) => state.userData);
    const chatContext = useContext(ChatContext);
    const orderContext = useContext(OrderContext);
    const { isMobile } = orderContext!
    const { baseCurrency, currency } = chatContext!;
    const [dataProduct, setDataProduct] = useState<Product>()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProduct/${data.data._id}`)
            .then((doc) => setDataProduct(doc.data))
            .catch((err) => console.log(err))
    }, [data.data._id])

    const finshNumber = (num: number) => {
        const Currency = currency === "ILS" ? "ILS" : currency === "USD" ? "USD" : "JOD"
        if (currency === "ILS") {
            const afterNum = (Number(num) * baseCurrency)
            return (afterNum.toFixed(2)) + " " + Currency
        }
        else {
            return (Number(num) * baseCurrency).toFixed(2) + " " + Currency
        }
    }

    return (<>
        <Stack style={{ display: "flex", maxHeight: "200px", flexDirection: "row", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #dddddd", padding: "15px 0" }}>
            <Link to={`/client/market/productDetails/${data.data._id}`} style={isMobile ? { display: "flex", width: "30%", alignItems: "center", gap: 5, color: "initial", textDecoration: "none", flexDirection: "column" } : { display: "flex", width: "40%", alignItems: "center", gap: "20px", color: "initial", textDecoration: "none" }}>
                <img style={{ height: isMobile ? 80 : "160px", width: isMobile ? 100 : "200px", borderRadius: "8px" }} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${data?.data?.file?.[0]}`} alt={data.data.product?.en} />
                <div>
                    <div style={{ fontWeight: "500", fontSize: "15px" }}>
                        {Langu(data?.data?.product!)}
                    </div>
                </div>
                {data.color &&
                    <div style={{ width: '50px', textAlign: "center" }}>
                        <div style={{ minWidth: 20, maxWidth: 20, minHeight: 20, maxHeight: 20, backgroundColor: data.color }}></div>
                    </div>
                }
            </Link>
            <div style={{ width: "20%", textAlign: "center", fontWeight: "500", fontSize: "15px" }}>
                {
                    data.data.newPrice ?
                        <>
                            <div>
                                <div style={{ textDecoration: "line-through", color: "gray" }}>
                                    {finshNumber(data.data.price!)}
                                </div>
                                <div style={{ color: "red" }}>
                                    {finshNumber(data.data.newPrice!)}
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {finshNumber(data.data.price!)}
                        </>
                }
            </div>
            <div style={{ display: "flex", width: isMobile ? "auto" : "20%", justifyContent: "center", gap: "10px", flexDirection: isMobile ? "column" : undefined, alignItems: isMobile ? "center" : undefined }}>
                <div>
                    <Button bsPrefix="BtnBNB" onClick={() => dispatch(incrementItems({ quantity: 1, data: data.data, userId: userData.user.userDetals?._id!, color: data.color }))} disabled={data.quantity === dataProduct?.number} style={{ width: "30px", height: "30px", padding: "0" }}>+</Button>
                </div>
                <div style={{ width: "20px", textAlign: "center", fontWeight: "500", fontSize: "15px" }}>
                    {data.quantity}
                </div>
                <div>
                    <Button bsPrefix="BtnBNB" onClick={() => dispatch(decrementItems({ quantity: 1, data: data.data, userId: userData.user.userDetals?._id!, color: data.color }))} style={{ width: "30px", height: "30px", padding: "0" }}>-</Button>
                </div>
            </div>
            <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-evenly", width: "20%" }}>
                {
                    data.quantity > 1 && data.data.offerForTwoProducts ?
                        data.data.newPrice ?
                            <div style={{ textAlign: "end", fontWeight: "500", fontSize: "15px" }}>
                                <div style={{ textDecoration: "line-through", color: "gray" }}>
                                    {finshNumber((data.data.price! * data.quantity) - (data.data.price! - data.data.priceOfferForTwoProducts!))}
                                </div>
                                <div style={{ color: "red" }}>
                                    {finshNumber((data.data.newPrice! * data.quantity) - (data.data.newPrice! - data.data.priceOfferForTwoProducts!))}
                                </div>
                            </div>
                            :
                            <div style={{ textAlign: "end", fontWeight: "500", fontSize: "15px" }}>
                                {finshNumber((data.data.price! * data.quantity) - (data.data.price! - data.data.priceOfferForTwoProducts!))}
                            </div>
                        :
                        data.data.newPrice ?
                            <div style={{ textAlign: "end", fontWeight: "500", fontSize: "15px" }}>
                                <div style={{ textAlign: "end", fontWeight: "500", fontSize: "15px" }}>
                                    {finshNumber(data.data.price! * data.quantity)}
                                </div>
                                <div style={{ color: "red" }}>
                                    {finshNumber(data.data.newPrice! * data.quantity)}
                                </div>
                            </div>
                            :
                            <div style={{ textAlign: "end", fontWeight: "500", fontSize: "15px" }}>
                                {finshNumber(data.data.price! * data.quantity)}
                            </div>
                }
            </div>
        </Stack >
    </>)
}


export default CartItemPayPage;