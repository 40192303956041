import { Button, CloseButton } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import './Hedar.client.css'
import { useContext, useEffect, useState } from "react";
import { IoSearch, IoWalletOutline } from "react-icons/io5";
import Search from "../../guest/hedarGuest/Search";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Cookies from "universal-cookie";
import { isExpired } from "react-jwt";
import axios from "axios";
import { setUser } from "../../redux/userSlice";
import I18nClient from "./I18n.client";
import { useTranslation } from "react-i18next";
import AccuntDropdownClient from "./AccuntDropdown.client";
import { MdOutlineShoppingCart } from "react-icons/md";
import ShoppingCart from "../../guest/hedarGuest/ShoppingCart";
import { GrFavorite } from "react-icons/gr";
import { ThemeColor } from "../../theme";
import { offerHedarRedux } from "../../redux/counterSlice";
import Langu from "../../components/languge/languge";
import SetCurrency from "../../components/setCurrency/SetCurrency";
import { Category, OthersProps, UsersProps } from "../../Interfaces";
import { ChatContext } from "../../components/context/ChatContext";
import { FaBars, FaRegUser } from "react-icons/fa";
import { OrderContext } from "../../components/context/OrderContext";
import img2 from "../../files/HomeGuest/شعارتريتميأبيض.jpg"
import { CiViewList } from "react-icons/ci";
import { LuUserCog } from "react-icons/lu";
import { TbLogout } from "react-icons/tb";
import { BiMinus, BiPlus } from "react-icons/bi";


const HedarClient: React.FC = () => {

    const dispatch = useDispatch();
    const userData = useSelector((state: RootState) => state.userData);
    const CarentItem = useSelector((state: RootState) => state.counterData)
    const { t, i18n } = useTranslation()
    const nav = useNavigate()
    const cookie = new Cookies()
    const getTokenCookie = cookie.get("bearer");
    const chatContext = useContext(ChatContext)
    const { baseCurrency, currency } = chatContext!
    const ordercontext = useContext(OrderContext)
    const { isMobile } = ordercontext!

    const [dataCategotys, setDataCategorys] = useState<Category[]>()
    const [showShoppingCart, setShowShoppingCart] = useState<boolean>(false)
    const [showSearch, setShowSearch] = useState<boolean>(false)
    const [isVisible, setIsVisible] = useState<boolean>(true);
    const [close, setClose] = useState<Boolean>(isVisible)
    const [dataOthers, setDataOthers] = useState<OthersProps>()
    const [menuVisible, setMenuVisible] = useState<boolean>(false);
    const [newDataUser, setNewDataUser] = useState<UsersProps>()
    const [openAccunt, setOpenAccunt] = useState<boolean>(false)

    const handleClose = () => {
        // جعل العنصر يختفي تدريجيًا
        setIsVisible(false);
        // بعد فترة التأثير نحدث حالة Redux
        setTimeout(() => {
            setClose(false)
            dispatch(offerHedarRedux({ offerHedar: true }));
        }, 700);
    };

    let TokenReducer = userData?.user?.token
    let IsExpiredReducerToken = isExpired(TokenReducer!)
    let RefreshToken = getTokenCookie?.refreshToken

    const quantitys = CarentItem?.cartItems.reduce((total, cartitem) => {
        return total + cartitem.quantity
    }, 0)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${userData.user.userDetals?._id}`, {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + userData.user.token,
            }
        })
            .then((doc) => setNewDataUser(doc.data))
            .catch((err) => console.log(err))
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategorys`)
            .then((doc) => setDataCategorys(doc.data))
            .catch((err) => console.log(err))
        axios.get(`${process.env.REACT_APP_URL_API}/API/Others/GetOthers`)
            .then((doc) => setDataOthers(doc.data?.[0]))
            .catch((err) => console.log(err))
        document.body.dir = i18n.dir()
    }, [])

    useEffect(() => {
        if (IsExpiredReducerToken) {
            axios.get(`${process.env.REACT_APP_URL_API}/API/Auth/RefreshToken`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + RefreshToken,
                }
            })
                .then((doc) => {
                    const token = doc.data.token;
                    const userDetals = doc.data.data
                    cookie.set("bearer", { token: token, refreshToken: RefreshToken, userDetals: userDetals }, { path: "/", expires: new Date(Date.now() + 2 * 30 * 24 * 60 * 60 * 1000) });
                    dispatch(setUser({ token: token, refreshToken: RefreshToken, userDetals: userDetals }))
                })
                .catch((err) => {
                    console.log("err refreshPage : ", err);
                })
        }
    }, [IsExpiredReducerToken, RefreshToken, TokenReducer, dispatch, cookie])

    const LogoutBtn = async () => {
        await axios.get(`${process.env.REACT_APP_URL_API}/API/Auth/Logout`, {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + userData.user.token,
            }
        })
            .then(() => {
                dispatch(setUser({ token: undefined, refreshToken: undefined, userDetals: undefined }))
                cookie.remove("bearer", { path: "/" })
                nav('/guest/homePage')
            })
            .catch((err) => console.log(err))
    }

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    const finshNumber = (num: number) => {
        const Currency = currency === "ILS" ? "ILS" : currency === "USD" ? "USD" : "JOD";
        return (Number(num) * baseCurrency).toFixed(2) + " " + Currency;
    };

    return (<>
        <nav>
            <div style={{ minHeight: close ? isMobile ? "120px" : "95px" : "65px" }}>
                <div className="NavHedar" style={{ flexDirection: "column" }}>
                    {!CarentItem.offerHedar && (
                        <div
                            className="offer-banner"
                            style={{
                                backgroundColor: ThemeColor.bgColor(1),
                                color: "white",
                                display: "flex",
                                justifyContent: "space-between",
                                padding: 5,
                                minHeight: 20,
                                minWidth: "100%",
                                opacity: isVisible ? 1 : 0,
                                transition: "opacity 0.7s ease-out",
                            }}
                        >
                            <div></div>
                            <div style={{ fontSize: 16, fontWeight: 500, textAlign: isMobile ? "center" : undefined }}>
                                {t("Hedar.Shipping to Palestine and Jordan", { Jordan: (dataOthers?.delivery?.jordan! * baseCurrency).toFixed(0), Inside: (dataOthers?.delivery?.inside! * baseCurrency).toFixed(0), WestBank: (dataOthers?.delivery?.westBank! * baseCurrency).toFixed(0), currency })}
                            </div>
                            <div>
                                <CloseButton
                                    variant="white"
                                    style={{ margin: "0 20px" }}
                                    onClick={handleClose}
                                />
                            </div>
                        </div>
                    )}
                    <div style={{ maxWidth: "1440px", width: "100%", padding: isMobile ? "0 10px" : "0 40px", minHeight: 65, alignContent: "center" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            {
                                isMobile ?
                                    <div style={{ display: "flex", width: "100%" }}>
                                        <div style={{ width: "33.33%" }}>
                                            <Button
                                                onClick={toggleMenu}
                                                style={{
                                                    background: 'none',
                                                    backgroundColor: menuVisible ? "white" : ThemeColor.bgColor(1),
                                                    border: `solid 1px ${ThemeColor.bgColor(1)}`,
                                                    fontSize: '20px',
                                                    color: menuVisible ? ThemeColor.bgColor(1) : "white",
                                                    cursor: 'pointer',
                                                    padding: "2px 10px",
                                                }}
                                            >
                                                <FaBars />
                                            </Button>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "center", width: "33.33%" }}>
                                            <Button bsPrefix="BtnBNBIcon" style={{ border: "none", padding: 0 }} onClick={() => nav(`/client/homePage`)} >
                                                <img style={{ maxHeight: 40, padding: 5 }} src={img2} />
                                            </Button>
                                        </div>
                                        <div style={{ display: "flex", width: "33.33%", justifyContent: "end" }}>
                                            <Button bsPrefix='BtnBNBIcon' style={{ display: "flex", alignItems: "center", color: "black" }} onClick={() => setShowShoppingCart(true)}>
                                                <div style={{ backgroundColor: ThemeColor.bgColor(1), minWidth: "24px", borderRadius: "50%", color: "white" }}>{quantitys}</div>
                                                <MdOutlineShoppingCart className='IconHedar' size={"30px"} />
                                            </Button>
                                            <Button bsPrefix='BtnBNBIcon' onClick={() => setShowSearch(true)}><IoSearch size={"25px"} /></Button>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className='MenuNavLink' style={{ display: "flex", gap: "20px" }}>
                                            {/* <Notification /> */}
                                            <NavLink className={"NavLinkHedar"} to="/client/homePage">{t("Home.home")}</NavLink>
                                            {dataCategotys && dataCategotys.slice(0, 7).map((Categoty, index) => (
                                                <NavLink className={"NavLinkHedar"} to={`/client/market/category/${Categoty.name.en}`} key={index}><Langu {...Categoty.name} /></NavLink>
                                            ))}
                                        </div>
                                    </div>
                            }
                            <div style={{
                                position: 'absolute',
                                top: close ? 120 : '60px',
                                right: '0',
                                backgroundColor: 'white',
                                width: '100%',
                                boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)',
                                opacity: menuVisible ? 1 : 0,
                                display: menuVisible ? 'flex' : "none",
                                flexDirection: 'column',
                                gap: '10px',
                                padding: '10px',
                                transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
                                transform: menuVisible ? 'translateY(0)' : 'translateY(-20px)',
                                zIndex: 61,
                            }}>
                                <NavLink className={"NavLinkHedar"} to="/client/homePage" style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                    fontWeight: '700',
                                    transition: 'color 0.3s',
                                    padding: "0 0 10px 0",
                                    display: "flex",
                                    gap: 10,
                                    borderBottom: "2px solid #dddddd",
                                }}
                                    onClick={toggleMenu}
                                    onMouseEnter={(e) => e.currentTarget.style.color = '#ff9100'}
                                    onMouseLeave={(e) => e.currentTarget.style.color = 'black'}
                                >
                                    {/* <img draggable={false} style={{ width: 20, height: 20 }} src={img3} /> */}
                                    <p style={{ margin: 0 }}>{t("Home.home")}</p>
                                </NavLink>
                                {dataCategotys && dataCategotys.slice(0, 5).map((catogory, index) => (
                                    <NavLink
                                        key={index}
                                        to={`/client/market/category/${catogory.name.en}`}
                                        style={{
                                            textDecoration: 'none',
                                            color: 'black',
                                            fontWeight: '700',
                                            transition: 'color 0.3s',
                                            display: "flex",
                                            padding: "0 0 10px 0",
                                            gap: 10,
                                            borderBottom: "2px solid #dddddd",
                                        }}
                                        onClick={toggleMenu}
                                        onMouseEnter={(e) => e.currentTarget.style.color = ThemeColor.text}
                                        onMouseLeave={(e) => e.currentTarget.style.color = 'black'}
                                    >
                                        {/* <img draggable={false} style={{ width: 20, height: 20 }} src={`${process.env.REACT_APP_URL_API}/API/files/Categorys/${catogory.file ? catogory.file?.[2] : ""}`} /> */}
                                        <Langu {...catogory.name} />
                                    </NavLink>
                                ))}
                                <NavLink style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                    fontWeight: '700',
                                    transition: 'color 0.3s',
                                    display: "flex",
                                    padding: "0 0 10px 0",
                                    gap: 10,
                                    borderBottom: "2px solid #dddddd",
                                }}
                                    onClick={toggleMenu}
                                    to={`/client/market/Favorite/${userData.user?.userDetals?._id}`} >
                                    <GrFavorite className='IconHedar' size={"25px"} />
                                    {t('Hedar.Favorites')}
                                    <div style={{ backgroundColor: "#842cff", minWidth: "24px", borderRadius: "50%", color: "white", textAlign: "center" }}>{userData.user.userDetals?.favorite?.length}</div>
                                </NavLink>
                                <NavLink style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                    fontWeight: '700',
                                    transition: 'color 0.3s',
                                    display: "flex",
                                    padding: "0 0 10px 0",
                                    gap: 10,
                                    borderBottom: "2px solid #dddddd",
                                }}
                                    onClick={toggleMenu}
                                    to={`/client/myAccount/Wallet/${userData?.user?.userDetals?._id}`} >
                                    <IoWalletOutline size={20} /> <div>{t("Hedar.Wallet")} :</div> <div style={{ fontSize: 14, alignContent: "center", color: "green" }}>{finshNumber(newDataUser?.pointsInvite ?? 0)}</div>
                                </NavLink>
                                <div style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                    fontWeight: '700',
                                    transition: 'color 0.3s',
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "0 0 10px 0",
                                    gap: 10,
                                    borderBottom: "2px solid #dddddd",
                                }}
                                    onClick={() => { setOpenAccunt(!openAccunt); }}>
                                    <div>
                                        <FaRegUser size={"25px"} /> {t('Hedar.My account')}
                                    </div>
                                    <div style={{ padding: "0 20px" }}>
                                        {
                                            openAccunt ?
                                                <BiMinus />
                                                :
                                                <BiPlus />
                                        }
                                    </div>
                                </div>
                                <div style={{
                                    maxHeight: openAccunt ? "290px" : "0px",
                                    overflow: "hidden",
                                    transition: "max-height 0.3s ease-in-out",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 10,
                                    padding: openAccunt ? "0px 20px 0 0" : "0 20px 0 0"
                                }}>
                                    <NavLink style={{
                                        textDecoration: 'none',
                                        color: 'black',
                                        fontWeight: '700',
                                        transition: 'color 0.3s',
                                        display: "flex",
                                        padding: "0 0 10px 0",
                                        gap: 10,

                                        borderBottom: "2px solid #dddddd",
                                    }}
                                        onClick={toggleMenu}
                                        to={`/client/myAccount/myOrders`} >
                                        <CiViewList size={20} /> {t("Hedar.my Orders")}
                                    </NavLink>
                                    <NavLink style={{
                                        textDecoration: 'none',
                                        color: 'black',
                                        fontWeight: '700',
                                        transition: 'color 0.3s',
                                        display: "flex",
                                        padding: "0 0 10px 0",
                                        gap: 10,
                                        borderBottom: "2px solid #dddddd",
                                    }}
                                        onClick={toggleMenu}
                                        to={`/client/myAccount/AccountDetals`} >
                                        <LuUserCog size={20} /> {t("Hedar.AccountDetals")}
                                    </NavLink>
                                    <div style={{
                                        textDecoration: 'none',
                                        color: 'red',
                                        fontWeight: '700',
                                        transition: 'color 0.3s',
                                        display: "flex",
                                        padding: "0 0 10px 0",
                                        gap: 10,
                                        borderBottom: "2px solid #dddddd",
                                    }}
                                        onClick={LogoutBtn}
                                    >
                                        <TbLogout size={20} /> {t("Hedar.Logout")}
                                    </div>
                                </div>
                                <div>
                                    <SetCurrency />
                                </div>
                                <div>
                                    <I18nClient />
                                </div>
                            </div>

                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                {!isMobile &&
                                    <>
                                        <div>
                                            <Button bsPrefix='BtnBNBIcon' style={{ display: "flex" }} onClick={() => setShowShoppingCart(true)}>
                                                <div style={{ backgroundColor: "#842cff", minWidth: "24px", borderRadius: "50%", color: "white" }}>{quantitys}</div>
                                                <MdOutlineShoppingCart className='IconHedar' size={"25px"} />
                                            </Button>
                                        </div>
                                        <div>
                                            <Button bsPrefix='BtnBNBIcon' style={{ display: "flex", gap: "2px" }} onClick={() => { nav(`/client/market/Favorite/${userData.user?.userDetals?._id}`) }}>
                                                <div style={{ backgroundColor: "#842cff", minWidth: "24px", borderRadius: "50%", color: "white", }}>{userData.user.userDetals?.favorite?.length}</div>
                                                <GrFavorite className='IconHedar' size={"25px"} />
                                            </Button>
                                        </div>
                                        <div>
                                            <AccuntDropdownClient />
                                        </div>
                                    </>
                                }
                                {!isMobile &&
                                    <>
                                        <div>
                                            <Button bsPrefix='BtnBNBIcon' onClick={() => setShowSearch(true)}><IoSearch size={"25px"} /></Button>
                                        </div>
                                        <div>
                                            <SetCurrency />
                                        </div>
                                        <div>
                                            <I18nClient />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Search show={showSearch} setShowSearch={setShowSearch} />
            <ShoppingCart show={showShoppingCart} setShowShoppingCart={setShowShoppingCart} />
        </nav>
    </>)
}

export default HedarClient;