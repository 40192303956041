import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { LoadingBtn } from "../../../components/laoding/laoding";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import { ChatContext } from "../../../components/context/ChatContext";

interface BtnCreateProductProps {
    productEN: string;
    productAR: string;
    // productHE: string;
    informationEN: string;
    informationAR: string;
    // informationHE: string;
    price: number;
    newPrice: number;
    number: number;
    photo: File[];
    category: string;
    brand: string;
    offerStatus: boolean;
    colorsPost: (string | undefined)[];
    statusOfferForTwoProducts: boolean;
    priceOfferForTwoProducts?: number;
    // target: string;
    // targetStatus: boolean;
    Categorys?: Record<string, string>;
    introVideo: File;
    photoIllustrativePictures?: File[];
    forYouMyLady?: boolean;
}


const BtnCreateProduct: React.FC<BtnCreateProductProps> = ({ productEN, productAR, informationEN, informationAR, price, number, newPrice, photo, category, brand, offerStatus, colorsPost,/* targetStatus, target,*/ Categorys, statusOfferForTwoProducts, priceOfferForTwoProducts, introVideo, photoIllustrativePictures, forYouMyLady }) => {
    let userData = useSelector((state: RootState) => state.userData)
    let token = userData.user.token
    const context = useContext(ChatContext)
    const { baseCurrency } = context!
    const { t } = useTranslation()
    const [statusBtnCreate, setStatusBtnCreate] = useState<any>(t("Market.Create Product"))
    const [disabledBtnCreate, setDisabledBtnCreate] = useState<boolean>(false)
    const [errInput, setErrInput] = useState<string>('')
    const [success, setSuccess] = useState<string>()

    const discount: number | undefined = price && newPrice ? ((price - newPrice) / price) * 100 : undefined;

    useEffect(() => {
        setErrInput('')
        setSuccess('')
    }, [productEN, productAR, informationEN, informationAR, price, number, photo])

    const BtnCreate = async () => {
        setStatusBtnCreate(<LoadingBtn />)
        setDisabledBtnCreate(true)
        if (!productEN || !productAR || !price || !number) {
            setDisabledBtnCreate(false)
            setStatusBtnCreate(t("Market.Create Product"))
            setErrInput(t('Market.Please enter data'))
            return;
        }
        if (photo.length === 0) {
            setDisabledBtnCreate(false)
            setStatusBtnCreate(t("Market.Create Product"))
            setErrInput(t('Market.Please enter photos'))
            return;
        }
        const Category = JSON.parse(category)
        const Brand = JSON.parse(brand)

        const selectedFiles = []
        const selectedFilesIllustrativePictures = []

        for (let i = 0; i < photo.length; i++) {
            selectedFiles.push(photo[i]);
        }
        if (photoIllustrativePictures) {
            for (let i = 0; i < photoIllustrativePictures.length; i++) {
                selectedFilesIllustrativePictures.push(photoIllustrativePictures[i]);
            }
        }
        const updatedFormData = new FormData();
        updatedFormData.append("categoryEN", Category.en)
        updatedFormData.append("categoryAR", Category.ar)
        // updatedFormData.append("categoryHE", Category.he)
        updatedFormData.append("brandEN", Brand.en)
        updatedFormData.append("brandAR", Brand.ar)
        // updatedFormData.append("brandHE", Brand.he)
        updatedFormData.append("productEN", productEN)
        updatedFormData.append("productAR", productAR)
        // updatedFormData.append("productHE", productHE)
        updatedFormData.append("informationEN", informationEN)
        updatedFormData.append("informationAR", informationAR)
        if (forYouMyLady) {
            updatedFormData.append("forYouMyLady", forYouMyLady.toString())
        }
        // updatedFormData.append("informationHE", informationHE)
        updatedFormData.append("colors", JSON.stringify(colorsPost))
        if (price !== undefined) {
            if (offerStatus && newPrice !== undefined) {
                updatedFormData.append("price", (price / baseCurrency).toString());
                updatedFormData.append("newPrice", (newPrice / baseCurrency).toString());
                updatedFormData.append("offers", offerStatus.toString());
            }
            if (discount !== undefined) {
                updatedFormData.append("discount", discount.toFixed(0).toString());
            }
            else {
                updatedFormData.append("price", (price / baseCurrency).toString());
            }
        }
        if (statusOfferForTwoProducts && priceOfferForTwoProducts) {
            updatedFormData.append("offerForTwoProducts", statusOfferForTwoProducts.toString());
            updatedFormData.append("priceOfferForTwoProducts", (priceOfferForTwoProducts / baseCurrency).toString());
        }
        else {
            updatedFormData.append("offerForTwoProducts", statusOfferForTwoProducts.toString());
        }
        // if (targetStatus) {
        //     updatedFormData.append("target", target)
        // }
        if (Categorys) {
            updatedFormData.append("categorys", JSON.stringify(Categorys))
        }
        if (number !== undefined) {
            updatedFormData.append("number", number.toString());
        }

        for (let i = 0; i < selectedFiles.length; i++) {
            updatedFormData.append("file", selectedFiles[i]);
        }
        if (photoIllustrativePictures) {
            for (let i = 0; i < selectedFilesIllustrativePictures.length; i++) {
                updatedFormData.append("fileIllustrativePictures", selectedFilesIllustrativePictures[i]);
            }
        }
        if (introVideo) {
            updatedFormData.append("introVideo", introVideo);
        }

        await axios.post(`${process.env.REACT_APP_URL_API}/API/Products/PostProduct`, updatedFormData,
            {
                headers: {
                    Authorization: "Bearar " + token,
                    "Content-Type": "multipart/form-data"
                }
            }
        )
            .then(() => {
                setSuccess("Success")
            })
            .catch(err => console.log(err))
        setStatusBtnCreate(t("Market.Create Product"))
        setDisabledBtnCreate(false)
    }

    return (<>
        <div>
            {success &&
                <div style={{ color: "green", textAlign: "center", backgroundColor: "#e1ffe1", padding: "10px 20px", marginBottom: 10 }}>{success}</div>
            }
            <div style={{ color: "red" }}>{errInput}</div>
            <Button bsPrefix="BtnBNB" onClick={BtnCreate} disabled={disabledBtnCreate}>{statusBtnCreate}</Button>
        </div>
    </>)
}

export default BtnCreateProduct;