import { Button, Stack } from "react-bootstrap";
import Langu from "../languge/languge";
import { decrementItems, incrementItems } from "../../redux/counterSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ChatContext } from "../context/ChatContext";
import { Product } from "../../Interfaces";


interface CartItemProps {
    data: {
        data: Product;
        quantity: number;
        color: string;
    }
}


const CartItem: React.FC<CartItemProps> = ({ data }) => {
    const dispatch = useDispatch()
    const userData = useSelector((state: RootState) => state.userData);
    const chatContext = useContext(ChatContext);
    const { baseCurrency, currency } = chatContext!;

    const [dataProduct, setDataProduct] = useState<Product>()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProduct/${data.data._id}`)
            .then((doc) => setDataProduct(doc.data))
            .catch((err) => console.log(err))
    }, [data.data._id])


    const truncateText = (text: string): string => {
        let shortText = text.substring(0, 45)
        if (text.length > 45) {
            shortText = shortText + "..."
        }
        return shortText
    }
    const extractTextFromElement = (element: React.ReactNode): string => {
        // التأكد من أن العنصر هو نص (string)
        if (typeof element === "string") {
            return element;
        }
        // إذا كان العنصر JSX، نحاول استخراج النص من داخله
        if (React.isValidElement(element) && element.props.children) {
            return String(element.props.children);
        }
        return "";
    };

    const finshNumber = (num: number) => {
        const Currency = currency === "ILS" ? "ILS" : currency === "USD" ? "USD" : "JOD"
        if (currency === "ILS") {
            const afterNum = (Number(num) * baseCurrency)
            return (afterNum.toFixed(2)) + " " + Currency
        }
        else {
            return (Number(num) * baseCurrency).toFixed(2) + " " + Currency
        }
    }

    return (<>
        <Stack style={{ display: "flex", maxHeight: "100px", flexDirection: "row", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #dddddd", padding: "4px 0" }}>
            <Link to={`/client/market/productDetails/${data.data._id}`} style={{ display: "flex", color: "initial", textDecoration: "none", gap: 10 }}>
                <img style={{ height: "80px", width: "100px", borderRadius: "8px" }} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${data?.data?.file?.[0]}`} alt={data.data.product?.en} />
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <div>
                        {truncateText(extractTextFromElement(Langu(data?.data?.product!)))}
                    </div>
                    <div style={{ display: "flex", gap: 10 }}>
                        <div>
                            x{data.quantity}
                        </div>
                        {data.color &&
                            <div style={{ width: '50px', textAlign: "center" }}>
                                <div style={{ maxWidth: 20, minWidth: 20, maxHeight: 20, minHeight: 20, backgroundColor: data.color }}></div>
                            </div>
                        }
                    </div>
                </div>
            </Link>
            <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
                <div style={{ textAlign: "center" }}>
                    {
                        data.quantity > 1 && data.data.offerForTwoProducts ?
                            data.data.newPrice ?
                                <div>
                                    <div style={{ textDecoration: "line-through", color: "gray" }}>
                                        {finshNumber((data.data.price! * data.quantity) - (data.data.price! - data.data.priceOfferForTwoProducts!))}
                                    </div>
                                    <div style={{ color: "red" }}>
                                        {finshNumber((data.data.newPrice! * data.quantity) - (data.data.newPrice! - data.data.priceOfferForTwoProducts!))}
                                    </div>
                                </div>
                                :
                                <div>
                                    {finshNumber((data.data.price! * data.quantity) - (data.data.price! - data.data.priceOfferForTwoProducts!))}
                                </div>
                            :
                            data.data.newPrice ?
                                <div>
                                    <div style={{ textDecoration: "line-through", color: "gray" }}>
                                        {finshNumber(data.data.price! * data.quantity)}
                                    </div>
                                    <div style={{ color: "red" }}>
                                        {finshNumber(data.data.newPrice! * data.quantity)}
                                    </div>
                                </div>
                                :
                                <div>
                                    {finshNumber(data.data.price! * data.quantity)}
                                </div>
                    }
                </div>
                <div style={{ display: "flex" }}>
                    <div>
                        <Button bsPrefix="BtnBNB" onClick={() => dispatch(incrementItems({ quantity: 1, data: data.data, userId: userData.user.userDetals?._id!, color: data.color }))} disabled={dataProduct?.number === data.quantity} style={{ width: "30px", height: "30px", padding: "0" }}>+</Button>
                    </div>
                    <div style={{ width: "20px", textAlign: "center" }}>
                        {data.quantity}
                    </div>
                    <div>
                        <Button bsPrefix="BtnBNB" onClick={() => dispatch(decrementItems({ quantity: 1, data: data.data, userId: userData.user.userDetals?._id!, color: data.color }))} style={{ width: "30px", height: "30px", padding: "0" }}>-</Button>
                    </div>
                </div>
            </div>
        </Stack>
    </>)
}


export default CartItem;