import { useTranslation } from "react-i18next"
import { IoMdMail } from "react-icons/io"
import { BiSolidPhoneCall } from "react-icons/bi";
import { Button, Modal } from "react-bootstrap";
import { IoLogoWhatsapp } from "react-icons/io5";
import img from "../../files/HomeGuest/12344.jpg"
import { useContext, useState } from "react";
import { OrderContext } from "../context/OrderContext";
import { FcAssistant } from "react-icons/fc";
import { Link } from "react-router-dom";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";


const Footer: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData);
    const { t } = useTranslation()
    const ordercontext = useContext(OrderContext)
    const { isMobile } = ordercontext!
    const [showWhatsapp, setShowWhatsapp] = useState<boolean>(false)
    const [showTelephone, setShowTelephone] = useState<boolean>(false)

    return (<>
        <div style={{ minHeight: 500, backgroundColor: "#101729", display: "flex", flexDirection: "column", justifyContent: "center", padding: 30, alignItems: "center" }}>
            <div style={{ maxWidth: 1660, display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <div style={isMobile ? { display: "flex", flexDirection: "column", textAlign: "center", gap: 40 } : { display: "flex", justifyContent: "space-between", padding: 30 }}>
                    <div style={{ maxWidth: isMobile ? "100%" : "30%" }}>
                        <h2 style={{ color: "#da108d", fontWeight: 700 }}>{t("Footer.Who are we?")}</h2>
                        <div style={{ color: "white", fontSize: 19, marginTop: 50 }}>
                            {t("Footer.Who are we2?")}
                        </div>
                    </div>
                    <div>
                        <h2 style={{ color: "#da108d", fontWeight: 700 }}>{t("Footer.Abbreviations")}</h2>
                    </div>
                    <div>
                        <h2 style={{ color: "#da108d", fontWeight: 700 }}>{t("Footer.Our Policies")}</h2>
                        <div style={{ color: "white", fontSize: 19, display: "flex", flexDirection: "column", gap: 40, marginTop: 50 }}>
                            <div>
                                {t("Footer.Order Cancellation Policy")}
                            </div>
                            <div>
                                {t("Footer.Return Policy")}
                            </div>
                            <div>
                                {t("Footer.Shipping Policy")}
                            </div>
                            <div>
                                {t("Footer.Contact us")}
                            </div>
                        </div>
                    </div>
                    <div>
                        <h2 style={{ color: "#da108d", fontWeight: 700 }}>{t("Footer.Contact us")}</h2>
                        <div style={{ display: "flex", gap: 30, marginTop: 50, width: "100%", justifyContent: "center" }}>
                            <Button bsPrefix="BtnBNBIcon" style={{ backgroundColor: "#101729", width: 40 }}>
                                <IoMdMail size={40} style={{ borderRadius: 50, border: "solid 1px #cba95b", color: "#cba95b", padding: 7 }} />
                            </Button>
                            <Button bsPrefix="BtnBNBIcon" style={{ backgroundColor: "#101729", width: 40 }} onClick={() => { setShowTelephone(true) }}>
                                <BiSolidPhoneCall size={40} style={{ borderRadius: 50, border: "solid 1px #cba95b", color: "#cba95b", padding: 7 }} />
                            </Button>
                            <Button bsPrefix="BtnBNBIcon" style={{ backgroundColor: "#101729", width: 40 }} onClick={() => { setShowWhatsapp(true) }}>
                                <IoLogoWhatsapp size={40} style={{ borderRadius: 50, border: "solid 1px #cba95b", color: "#cba95b", padding: 7 }} />
                            </Button>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column-reverse", justifyContent: isMobile ? "center" : "space-between", width: isMobile ? "100%" : undefined, alignItems: isMobile ? "center" : undefined, gap: 30, marginTop: isMobile ? 30 : undefined }}>
                    <div style={{ color: "white", fontWeight: 700, fontSize: isMobile ? 13 : 19, alignContent: "end", textAlign: isMobile ? "center" : undefined, direction: "ltr" }}>
                        <div style={{ display: "flex", gap: 50 }}>
                            <div>
                                Copyright 2024 Made by HLDSH - All Rights Reserved
                            </div>
                            <div >
                                {userData.user.userDetals?.role === false ?
                                    <Link to={'/client/PrivacyPolicy'}>Privacy Policy</Link>
                                    :
                                    <Link to={'/guest/PrivacyPolicy'}>Privacy Policy</Link>
                                }
                            </div>
                            <div>
                                Refund policy
                            </div>
                            <div>
                                Terms and Conditions
                            </div>
                        </div>
                    </div>
                    <div>
                        <img style={{ maxWidth: 200, margin: "0 20px" }} src={img} />
                    </div>
                </div>
            </div>
        </div>
        <Modal show={showWhatsapp} onHide={() => setShowWhatsapp(false)}>
            <Modal.Header className="CloseBtn" style={{ backgroundColor: "#22cd5b" }} closeButton>
                <Modal.Title style={{ color: "white" }}>
                    {t("Hedar.We are here to help you, contact us on WhatsApp")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ display: "flex", justifyContent: "start" }}>
                <a style={{ textDecoration: "none", fontSize: 30, color: "rgb(0, 168, 132)", alignItems: "center", display: "flex", gap: 10, width: "100%" }} href='https://api.whatsapp.com/send/?phone=905527864653&text&type=phone_number&app_absent=0'>  <FcAssistant size={40} />  {"  "}  هلال {" "}  </a>
            </Modal.Body>
            <Modal.Footer style={{ alignItems: "center", justifyContent: "center" }}>
                <Button variant="secondary" onClick={() => setShowWhatsapp(false)}>{t("Hedar.Close")}</Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showTelephone} onHide={() => setShowTelephone(false)}>
            <Modal.Header className="CloseBtn" style={{ backgroundColor: "#008069" }} closeButton>
                <Modal.Title style={{ color: "white" }}>
                    {t("Home.phone")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ display: "flex", justifyContent: "center", fontSize: 30 }}>
                +905527864653
            </Modal.Body>
            <Modal.Footer style={{ alignItems: "center", justifyContent: "center" }}>
                <Button variant="secondary" onClick={() => setShowTelephone(false)}>{t("Hedar.Close")}</Button>
            </Modal.Footer>
        </Modal>
    </>)
}

export default Footer