import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Langu from "../../../components/languge/languge";
import BtnCreateProduct from "./BtnCreateProduct";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { Brand, Category } from "../../../Interfaces";
import { OrderContext } from "../../../components/context/OrderContext";


const CreateProduct: React.FC = () => {
    const { t } = useTranslation()
    const orderContext = useContext(OrderContext);
    const { isMobile } = orderContext!
    const [dataBrands, setDataBrands] = useState<Brand[]>()
    const [dataCategory, setDataCategory] = useState<Category[]>()
    const [category, setCategory] = useState<string>("")
    const [brand, setBrand] = useState<string>('')
    const [productEN, setProductEN] = useState<string>('')
    const [productAR, setProductAR] = useState<string>('')
    // const [productHE, setProductHE] = useState<string>('')
    const [informationEN, setInformationEN] = useState<string>('')
    const [informationAR, setInformationAR] = useState<string>('')
    // const [informationHE, setInformationHE] = useState<string>('')
    const [color, setColor] = useState<string>()
    const [colorsPost, setColorsPost] = useState<(string | undefined)[]>([]);
    const [price, setPrice] = useState<number | undefined>()
    const [newPrice, setNewPrice] = useState<number | undefined>()
    const [number, setNumber] = useState<number | undefined>()
    const [photo, setPhoto] = useState<File[]>([])
    const [photoIllustrativePictures, setPhotoIllustrativePictures] = useState<File[]>([])
    const [video, setVideo] = useState<File>();
    const [offerStatus, setOfferStatus] = useState<boolean>(false)
    const [hoveredItemId, setHoveredItemId] = useState<string | null | undefined>(null)
    const [statusOfferForTwoProducts, setStatusOfferForTwoProducts] = useState<boolean>(false)
    const [priceOfferForTwoProducts, setPriceOfferForTwoProducts] = useState<number>()
    const [forYouMyLady, setForYouMyLady] = useState<boolean>()
    // const [targetStatus, setTargetStatus] = useState<boolean>(false)
    // const [target, setTarget] = useState<string>("Men's")
    const [categoryDetails, setCategoryDetails] = useState<Category>()
    const [checkedCategorys, setCheckedCategorys] = useState<Record<string, string>>({})

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategorys`)
            .then((doc) => { setDataCategory(doc.data) })
            .catch((err) => console.log(err))
        axios.get(`${process.env.REACT_APP_URL_API}/API/Brands/GetBrands`)
            .then((doc) => { setDataBrands(doc.data) })
            .catch((err) => console.log(err))
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        category && axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategory/${JSON.parse(category).en}`)
            .then((doc) => setCategoryDetails(doc.data))
            .catch((err) => console.log(err))
    }, [category])

    useEffect(() => {
        setTimeout(() => {
            if (dataCategory && dataCategory.length > 0) {
                setCategory(JSON.stringify({
                    en: dataCategory[0]?.name?.en,
                    ar: dataCategory[0]?.name?.ar,
                }))
                setBrand(JSON.stringify({
                    en: dataBrands?.[0]?.name?.en,
                    ar: dataBrands?.[0]?.name?.ar,
                }))
            }
        }, 500)
    }, [dataCategory])

    const handelPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setPhoto(Array.from(files))
        }
    }

    const handelPhotoIllustrativePictures = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setPhotoIllustrativePictures(Array.from(files))
        }
    }


    const handelVideo = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const video = e.target.files[0];
            setVideo(video)
        }
    };

    const discount: number | undefined = price && newPrice ? ((price - newPrice) / price) * 100 : undefined;

    const addColors = () => {
        setColorsPost(prevColors => [...prevColors, (color || '#000000')]);
    };

    const BtnDeleteColor = () => {
        setColorsPost(prevColors => prevColors.filter(c => c !== hoveredItemId));
    }

    // const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setTarget(e.target.value)
    // }

    const handelCheckedCategorys = (categoryKey: string, value: string) => {
        setCheckedCategorys((prev) => ({
            ...prev,
            [categoryKey]: value, // تحديث الاختيار للتصنيف المعين
        }));
    }

    const changeImageOrder = (fromIndex: number, toIndex: number) => {
        const newPhotos = [...photo];
        const [movedPhoto] = newPhotos.splice(fromIndex, 1);
        newPhotos.splice(toIndex, 0, movedPhoto);
        setPhoto(newPhotos);
    }

    const changeImageIllustrativePictures = (fromIndex: number, toIndex: number) => {
        const newPhotos = [...photoIllustrativePictures];
        const [movedPhoto] = newPhotos.splice(fromIndex, 1);
        newPhotos.splice(toIndex, 0, movedPhoto);
        setPhotoIllustrativePictures(newPhotos);
    }

    const BtnSwitchsetForYouMyLady = async (e: ChangeEvent<HTMLInputElement>) => {
        const newStatusSwitch: boolean = e.target.checked;
        setForYouMyLady(newStatusSwitch)
    }

    return (<>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "1440px", width: "100%", boxShadow: "rgb(214, 214, 214) 5px 6px 10px", padding: "30px", borderRadius: "10px", border: "solid 1px #d6d6d6", margin: isMobile ? 10 : "20px 10% 30px 10%" }}>
                <h1 style={{ width: "100%", borderBottom: "1px solid rgb(214, 214, 214)", textAlign: "center", paddingBottom: "20px" }}>{t("Market.Create Product")}</h1>
                <div style={{ width: "100%", maxWidth: "1000px", display: "flex", gap: "10px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Category" style={{ width: "49%" }}>
                        <Form.Label>{t("Market.Category name")} :</Form.Label>
                        <Form.Select onChange={e => { setCategory(e.target.value) }} value={category}>
                            {
                                dataCategory?.map((category, index) => (
                                    <option key={index} value={JSON.stringify({ en: category.name.en, ar: category.name.ar })}><Langu {...category.name} /></option>
                                ))
                            }
                            <option value={JSON.stringify({ en: "Packages", ar: "باكيجات", he: "باكيجات" })}>{t("Home.Packages")}</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group style={{ width: "49%" }}>
                        <Form.Label>{t("Market.Brand name")} :</Form.Label>
                        <Form.Select onChange={e => { setBrand(e.target.value) }} value={brand}>
                            {
                                dataBrands?.map((brands, index) => (
                                    <option key={index} value={JSON.stringify({ en: brands.name.en, ar: brands.name.ar })}><Langu {...brands.name} /></option>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    {categoryDetails?.Categorys?.map((item: any, index: number) => (
                        <div key={index}>
                            <div> <Langu {...item.name} /> : </div>
                            <Form.Check className="mb-3" style={{ display: "flex", gap: 20 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                                    <Form.Label htmlFor={`null--${index}`}>بلا</Form.Label>
                                    <Form.Check
                                        type="radio"
                                        id={`null--${index}`}
                                        value={"null"}
                                        name={`Categorys-${index}`}
                                        checked={checkedCategorys[item?.name?.en] === "null"}
                                        onChange={() => handelCheckedCategorys(item.name.en, "null")}
                                    />
                                </div>
                                {item?.Categories?.map((category: any, subIndex: number) => (
                                    <div key={subIndex} style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                                        <Form.Label htmlFor={`${category}-${index}-${subIndex}`}> <Langu {...category} /></Form.Label>
                                        <Form.Check
                                            type="radio"
                                            id={`${category}-${index}-${subIndex}`}
                                            name={`Categorys-${index}`}
                                            value={category.en}
                                            checked={checkedCategorys[item.name.en] === category.en}
                                            onChange={() => handelCheckedCategorys(item.name.en, category.en)}
                                        />
                                    </div>
                                ))}
                            </Form.Check>
                        </div>
                    ))}
                </div>
                <div style={{ width: "100%", maxWidth: "1000px" }}>
                    <Form.Label>{t("Market.Product name")} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.Product" style={{ display: "flex", gap: "10px" }}>
                        <Form.Control type="text" placeholder="English" autoFocus onChange={e => { setProductEN(e.target.value) }} />
                        <Form.Control type="text" placeholder="العربية" onChange={e => { setProductAR(e.target.value) }} />
                        {/* <Form.Control type="text" placeholder="עִברִית" onChange={e => { setProductHE(e.target.value) }} /> */}
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column" }}>
                    <Form.Label>{t("Market.information")} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "grid", gap: "10px" }}>
                        <Form.Control as="textarea" placeholder="English" onChange={e => { setInformationEN(e.target.value) }} />
                        <Form.Control as="textarea" placeholder="العربية" onChange={e => { setInformationAR(e.target.value) }} />
                        {/* <Form.Control as="textarea" placeholder="עִברִית" onChange={e => { setInformationHE(e.target.value) }} /> */}
                    </Form.Group>
                </div>
                {/* <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Check type="switch" label={t("Market.target")} style={{ margin: "20px 0" }} onChange={((e) => setTargetStatus(e.target.checked))} />
                    {targetStatus &&
                        <Form.Group style={{ marginBottom: 10 }}>
                            <label className="radio-label" htmlFor="Men's" style={{ display: "flex", gap: "5px", color: "black" }}>
                                <input
                                    type="radio"
                                    id="Men's"
                                    name="target"
                                    value="Men's"
                                    checked={target === "Men's"}
                                    onChange={handleChangeRadio}
                                />
                                {t("Market.Men's")}
                                <span className="radio-custom"></span>
                            </label> <label className="radio-label" htmlFor="Women's" style={{ display: "flex", gap: "5px", color: "black" }}>
                                <input
                                    type="radio"
                                    id="Women's"
                                    name="target"
                                    value="Women's"
                                    checked={target === "Women's"}
                                    onChange={handleChangeRadio}
                                />
                                {t("Market.Women's")}
                                <span className="radio-custom"></span>
                            </label>
                        </Form.Group>
                    }
                </div> */}
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.colors" style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <Form.Label>{t("Market.colors")} :</Form.Label>
                        <Form.Control type="color" placeholder="" autoFocus onChange={e => { setColor(e.target.value) }} />
                        <Button bsPrefix="BtnBNB" onClick={addColors}>{t("Market.add")}</Button>
                        {colorsPost?.map((co, index) => (
                            <div
                                key={index}
                                onMouseEnter={() => setHoveredItemId(co)}
                                onMouseLeave={() => setHoveredItemId(null)}
                                style={{ backgroundColor: co, minWidth: 28, minHeight: 28, border: "1px solid #dddddd", margin: "10px 5px", cursor: "pointer" }}
                                onClick={BtnDeleteColor}
                            >
                            </div>
                        ))}
                    </Form.Group>
                </div>
                {/* <FormLabel htmlFor="forYouMyLady">for You My Lady : </FormLabel> */}
                <div style={{ width: "100%", maxWidth: "700px", textAlign: "start" }}>
                    <Form.Check id="forYouMyLady" style={{ textAlign: "start", maxWidth: "300px" }} label={`${t("Home.for You My Lady")}`} type="switch" checked={forYouMyLady} onChange={(e) => BtnSwitchsetForYouMyLady(e)}></Form.Check>
                    <Form.Check type="switch" label={`${t("Market.offer")}?`} style={{ margin: "20px 0", textAlign: "start", maxWidth: "300px" }} onChange={((e) => setOfferStatus(e.target.checked))} />
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    {offerStatus ?
                        <Form.Group className="mb-3" controlId="exampleForm.newPrice">
                            <Form.Label form="Price">{t("Market.price")} :</Form.Label>
                            <Form.Control type="number" placeholder="" onChange={e => { setPrice(parseFloat(e.target.value) || undefined) }} />
                            <Form.Label form="newPrice">{t("Market.newPrice")} :</Form.Label>
                            <Form.Control type="number" placeholder="" onChange={e => { setNewPrice(parseFloat(e.target.value) || undefined) }} />
                            <div style={{ width: "100%", padding: "10px" }}>{t("Market.discount")} : {discount?.toFixed(0)}%</div>
                        </Form.Group>
                        :
                        <Form.Group className="mb-3" controlId="exampleForm.Price">
                            <Form.Label>{t("Market.price")} :</Form.Label>
                            <Form.Control type="number" placeholder="" onChange={e => { setPrice(parseFloat(e.target.value) || undefined) }} />
                        </Form.Group>
                    }
                </div>
                <div style={{ width: "100%", maxWidth: "700px", textAlign: "start" }}>
                    <Form.Check type="switch" label={t("Market.Discount 2 products?")} style={{ margin: "20px 0", textAlign: "start", maxWidth: "300px" }} onChange={((e) => setStatusOfferForTwoProducts(e.target.checked))} />
                </div>
                {
                    statusOfferForTwoProducts &&
                    <div style={{ width: "100%", maxWidth: "700px" }}>
                        <Form.Group className="mb-3" controlId="exampleForm.Price">
                            <Form.Label>{t("Market.Second product price")} :</Form.Label>
                            <Form.Control type="number" placeholder="" onChange={e => { setPriceOfferForTwoProducts(parseFloat(e.target.value) || undefined) }} />
                        </Form.Group>
                    </div>
                }
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Price">
                        <Form.Label>{t("Market.Available number")} :</Form.Label>
                        <Form.Control type="number" placeholder="" onChange={e => { setNumber(parseFloat(e.target.value) || undefined) }} />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px", marginBottom: 50 }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Photos">
                        <Form.Label>{t("Market.Photos")} :</Form.Label>
                        <Form.Control type="file" onChange={handelPhoto} name="photos" multiple accept="image/*" />
                    </Form.Group>
                    <div>
                        {photo.length > 0 &&
                            <div style={{ display: "flex", gap: "15%", height: 250, flexDirection: "column" }}>
                                <h3>{t("Market.Selected images")} :</h3>
                                <div style={{ display: "flex", gap: 15, width: "100%" }}>
                                    {photo.map((i, index) => (
                                        <div key={index} style={{ marginBottom: '10px', display: "flex", flexDirection: 'column', justifyContent: "space-between", maxWidth: isMobile ? "33%" : undefined }}>
                                            <p>{t("Market.Photo")} {index + 1}: {i.name}</p>
                                            <img src={URL.createObjectURL(i)} alt={`photo-${index}`} width="100" />
                                            <div style={{ display: "flex", gap: 15, marginTop: 10 }}>
                                                {index > 0 && (
                                                    <Button bsPrefix="BtnBNB" style={{ fontSize: 10, padding: "0 10px" }} onClick={() => changeImageOrder(index, index - 1)}>
                                                        <FaArrowUp size={20} />
                                                    </Button>
                                                )}
                                                {index < photo.length - 1 && (
                                                    <Button bsPrefix="BtnBNB" style={{ fontSize: 10, padding: "0 10px" }} onClick={() => changeImageOrder(index, index + 1)}>
                                                        <FaArrowDown size={20} />
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Video">
                        <Form.Label>{t("Market.Video")} :</Form.Label>
                        <Form.Control type="file" name="video" onChange={handelVideo} accept="video/*" />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px", marginBottom: 50 }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Photos">
                        <Form.Label>{t("Market.Illustrative pictures")} :</Form.Label>
                        <Form.Control type="file" onChange={handelPhotoIllustrativePictures} name="photos" multiple accept="image/*" />
                    </Form.Group>
                    <div>
                        {photoIllustrativePictures.length > 0 &&
                            <div style={{ display: "flex", gap: "15%", height: 250, flexDirection: "column" }}>
                                <h3>{t("Market.Selected images")} :</h3>
                                <div style={{ display: "flex", gap: 15, width: "100%" }}>
                                    {photoIllustrativePictures.map((i, index) => (
                                        <div key={index} style={{ marginBottom: '10px', display: "flex", flexDirection: 'column', justifyContent: "space-between", maxWidth: isMobile ? "33%" : undefined }}>
                                            <p>{t("Market.Photo")} {index + 1} : {i.name}</p>
                                            <img src={URL.createObjectURL(i)} alt={`photo-${index}`} width="100" />
                                            <div style={{ display: "flex", gap: 15, marginTop: 10 }}>
                                                {index > 0 && (
                                                    <Button bsPrefix="BtnBNB" style={{ fontSize: 10, padding: "0 10px" }} onClick={() => changeImageIllustrativePictures(index, index - 1)}>
                                                        <FaArrowUp size={20} />
                                                    </Button>
                                                )}
                                                {index < photo.length - 1 && (
                                                    <Button bsPrefix="BtnBNB" style={{ fontSize: 10, padding: "0 10px" }} onClick={() => changeImageIllustrativePictures(index, index + 1)}>
                                                        <FaArrowDown size={20} />
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <BtnCreateProduct brand={brand} category={category} colorsPost={colorsPost!} informationAR={informationAR} informationEN={informationEN}
                    newPrice={newPrice!} number={number!} offerStatus={offerStatus} photo={photo!} introVideo={video!} price={price!} productAR={productAR} productEN={productEN}
                    /* target={target} targetStatus={targetStatus} */ Categorys={checkedCategorys} statusOfferForTwoProducts={statusOfferForTwoProducts} priceOfferForTwoProducts={priceOfferForTwoProducts}
                    photoIllustrativePictures={photoIllustrativePictures} forYouMyLady={forYouMyLady} />
            </div>
        </div>
    </>)
}


export default CreateProduct;