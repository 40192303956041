import { Button } from "react-bootstrap";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import '../../guest/hedarGuest/HedarGuest.css'
import { useContext, useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import Search from "../../guest/hedarGuest/Search";
import I18nAdmin from "./I18n.admin";
import { useTranslation } from "react-i18next";
import AccuntDropdownAdmin from "./AccuntDropdown.admin";
import NotificationsOrders from "./NotificationsOrders";
import SetCurrency from "../../components/setCurrency/SetCurrency";
import axios from "axios";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { isExpired } from "react-jwt";
import Cookies from "universal-cookie";
import { setUser } from "../../redux/userSlice";
import { FaBars, FaRegUser } from "react-icons/fa";
import { OrderContext } from "../../components/context/OrderContext";
import { ThemeColor } from "../../theme";
import img2 from '../../files/HomeGuest/شعارتريتميأبيض.jpg'
import { BiMinus, BiPlus } from "react-icons/bi";
import { LuUserCog } from "react-icons/lu";
import { TbLogout } from "react-icons/tb";


const HedarAdmin: React.FC = () => {
    const { t, i18n } = useTranslation()
    const userData = useSelector((state: RootState) => state.userData);
    const ordercontext = useContext(OrderContext)
    const { isMobile } = ordercontext!
    const dispatch = useDispatch();
    const params = useParams()
    const nav = useNavigate()
    const [showSearch, setShowSearch] = useState<boolean>(false)
    const cookie = new Cookies()
    const getTokenCookie = cookie.get("bearer");
    const [menuVisible, setMenuVisible] = useState<boolean>(false);
    const [openAccunt, setOpenAccunt] = useState<boolean>(false)

    let TokenReducer = userData?.user?.token
    let IsExpiredReducerToken = isExpired(TokenReducer!)
    let RefreshToken = getTokenCookie?.refreshToken

    useEffect(() => {
        document.body.dir = i18n.dir()
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (IsExpiredReducerToken) {
            axios.get(`${process.env.REACT_APP_URL_API}/API/Auth/RefreshToken`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + RefreshToken,
                }
            })
                .then((doc) => {
                    const token = doc.data.token;
                    const userDetals = doc.data.data
                    cookie.set("bearer", { token: token, refreshToken: RefreshToken, userDetals: userDetals }, { path: "/", expires: new Date(Date.now() + 2 * 30 * 24 * 60 * 60 * 1000) });
                    dispatch(setUser({ token: token, refreshToken: RefreshToken, userDetals: userDetals }))
                })
                .catch((err) => {
                    console.log("err refreshPage : ", err);
                })
        }
    }, [IsExpiredReducerToken, RefreshToken, TokenReducer, dispatch, cookie, params])

    const LogoutBtn = async () => {
        await axios.get(`${process.env.REACT_APP_URL_API}/API/Auth/Logout`, {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + userData.user.token,
            }
        })
            .then(() => {
                dispatch(setUser({ token: undefined, refreshToken: undefined, userDetals: undefined }))
                cookie.remove("bearer", { path: "/" })
                nav('/guest/homePage')
            })
            .catch((err) => console.log(err))
    }

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    return (<>
        <nav>
            <div style={{ minHeight: "65px" }}>
                <div className="NavHedar">
                    <div style={{ maxWidth: "1440px", width: "100%", padding: isMobile ? "0 10px" : "0 40px", minHeight: 65, alignContent: "center" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            {
                                isMobile ?
                                    <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                                        <div style={{ width: "33.33%" }}>
                                            <Button
                                                onClick={toggleMenu}
                                                style={{
                                                    background: 'none',
                                                    backgroundColor: menuVisible ? "white" : ThemeColor.bgColor(1),
                                                    border: `solid 1px ${ThemeColor.bgColor(1)}`,
                                                    fontSize: '20px',
                                                    color: menuVisible ? ThemeColor.bgColor(1) : "white",
                                                    cursor: 'pointer',
                                                    padding: "2px 10px",
                                                }}
                                            >
                                                <FaBars />
                                            </Button>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "center", width: "33.33%" }}>
                                            <Button bsPrefix="BtnBNBIcon" style={{ border: "none", padding: 0 }} onClick={() => nav(`/client/homePage`)} >
                                                <img style={{ maxHeight: 40, padding: 5 }} src={img2} />
                                            </Button>
                                        </div>
                                        <div style={{ display: "flex", width: "33.33%", justifyContent: "end", alignItems: "center", gap: 5 }}>
                                            {/* <Button bsPrefix='BtnBNBIcon' style={{ display: "flex", alignItems: "center", color: "black" }} onClick={() => setShowShoppingCart(true)}>
                                                <div style={{ backgroundColor: ThemeColor.bgColor(1), minWidth: "24px", borderRadius: "50%", color: "white" }}>{quantitys}</div>
                                                <MdOutlineShoppingCart className='IconHedar' size={"30px"} />
                                            </Button> */}
                                            <NotificationsOrders />
                                            <Button bsPrefix='BtnBNBIcon' onClick={() => setShowSearch(true)}><IoSearch size={"25px"} /></Button>
                                        </div>
                                    </div>
                                    :
                                    // <div>
                                    //     <div className='MenuNavLink' style={{ display: "flex", gap: "20px" }}>
                                    //         {/* <Notification /> */}
                                    //         <NavLink className={"NavLinkHedar"} to="/client/homePage">{t("Home.home")}</NavLink>
                                    //         {dataCategotys?.slice(0, 7).map((Categoty, index) => (
                                    //             <NavLink className={"NavLinkHedar"} to={`/client/market/category/${Categoty.name.en}`} key={index}><Langu {...Categoty.name} /></NavLink>
                                    //         ))}
                                    //     </div>
                                    // </div>

                                    <div>
                                        <div className='MenuNavLink' style={{ display: "flex", gap: "20px" }}>
                                            <NavLink className={"NavLinkHedar"} to="/admin/homePage">{t("Home.home")}</NavLink>
                                            <NavLink className={"NavLinkHedar"} to="/admin/dashboard">{t("Home.dashboard")}</NavLink>
                                            <NavLink className={"NavLinkHedar"} to="/admin/market/createCategory">{t("Home.create Category")}</NavLink>
                                            <NavLink className={"NavLinkHedar"} to="/admin/market/createProduct">{t("Home.create Product")}</NavLink>
                                            <NavLink className={"NavLinkHedar"} to="/admin/market/createOffer">{t("Home.create Offer")}</NavLink>
                                            <NavLink className={"NavLinkHedar"} to="/admin/market/orders/getOrders">{t("Home.Orders")}</NavLink>
                                            {/* <NavLink className={"NavLinkHedar"} to="/admin/chat">Chat</NavLink> */}
                                        </div>
                                    </div>
                            }
                            <div style={{
                                position: 'absolute',
                                top: '60px',
                                right: '0',
                                backgroundColor: 'white',
                                width: '100%',
                                boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)',
                                opacity: menuVisible ? 1 : 0,
                                display: menuVisible ? 'flex' : "none",
                                flexDirection: 'column',
                                gap: '10px',
                                padding: '10px',
                                transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
                                transform: menuVisible ? 'translateY(0)' : 'translateY(-20px)',
                                zIndex: 61,
                            }}>
                                <NavLink className={"NavLinkHedar"} to="/admin/homePage" style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                    fontWeight: '700',
                                    transition: 'color 0.3s',
                                    padding: "0 0 10px 0",
                                    display: "flex",
                                    gap: 10,
                                    borderBottom: "2px solid #dddddd",
                                }}
                                    onClick={toggleMenu}
                                    onMouseEnter={(e) => e.currentTarget.style.color = '#ff9100'}
                                    onMouseLeave={(e) => e.currentTarget.style.color = 'black'}
                                >
                                    {/* <img draggable={false} style={{ width: 20, height: 20 }} src={img3} /> */}
                                    <p style={{ margin: 0 }}>{t("Home.home")}</p>
                                </NavLink>
                                <NavLink className={"NavLinkHedar"} to="/admin/dashboard" style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                    fontWeight: '700',
                                    transition: 'color 0.3s',
                                    padding: "0 0 10px 0",
                                    display: "flex",
                                    gap: 10,
                                    borderBottom: "2px solid #dddddd",
                                }}
                                    onClick={toggleMenu}
                                    onMouseEnter={(e) => e.currentTarget.style.color = '#ff9100'}
                                    onMouseLeave={(e) => e.currentTarget.style.color = 'black'}
                                >
                                    {/* <img draggable={false} style={{ width: 20, height: 20 }} src={img3} /> */}
                                    <p style={{ margin: 0 }}>{t("Home.dashboard")}</p>
                                </NavLink>
                                <NavLink className={"NavLinkHedar"} to="/admin/market/createCategory" style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                    fontWeight: '700',
                                    transition: 'color 0.3s',
                                    padding: "0 0 10px 0",
                                    display: "flex",
                                    gap: 10,
                                    borderBottom: "2px solid #dddddd",
                                }}
                                    onClick={toggleMenu}
                                    onMouseEnter={(e) => e.currentTarget.style.color = '#ff9100'}
                                    onMouseLeave={(e) => e.currentTarget.style.color = 'black'}
                                >
                                    {/* <img draggable={false} style={{ width: 20, height: 20 }} src={img3} /> */}
                                    <p style={{ margin: 0 }}>{t("Home.create Category")}</p>
                                </NavLink>
                                <NavLink className={"NavLinkHedar"} to="/admin/market/createProduct" style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                    fontWeight: '700',
                                    transition: 'color 0.3s',
                                    padding: "0 0 10px 0",
                                    display: "flex",
                                    gap: 10,
                                    borderBottom: "2px solid #dddddd",
                                }}
                                    onClick={toggleMenu}
                                    onMouseEnter={(e) => e.currentTarget.style.color = '#ff9100'}
                                    onMouseLeave={(e) => e.currentTarget.style.color = 'black'}
                                >
                                    {/* <img draggable={false} style={{ width: 20, height: 20 }} src={img3} /> */}
                                    <p style={{ margin: 0 }}>{t("Home.create Product")}</p>
                                </NavLink>
                                <NavLink className={"NavLinkHedar"} to="/admin/market/createOffer" style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                    fontWeight: '700',
                                    transition: 'color 0.3s',
                                    padding: "0 0 10px 0",
                                    display: "flex",
                                    gap: 10,
                                    borderBottom: "2px solid #dddddd",
                                }}
                                    onClick={toggleMenu}
                                    onMouseEnter={(e) => e.currentTarget.style.color = '#ff9100'}
                                    onMouseLeave={(e) => e.currentTarget.style.color = 'black'}
                                >
                                    {/* <img draggable={false} style={{ width: 20, height: 20 }} src={img3} /> */}
                                    <p style={{ margin: 0 }}>{t("Home.create Offer")}</p>
                                </NavLink>
                                <NavLink className={"NavLinkHedar"} to="/admin/market/orders/getOrders" style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                    fontWeight: '700',
                                    transition: 'color 0.3s',
                                    padding: "0 0 10px 0",
                                    display: "flex",
                                    gap: 10,
                                    borderBottom: "2px solid #dddddd",
                                }}
                                    onClick={toggleMenu}
                                    onMouseEnter={(e) => e.currentTarget.style.color = '#ff9100'}
                                    onMouseLeave={(e) => e.currentTarget.style.color = 'black'}
                                >
                                    {/* <img draggable={false} style={{ width: 20, height: 20 }} src={img3} /> */}
                                    <p style={{ margin: 0 }}>{t("Home.Orders")}</p>
                                </NavLink>

                                <div style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                    fontWeight: '700',
                                    transition: 'color 0.3s',
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "0 0 10px 0",
                                    gap: 10,
                                    borderBottom: "2px solid #dddddd",
                                }}
                                    onClick={() => { setOpenAccunt(!openAccunt); }}>
                                    <div>
                                        <FaRegUser size={"25px"} /> {t('Hedar.My account')}
                                    </div>
                                    <div style={{ padding: "0 20px" }}>
                                        {
                                            openAccunt ?
                                                <BiMinus />
                                                :
                                                <BiPlus />
                                        }
                                    </div>
                                </div>
                                <div style={{
                                    maxHeight: openAccunt ? "290px" : "0px",
                                    overflow: "hidden",
                                    transition: "max-height 0.3s ease-in-out",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 10,
                                    padding: openAccunt ? "0px 20px 0 0" : "0 20px 0 0"
                                }}>

                                    <NavLink style={{
                                        textDecoration: 'none',
                                        color: 'black',
                                        fontWeight: '700',
                                        transition: 'color 0.3s',
                                        display: "flex",
                                        padding: "0 0 10px 0",
                                        gap: 10,
                                        borderBottom: "2px solid #dddddd",
                                    }}
                                        onClick={toggleMenu}
                                        to={`/admin/myAccount/AccountDetals/${userData?.user?.userDetals?._id}`} >
                                        <LuUserCog size={20} /> {t("Hedar.AccountDetals")}
                                    </NavLink>
                                    <div style={{
                                        textDecoration: 'none',
                                        color: 'red',
                                        fontWeight: '700',
                                        transition: 'color 0.3s',
                                        display: "flex",
                                        padding: "0 0 10px 0",
                                        gap: 10,
                                        borderBottom: "2px solid #dddddd",
                                    }}
                                        onClick={LogoutBtn}
                                    >
                                        <TbLogout size={20} /> {t("Hedar.Logout")}
                                    </div>
                                </div>
                                <div>
                                    <SetCurrency />
                                </div>
                                <div>
                                    <I18nAdmin />
                                </div>
                            </div>
                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                {
                                    !isMobile &&
                                    <>
                                        <div>
                                            <NotificationsOrders />
                                        </div>

                                        <div>
                                            <AccuntDropdownAdmin />
                                        </div>
                                        <div>
                                            <Button bsPrefix='BtnBNBIcon' onClick={() => setShowSearch(true)}><IoSearch size={"25px"} /></Button>
                                        </div>
                                        <div>
                                            <SetCurrency />
                                        </div>
                                        <div>
                                            <I18nAdmin />
                                        </div>
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Search show={showSearch} setShowSearch={setShowSearch} />
        </nav>
    </>)
}

export default HedarAdmin;