import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { OrderProps } from "../../../../admin/market/orders/getOrders/GetOrders.admin";
import { useContext, useState } from "react";
import axios from "axios";
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { LoadingBtn } from "../../../../components/laoding/laoding";
import { ChatContext } from "../../../../components/context/ChatContext";
import { setUser } from "../../../../redux/userSlice";

interface dataOrders {
    dataOrder: OrderProps;
    onRefund: () => void;
}

const RefundCard: React.FC<dataOrders> = ({ dataOrder, onRefund }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const userData = useSelector((state: RootState) => state.userData);
    const chatContext = useContext(ChatContext)
    const { dataCurrency, baseCurrency } = chatContext!
    const [stautsBtn, setStatusBtn] = useState<any>(t("Refund Card"))
    const [disabledBtn, setDisabledBtn] = useState<boolean>(false)
    const [stautsBtnFee, setStatusBtnFee] = useState<any>(t("Refund Card"))
    const [disabledBtnFee, setDisabledBtnFee] = useState<boolean>(false)
    const [stautsBtnAmount, setStatusBtnAmount] = useState<any>(t("Refund Card"))
    const [disabledBtnAmount, setDisabledBtnAmount] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState<string>()
    const [canceled, setCanceled] = useState<boolean>(false)
    const [refundAmount, setRefundAmount] = useState<boolean>(false)
    const [valueRefundAmount, setValueRefundAmount] = useState<string>()


    const handleRefund = async () => {
        setDisabledBtn(true)
        setStatusBtn(<LoadingBtn />)
        const NewDataOrder = await axios.get(`${process.env.REACT_APP_URL_API}/API/Orders/GetOrder/${dataOrder._id}`, {
            headers: {
                Authorization: "Bearer " + userData.user.token,
            }
        })
        if (NewDataOrder.data.status === "new" || NewDataOrder.data.status === "viewed" || userData.user.userDetals?.role === true) {
            await axios.post(`${process.env.REACT_APP_URL_API}/API/PayCard/RefundCard`, { paymentIntentid: NewDataOrder.data.paymentIntentid, OrderStatus: NewDataOrder.data.status, dataOrder: NewDataOrder.data.dataItems, role: userData.user.userDetals?.role }, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + userData.user.token,
                }
            })
                .then(async (doc) => {
                    const refundResponse = doc.data.id
                    const dataRefundOrder = { refundCardOrderid: refundResponse, dataOrder: NewDataOrder.data, cancelOrder: true, status: "canceled", role: userData.user.userDetals?.role }
                    await axios.put(`${process.env.REACT_APP_URL_API}/API/Orders/RefundOrder`, dataRefundOrder,
                        {
                            headers: {
                                Authorization: "Bearer " + userData.user.token,
                            }
                        }
                    )
                        .then(async (docc) => {
                            if (docc.data.useWallet) {
                                await axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${userData.user.userDetals?._id}`,
                                    {
                                        headers: {
                                            Authorization: "Bearer " + userData.user.token,
                                        }
                                    })
                                    .then((doc) => {
                                        dispatch(setUser({ userDetals: doc.data }))
                                    })
                                    .catch((err) => console.log(err))
                            }
                            setStatusBtn("Refund Successful")
                            setTimeout(async () => {
                                setStatusBtn(t("Market.PayPage.canceled"))
                            }, 3000)
                            setDisabledBtn(false)
                            setCanceled(true)
                        })
                        .catch((err) => {
                            setStatusBtn(t("Refund Failed"));
                            setErrMsg("err")
                            setTimeout(async () => {
                                setStatusBtn(t("Refund Card"))
                            }, 3000)
                            setDisabledBtn(false)
                        })
                })
        }
        else {
            setDisabledBtn(false)
            setStatusBtn(t("Refund Card"))
            setErrMsg("err")
        }
    }


    const handleRefundFee = async () => {
        setDisabledBtnFee(true)
        setStatusBtnFee(<LoadingBtn />)
        const NewDataOrder = await axios.get(`${process.env.REACT_APP_URL_API}/API/Orders/GetOrder/${dataOrder._id}`, {
            headers: {
                Authorization: "Bearer " + userData.user.token,
            }
        })
        if (NewDataOrder.data.status === "new" || NewDataOrder.data.status === "viewed" || userData.user.userDetals?.role === true) {
            await axios.post(`${process.env.REACT_APP_URL_API}/API/PayCard/RefundCardFee`, { paymentIntentid: NewDataOrder.data.paymentIntentid, OrderStatus: NewDataOrder.data.status, dataOrder: NewDataOrder.data.dataItems, role: userData.user.userDetals?.role }, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + userData.user.token,
                }
            })
                .then(async (doc) => {
                    const refundResponse = doc.data.id
                    const dataRefundOrder = { refundCardOrderid: refundResponse, dataOrder: NewDataOrder.data, cancelOrder: true, status: "canceled", role: userData.user.userDetals?.role }
                    await axios.put(`${process.env.REACT_APP_URL_API}/API/Orders/RefundOrder`, dataRefundOrder,
                        {
                            headers: {
                                Authorization: "Bearer " + userData.user.token,
                            }
                        }
                    )
                        .then(async (docc) => {
                            if (docc.data.useWallet) {
                                await axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${userData.user.userDetals?._id}`,
                                    {
                                        headers: {
                                            Authorization: "Bearer " + userData.user.token,
                                        }
                                    })
                                    .then((doc) => {
                                        dispatch(setUser({ userDetals: doc.data }))
                                    })
                                    .catch((err) => console.log(err))
                            }
                            setStatusBtnFee("Refund Successful")
                            setTimeout(async () => {
                                setStatusBtnFee(t("Market.PayPage.canceled"))
                            }, 3000)
                            setDisabledBtnFee(false)
                            setCanceled(true)
                        })
                        .catch((err) => {
                            setStatusBtnFee(t("Refund Failed"));
                            setErrMsg("err")
                            setTimeout(async () => {
                                setStatusBtnFee(t("Refund Card"))
                            }, 3000)
                            setDisabledBtnFee(false)
                        })
                })
        }
        else {
            setDisabledBtnFee(false)
            setStatusBtnFee(t("Refund Card"))
            setErrMsg("err")
        }
    }


    const handleRefundAmount = async () => {
        setDisabledBtnAmount(true)
        setStatusBtnAmount(<LoadingBtn />)
        const NewDataOrder = await axios.get(`${process.env.REACT_APP_URL_API}/API/Orders/GetOrder/${dataOrder._id}`, {
            headers: {
                Authorization: "Bearer " + userData.user.token,
            }
        })
        if (NewDataOrder.data.status === "new" || NewDataOrder.data.status === "viewed" || userData.user.userDetals?.role === true) {
            const amount = ((Number(valueRefundAmount) / baseCurrency) * dataCurrency.EUR) * 100
            await axios.post(`${process.env.REACT_APP_URL_API}/API/PayCard/RefundCardAmount`, { paymentIntentid: NewDataOrder.data.paymentIntentid, OrderStatus: NewDataOrder.data.status, dataOrder: NewDataOrder.data.dataItems, role: userData.user.userDetals?.role, amount }, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + userData.user.token,
                }
            })
                .then(async (doc) => {
                    const refundResponse = doc.data.id
                    const dataRefundOrder = { refundCardOrderid: refundResponse, dataOrder: NewDataOrder.data, cancelOrder: true, status: "canceled", role: userData.user.userDetals?.role }
                    await axios.put(`${process.env.REACT_APP_URL_API}/API/Orders/RefundOrder`, dataRefundOrder,
                        {
                            headers: {
                                Authorization: "Bearer " + userData.user.token,
                            }
                        }
                    )
                        .then(async (docc) => {
                            if (docc.data.useWallet) {
                                await axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${userData.user.userDetals?._id}`,
                                    {
                                        headers: {
                                            Authorization: "Bearer " + userData.user.token,
                                        }
                                    })
                                    .then((doc) => {
                                        dispatch(setUser({ userDetals: doc.data }))
                                    })
                                    .catch((err) => console.log(err))
                            }
                            setStatusBtnAmount("Refund Successful")
                            setTimeout(async () => {
                                setStatusBtnAmount(t("Market.PayPage.canceled"))
                            }, 3000)
                            setDisabledBtnAmount(false)
                            setCanceled(true)
                        })
                        .catch((err) => {
                            setStatusBtnAmount(t("Refund Failed"));
                            setErrMsg("err")
                            setTimeout(async () => {
                                setStatusBtnAmount(t("Refund Card"))
                            }, 3000)
                            setDisabledBtnAmount(false)
                        })
                })
        }
        else {
            setDisabledBtnAmount(false)
            setStatusBtnAmount(t("Refund Card"))
            setErrMsg("err")
        }
    }

    return (<>
        <div style={{ display: "flex", flexDirection: "column" }}>

            {errMsg &&
                <div style={{ padding: "10px 20px", color: "red", fontSize: 20, textAlign: "center" }}>
                    {errMsg}
                </div>}
            {
                canceled ?
                    <div>
                        {t("Market.The order has been cancelled")}
                    </div>
                    :
                    <div style={{ display: "flex", gap: 10 }}>
                        {
                            !refundAmount &&
                            <div style={{ display: "flex", gap: 10 }}>
                                <div>
                                    <Button variant="danger" onClick={handleRefund} disabled={disabledBtn}>{stautsBtn}</Button>
                                </div>
                                {/* <div>
                                    <Button variant="danger" onClick={handleRefundFee} disabled={disabledBtnFee}>Refund without Fee</Button>
                                </div> */}
                            </div>
                        }
                        <div>
                            {
                                refundAmount ?
                                    <div style={{ textAlign: "center" }}>
                                        <Button variant={"secondary"} onClick={() => setRefundAmount(!refundAmount)} > {t("Hedar.Close")}</Button>
                                    </div>
                                    :
                                    <div style={{ textAlign: "center" }}>
                                        <Button variant={"danger"} onClick={() => setRefundAmount(!refundAmount)}> Refund Amount </Button>
                                    </div>
                            }
                            {
                                refundAmount &&
                                <div style={{ display: "flex", flexDirection: "column", gap: 10, marginTop: 10, textAlign: "center" }}>
                                    <div>
                                        <Form.Control disabled={!refundAmount} type="number" value={Number(valueRefundAmount)} onChange={(e) => { setValueRefundAmount(e.target.value) }} />
                                    </div>
                                    <div>
                                        <Button variant="danger" onClick={handleRefundAmount} disabled={disabledBtnAmount}>{stautsBtnAmount} Amount</Button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
            }
        </div>
    </>)
}

export default RefundCard;