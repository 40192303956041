import React, { useEffect, useState } from "react";
import { OrderProps } from "../../admin/market/orders/getOrders/GetOrders.admin";
import { LoadingBtn } from "../../components/laoding/laoding";
import axios from "axios";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router";
import { Button } from "react-bootstrap";
import { ThemeColor } from "../../theme";



const MyOrdersMobile: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData)
    const { t } = useTranslation()
    const nav = useNavigate()
    const [dataOrders, setDataOrders] = useState<OrderProps[]>()
    const [statusBtn, setStatusBtn] = useState<any>(t("Market.View more"))
    const [disabledBtn, setDisabled] = useState<boolean>(false)
    const [limit, setLimit] = useState<number>(30)

    useEffect(() => {
        setStatusBtn(<LoadingBtn />)
        setDisabled(true)
        axios.get(`${process.env.REACT_APP_URL_API}/API/Orders/GetOrdersUserId/${userData?.user?.userDetals?._id}/${limit}`, {
            headers: {
                Authorization: "Bearer " + userData.user.token,
            }
        })
            .then((doc) => setDataOrders(doc.data))
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtn(t("Market.View more"))
                setDisabled(false)
            })
        window.scrollTo(0, 0)
    }, [userData?.user?.userDetals?._id, userData.user.token, limit])


    return (<>

        <div style={{ display: "flex", justifyContent: "center", backgroundColor: "#ededed" }}>
            <div style={{ maxWidth: "1440px", width: "100%", padding: 0 }}>
                {
                    dataOrders?.map((order, index) => (
                        <div key={index} style={{ border: "1px solid #dddddd", backgroundColor: "white", margin: 10 }}>
                            <div style={{ display: "flex", justifyContent: "space-between", padding: 10, borderBottom: "1px solid #dddddd" }}>
                                <div>
                                    <div>
                                        {new Date(order?.createdAt!).toLocaleString('en-GB', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false
                                        })}
                                    </div>
                                    <div style={{ color: ThemeColor.text }}>
                                        {
                                            order.totalPrice?.toFixed(2) + " " + order.currency
                                        }
                                    </div>
                                </div>
                                <div>
                                    <Button bsPrefix="BtnBNB" style={{ height: "100%" }} onClick={() => nav(`/client/myAccount/orderDetals/${order._id}`)}>{t("Market.Order details")}</Button>
                                </div>
                            </div>
                            <div style={{ padding: 10 }}>
                                <div>
                                    {t(`Market.PayPage.${order.status}`)}
                                </div>
                                <div style={{ display: "flex", gap: 5, flexWrap: "wrap" }}>
                                    {
                                        order.dataItems?.map((product, index) => (
                                            <img key={index} draggable={false} alt="وصف الصورة" style={{ width: 70, height: 70 }} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${product.file ? product.file : ""}`} />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }
                <div style={{ textAlign: "center", margin: "20px 0" }}>
                    <Button bsPrefix="BtnBNB" onClick={() => { setLimit(limit + 20) }} disabled={disabledBtn} >{statusBtn}</Button>
                </div>
            </div>
        </div >
    </>)
}

export default MyOrdersMobile;