import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import CartItemCompletePage from "../../../components/cartItem/CartItemCompletePage";
import { useTranslation } from "react-i18next";
import BasicExample from "./Accordion";
import { ChatContext } from "../../../components/context/ChatContext";
import { useContext, useEffect, useState } from "react";
import { cleenItems, subTotall, totall } from "../../../redux/counterSlice";
import axios from "axios";
import { OthersProps, UsersProps } from "../../../Interfaces";
import { OrderContext } from "../../../components/context/OrderContext";
import Langu from "../../../components/languge/languge";
import { Button, Form } from "react-bootstrap";
import { LoadingBtn } from "../../../components/laoding/laoding";
import { useNavigate } from "react-router";
import { setUser } from "../../../redux/userSlice";
import CardPayment from "./CardPayment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROMISE as string);

const CompleteOrder: React.FC = () => {
    const CarentItem = useSelector((state: RootState) => state.counterData)
    const userData = useSelector((state: RootState) => state.userData)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const nav = useNavigate()
    const orderContext = useContext(OrderContext);
    const { isMobile } = orderContext!
    const chatContext = useContext(ChatContext);
    const { baseCurrency, currency } = chatContext!;
    const total = totall(CarentItem)
    const subTotal = subTotall(CarentItem)
    const [dataUserId, setDataUserId] = useState<UsersProps>()
    const [dataOthers, setDataOthers] = useState<OthersProps>()
    const [disabled2, setDisabled2] = useState<boolean>(false)
    const [statusBtn, setStatusBtn] = useState<any>(t("Home.confirmAddress"))
    const [errPickup, setErrPickup] = useState<string>()
    const [pickup, setPickup] = useState<string>()
    const [disabled, setDisabled] = useState<boolean>(false)
    const [confirmBtn, setConfirmBtn] = useState<any>(t("Market.PayPage.confirm"))
    const [country, setCountry] = useState<string | undefined>(dataUserId ? dataUserId?.address?.country : "")
    const [neighborhood, setNeighborhood] = useState<string | undefined>(dataUserId ? dataUserId?.address?.neighborhood : "")
    const [street, setStreet] = useState<string | undefined>(dataUserId ? dataUserId?.address?.street : "")
    const [buildingNumber, setBuildingNumber] = useState<string | number | undefined>(dataUserId ? dataUserId?.address?.buildingNumber : "")
    const [floor, setFloor] = useState<number | undefined>(dataUserId ? dataUserId?.address?.floor : 0)
    const [apartmentNo, setApartmentNo] = useState<number | undefined>(dataUserId ? dataUserId?.address?.apartmentNo : 0)
    const [disabledPay, setDisabledPay] = useState<boolean>(true)


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${userData?.user?.userDetals?._id}`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => setDataUserId(doc.data))
            .catch((err) => console.log(err))
        axios.get(`${process.env.REACT_APP_URL_API}/API/Others/GetOthers`)
            .then((doc) => setDataOthers(doc.data?.[0]))
            .catch((err) => console.log(err))
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        setCountry(dataUserId ? dataUserId?.address?.country : "")
        setNeighborhood(dataUserId ? dataUserId?.address?.neighborhood : "")
        setStreet(dataUserId ? dataUserId?.address?.street : "")
        setBuildingNumber(dataUserId ? dataUserId?.address?.buildingNumber : "")
        setFloor(dataUserId ? dataUserId?.address?.floor : 0)
        setApartmentNo(dataUserId ? dataUserId?.address?.apartmentNo : 0)
    }, [dataUserId])

    const location = userData.user.userDetals?.address?.country;

    // console.log(userData.user.userDetals?.address)
    // console.log(dataUserId?.address)

    const Delivery = location && (location === "West Bank" ? dataOthers?.delivery?.westBank : location === "Inside" ? dataOthers?.delivery?.inside : dataOthers?.delivery?.jordan)

    const Total: number = subTotal + Number(Delivery)


    const finshNumber = (num: number) => {
        const Currency = currency === "ILS" ? "ILS" : currency === "USD" ? "USD" : "JOD"
        if (currency === "ILS") {
            const afterNum = (Number(num) * baseCurrency)
            return (afterNum.toFixed(2)) + " " + Currency
        }
        else {
            return (Number(num) * baseCurrency).toFixed(2) + " " + Currency
        }
    }

    const dataItems = CarentItem?.cartItems.map((item) => {
        return {
            itemId: item.data._id,
            product: item.data.product,
            price: Number(item.data.price) * baseCurrency,
            newPrice: Number(item.data.newPrice) * baseCurrency,
            offerForTwoProducts: item.data.offerForTwoProducts,
            priceOfferForTwoProducts: Number(item.data.priceOfferForTwoProducts) * baseCurrency,
            offers: item.data.offers,
            quantity: item.quantity,
            color: item.color,
            comment: item.comments,
            file: item.data.file?.[0],
        }
    })

    const ProductDiscount: number = CarentItem?.cartItems?.reduce((total, cartitem) => {
        return total + (cartitem.data.newPrice! ? ((cartitem.data.price! - cartitem.data.newPrice!) || 0) : 0) * cartitem.quantity!
    }, 0)!

    const offerForTwoProducts = CarentItem?.cartItems?.reduce((total, cartitem) => {
        const totall = total + ((cartitem.data.offerForTwoProducts && cartitem.quantity! >= 2 && (((cartitem.data.newPrice! || cartitem.data.price!) - cartitem.data.priceOfferForTwoProducts!) || 0)) || 0);
        return totall;
    }, 0)!;

    const dataPutAddress = { address: { country, neighborhood, street, buildingNumber, floor, apartmentNo } }


    const dataPostOrder = {
        userId: userData?.user?.userDetals?._id!,
        phone: userData?.user?.userDetals?.phone!,
        email: userData.user.userDetals?.email,
        paymentMethod: pickup,
        dataItems,
        userName: userData.user.userDetals?.userName!,
        address: dataPutAddress,
        useWallet: CarentItem.useWallet,
        valueWallet: { currency: CarentItem.valueWallet * baseCurrency, usd: CarentItem.valueWallet },
        totalPrice: subTotal * baseCurrency,
        finalPrice: Total * baseCurrency,
        delivery: Number(Delivery) * baseCurrency,
        currency,
        baseCurrency: baseCurrency
    }

    const putAddressUser = async () => {
        setDisabled2(true)
        setStatusBtn(<LoadingBtn />)
        await axios.put(`${process.env.REACT_APP_URL_API}/API/Users/PutAdressUserId/${userData?.user?.userDetals?._id}`,
            dataPutAddress,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then(() => {
                setDisabledPay(false)
                dispatch(setUser({ userDetals: { ...userData.user.userDetals, address: { country, neighborhood, street, buildingNumber, floor, apartmentNo } } }))
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtn(t("Home.confirmAddress"))
                setDisabled2(false)
            })
    }

    const CreateOrder = async () => {
        setDisabled(true)
        setConfirmBtn(<LoadingBtn />)
        await axios.post(`${process.env.REACT_APP_URL_API}/API/Orders/PostOrder`, dataPostOrder, {
            headers: {
                Authorization: "Bearer " + userData.user.token,
            }
        })
            .then((doc) => {
                dispatch(cleenItems())
                nav(`/client/market/invoice/${doc.data._id}`)
            })
            .catch(err => console.log(err))
            .finally(() => {
                setConfirmBtn(t("Market.PayPage.confirm"))
                setDisabled(false)
            })
    }

    return (<>
        {
            isMobile ?
                <div>
                    {CarentItem.cartItems.map((product, index) => (
                        <div key={index} style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #dddddd", padding: 10 }}>
                            <img style={{ height: "50px", width: "60px", borderRadius: "8px" }} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${product?.data?.file?.[0]}`} alt={product.data.product?.en} />
                            <div style={{}}>
                                <Langu {...product.data.product!} />
                                {
                                    product.data.colors &&
                                    <div style={{ width: '50px', textAlign: "center" }}>
                                        <div style={{ minWidth: 20, maxWidth: 20, minHeight: 20, maxHeight: 20, backgroundColor: product.data.colors?.[0] }}></div>
                                    </div>
                                }
                            </div>
                            <div>
                                x{product.quantity}
                            </div>
                            {
                                product.data.newPrice ?
                                    <div>
                                        <div style={{ textDecoration: "line-through", color: "gray" }}>
                                            {(finshNumber(product.data?.price! * product.quantity))}
                                        </div>
                                        <div style={{ color: "red" }}>
                                            {(finshNumber(product.data?.newPrice * product.quantity))}
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        {(finshNumber(product.data?.price! * product.quantity))}
                                    </div>
                            }
                        </div>
                    ))}
                    <div style={{ padding: "30px 10px 10px 10px" }}>
                        <div style={{ fontSize: "16px", fontWeight: "500", display: 'flex', justifyContent: "space-between" }}>
                            <div>
                                {t("Market.PayPage.Subtotal")}
                            </div>
                            <div>
                                {finshNumber(total)}
                            </div>
                        </div>
                        <div style={{ fontSize: "16px", fontWeight: "500", display: 'flex', justifyContent: "space-between" }}>
                            <div>
                                {t("Market.PayPage.shipping")}
                            </div>
                            <div>
                                {finshNumber(Number(Delivery))}
                            </div>
                        </div>
                        {
                            ProductDiscount > 0 &&
                            <div style={{ fontSize: "18px", fontWeight: "500", display: 'flex', justifyContent: "space-between", color: "red" }}>
                                <div>
                                    {t('Market.discount')}
                                </div>
                                <div>
                                    - {finshNumber(ProductDiscount)}
                                </div>
                            </div>
                        }
                        {
                            CarentItem.useWallet &&
                            <div style={{ fontSize: "18px", fontWeight: "500", display: 'flex', justifyContent: "space-between", color: "red" }}>
                                <div>
                                    {t("Market.PayPage.useWallet")}
                                </div>
                                <div>
                                    - {finshNumber(CarentItem.valueWallet)}
                                </div>
                            </div>
                        }
                        {
                            offerForTwoProducts > 0 &&
                            <div style={{ fontSize: "18px", fontWeight: "500", display: 'flex', justifyContent: "space-between", color: "red" }}>
                                <div>
                                    {t("Market.PayPage.Second product discount")}
                                </div>
                                <div>
                                    - {finshNumber(offerForTwoProducts)}
                                </div>
                            </div>
                        }
                        <div style={{ fontSize: "186x", fontWeight: "700", display: 'flex', justifyContent: "space-between" }}>
                            <div style={{ fontWeight: "700" }}>
                                {t("Market.PayPage.Total")}
                            </div>
                            <div>
                                {finshNumber(Total)}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", paddingTop: 15, borderTop: "1px solid #dddddd" }}>
                        <div style={{ marginBottom: "20px", fontSize: 20, fontWeight: 600 }}>
                            {t("Market.PayPage.addres")}
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", borderRadius: "10px", justifyContent: "center" }}>
                            <div style={{ width: "100%", maxWidth: "1000px", textAlign: "center" }}>
                                <Form.Label>{t("Market.PayPage.Country")} :</Form.Label>
                                <Form.Group className="mb-3" controlId="exampleForm.Product" style={{ display: "flex", textAlign: "center", width: "100%", justifyContent: "center" }}>
                                    <Form.Select onChange={e => { setCountry(e.target.value) }} style={{ textAlign: "center", width: "80%" }} value={country} defaultValue={country}>
                                        <option value={"West Bank"}>{t("Home.West Bank")}</option>
                                        <option value={"Inside"}>{t("Home.Inside")}</option>
                                        <option value={"Jordan"}>{t("Home.Jordan")}</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column", textAlign: "center" }}>
                                <Form.Label>{t("Market.PayPage.Neighborhood")} :</Form.Label>
                                <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "flex", textAlign: "center", width: "100%", justifyContent: "center" }}>
                                    <Form.Control type="text" placeholder="" style={{ textAlign: "center", width: "80%" }} onChange={(e) => setNeighborhood(e.target.value)} defaultValue={dataUserId?.address?.neighborhood} />
                                </Form.Group>
                            </div>

                            <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column", textAlign: "center" }}>
                                <Form.Label>{t("Market.PayPage.Street")} :</Form.Label>
                                <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "flex", textAlign: "center", width: "100%", justifyContent: "center" }}>
                                    <Form.Control type="text" placeholder="" style={{ textAlign: "center", width: "80%" }} onChange={(e) => setStreet(e.target.value)} defaultValue={dataUserId?.address?.street} />
                                </Form.Group>
                            </div>
                            <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column", textAlign: "center" }}>
                                <Form.Label> {t("Market.PayPage.Building number")}:</Form.Label>
                                <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "flex", textAlign: "center", width: "100%", justifyContent: "center" }}>
                                    <Form.Control type="text" placeholder="" style={{ textAlign: "center", width: "80%" }} onChange={(e) => setBuildingNumber(e.target.value)} defaultValue={dataUserId?.address?.buildingNumber} />
                                </Form.Group>
                            </div>
                            <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column", textAlign: "center" }}>
                                <Form.Label>{t('Market.PayPage.floor')} :</Form.Label>
                                <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "flex", textAlign: "center", width: "100%", justifyContent: "center" }}>
                                    <Form.Control type="number" placeholder="" style={{ textAlign: "center", width: "80%" }} onChange={(e) => setFloor(parseInt(e.target.value))} defaultValue={dataUserId?.address?.floor} />
                                </Form.Group>
                            </div>
                            <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column", textAlign: "center" }}>
                                <Form.Label> {t("Market.PayPage.Apartment No")} :</Form.Label>
                                <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "flex", textAlign: "center", width: "100%", justifyContent: "center" }}>
                                    <Form.Control type="number" placeholder="" style={{ textAlign: "center", width: "80%" }} onChange={(e) => setApartmentNo(parseInt(e.target.value))} defaultValue={dataUserId?.address?.apartmentNo} />
                                </Form.Group>
                            </div>
                            <div style={{ padding: 15 }}>
                                <Button bsPrefix="BtnBNB" disabled={disabled2} onClick={putAddressUser}>{statusBtn}</Button>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 15, borderTop: "1px solid #dddddd" }}>
                        <div style={{ fontSize: "18px", fontWeight: 600, padding: 10 }}>
                            {t('Market.PayPage.Payment')}
                        </div>
                        <div style={{
                            opacity: disabledPay ? 0 : 1, maxHeight: disabledPay ? 0 : "100%", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", gap: 20, transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
                            transform: disabledPay ? 'translateY(-20px)' : 'translateY(0)',
                        }}>
                            <div style={{ display: "flex", gap: 15, border: "1px solid #dddddd", padding: 20, borderRadius: 10, width: "50%" }}
                                onClick={() => { setPickup("cash"); setErrPickup('') }}>
                                <Form.Check
                                    id="cash"
                                    value={'cash'}
                                    name="pickup"
                                    type="radio"
                                    checked={pickup === "cash"}
                                    style={{ fontSize: 20, fontWeight: 600 }}
                                />
                                <label htmlFor={"cash"} style={{ fontSize: 18, fontWeight: 600 }} >{t(`Market.PayPage.cash`)}</label>
                            </div>
                            <div style={{ display: "flex", gap: 15, border: "1px solid #dddddd", padding: 20, borderRadius: 10, width: "50%", marginBottom: 10 }}
                                onClick={() => { setPickup("card"); setErrPickup('') }}>
                                <Form.Check
                                    id="card"
                                    value={'card'}
                                    name="pickup"
                                    type="radio"
                                    checked={pickup === "card"}
                                    style={{ fontSize: 20, fontWeight: 600 }}
                                />
                                <label htmlFor={"card"} style={{ fontSize: 18, fontWeight: 600 }}>{t(`Market.PayPage.card`)}</label>
                            </div>
                        </div>

                        {errPickup &&
                            <div style={{ color: "red", padding: 10, backgroundColor: "#ffe4e4", textAlign: "center", fontSize: 18, fontWeight: 600 }}>
                                {errPickup}
                            </div>
                        }
                        {pickup === "cash" &&
                            <div style={{ width: "100%", textAlign: "center", borderTop: "1px solid #dddddd", padding: 10 }}>
                                <Button bsPrefix="BtnBNB" onClick={CreateOrder} disabled={disabled}>{confirmBtn}</Button>
                            </div>
                        }
                        {
                            pickup === "card" &&
                            <div style={{ width: "100%", padding: 10, borderTop: "1px solid #dddddd" }}>
                                <Elements stripe={stripePromise}>
                                    <CardPayment finalPrice={Total} totalPrice={subTotal} cartItems={dataItems} payment={pickup} userId={userData.user.userDetals?._id!} receiptAddress={dataPutAddress} />
                                </Elements>
                            </div>
                        }
                    </div>
                </div>
                :
                <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>
                        {BasicExample()}
                    </div>
                    <div style={{ width: "50%", backgroundColor: "rgb(241 241 241)", minHeight: "100vh", padding: "0 20px" }}>
                        <div style={{ position: "sticky", top: "75px", marginTop: "50px", width: "500px" }}>
                            <div>
                                {CarentItem?.cartItems.map((item: any, index) => { return item ? <CartItemCompletePage key={index} data={item} /> : null })}
                            </div>
                            <div>
                                <div style={{ fontSize: "18px", fontWeight: "500", display: 'flex', width: "500px", justifyContent: "space-between" }}>
                                    <div>
                                        {t("Market.PayPage.Subtotal")}
                                    </div>
                                    <div>
                                        {finshNumber(total)}
                                    </div>
                                </div>
                                {
                                    ProductDiscount > 0 &&
                                    <div style={{ fontSize: "18px", fontWeight: "500", display: 'flex', width: "500px", justifyContent: "space-between", color: "red" }}>
                                        <div>
                                            {t('Market.discount')}
                                        </div>
                                        <div>
                                            - {finshNumber(ProductDiscount)}
                                        </div>
                                    </div>
                                }
                                {
                                    CarentItem.useWallet &&
                                    <div style={{ fontSize: "18px", fontWeight: "500", display: 'flex', width: "500px", justifyContent: "space-between", color: "red" }}>
                                        <div>
                                            {t("Market.PayPage.useWallet")}
                                        </div>
                                        <div>
                                            - {finshNumber(CarentItem.valueWallet)}
                                        </div>
                                    </div>
                                }
                                {
                                    offerForTwoProducts >= 0 &&
                                    <div style={{ fontSize: "18px", fontWeight: "500", display: 'flex', width: "500px", justifyContent: "space-between", color: "red" }}>
                                        <div>
                                            {t("Market.PayPage.Second product discount")}
                                        </div>
                                        <div>
                                            - {finshNumber(offerForTwoProducts)}
                                        </div>
                                    </div>
                                }
                                <div style={{ fontSize: "18px", fontWeight: "500", display: 'flex', width: "500px", justifyContent: "space-between" }}>
                                    <div>
                                        {t("Market.PayPage.shipping")}
                                    </div>
                                    <div>
                                        {finshNumber(Number(Delivery))}
                                    </div>
                                </div>
                                <div style={{ fontSize: "18px", fontWeight: "500", display: 'flex', width: "500px", justifyContent: "space-between" }}>
                                    <div>
                                        {t("Market.PayPage.Total")}
                                    </div>
                                    <div>
                                        {finshNumber(Total)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
    </>)
}

export default CompleteOrder;