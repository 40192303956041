import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import Langu from "../../components/languge/languge";
import AdminCardPeoduct from "../components/CardsProducts/AdminCardProduct";
import { useTranslation } from "react-i18next";
import { Brand, Product } from "../../Interfaces";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import { useNavigate } from "react-router";
import { OrderContext } from "../../components/context/OrderContext";


const ShoppBrandAdmin: React.FC = () => {
    const { t } = useTranslation()
    const nav = useNavigate()
    const ordercontext = useContext(OrderContext)
    const { isMobile } = ordercontext!
    const [dataCategorys, setDataCategorys] = useState<Brand[]>()
    const [dataBrand, setDataBrand] = useState<Brand | undefined>(dataCategorys ? dataCategorys[0] : undefined);
    const [dataProducts, setDataProducts] = useState<Product[]>()
    const scrollRef = useRef<HTMLDivElement>(null);
    const isDragging = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);
    const dragThreshold = 5;
    const [dragging, setDragging] = useState(false);
    const [isAtEnd, setIsAtEnd] = useState(false);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Brands/GetBrands`)
            .then((doc) => setDataCategorys(doc.data))
            .catch((err) => console.log(err))
    }, [])

    useEffect(() => {
        setDataBrand(dataCategorys ? dataCategorys[0] : undefined)
    }, [dataCategorys])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProductsBrand/${dataBrand?.name.en}`)
            .then((doc) => setDataProducts(doc.data))
            .catch((err) => console.log(err))
    }, [dataBrand])

    const onMouseDown = (e: React.MouseEvent) => {
        isDragging.current = true;
        startX.current = e.pageX - (scrollRef.current?.offsetLeft || 0);
        scrollLeft.current = scrollRef.current?.scrollLeft || 0;
        setDragging(false);
    };

    const onMouseLeaveOrUp = (e: React.MouseEvent) => {
        if (isDragging.current) {
            const x = e.pageX - (scrollRef.current?.offsetLeft || 0);
            const distance = Math.abs(x - startX.current);
            if (distance > dragThreshold) {
                setDragging(true);
            }
        }
        isDragging.current = false;
    };

    const onMouseMove = (e: React.MouseEvent) => {
        if (!isDragging.current) return;
        e.preventDefault();
        const x = e.pageX - (scrollRef.current?.offsetLeft || 0);
        const walk = (x - startX.current) * 1;
        if (scrollRef.current) {
            scrollRef.current.scrollLeft = scrollLeft.current - walk;
        }
    };
    const handleScroll = () => {
        if (scrollRef.current) {
            const { scrollWidth, scrollLeft, clientWidth } = scrollRef.current;
            setIsAtEnd((scrollLeft * -1) + clientWidth >= scrollWidth - 1);
        }
    };

    return (<>
        <div style={{ padding: isMobile ? "40px 0px " : "40px 20px" }}>
            <div style={{
                display: "flex",
                width: "100%",
                padding: "0",
                justifyContent: "center",
                marginBottom: "50px",
                position: "relative",
            }}>
                {
                    !isMobile &&
                    <div style={{ width: "150px" }}></div>
                }
                <h2 style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    {t("Home.Shop by brand")}
                </h2>
                <div style={{
                    position: "absolute",
                    bottom: "-10px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                }}>
                    <div style={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: "black",
                        borderRadius: "50%",
                    }}></div>
                    <div style={{
                        width: "50px",
                        height: "4px",
                        backgroundColor: "black",
                        borderRadius: "2px",
                    }}></div>
                    <div style={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: "black",
                        borderRadius: "50%",
                    }}></div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Button
                        bsPrefix="BrandShoppBrand"
                        style={{ width: "150px", backgroundColor: "white" }}
                        onClick={() => nav(`/admin/market/GetShoppBrandClient/${dataBrand?.name.en}`)}
                    >
                        {t("Home.Show all")}
                    </Button>
                </div>
            </div>
            <div style={{ display: "flex", gap: isMobile ? 5 : "30px", justifyContent: "center", margin: "30px 0 30px 0 " }}>
                {
                    dataCategorys?.map((brand, index) => <div key={index}>
                        <div style={{ display: "flex", justifyContent: "space-evenly", minHeight: "50px", gap: "30px" }}>
                            {
                                brand === dataBrand ?
                                    <Button key={index} bsPrefix="BrandShoppBrand" style={{ backgroundColor: "white", border: "1px solid #6f24d7", color: "#6f24d7", width: isMobile ? 60 : undefined, padding: "0", textAlign: "center" }} onClick={() => setDataBrand(brand)}><Langu {...brand.name} /></Button>
                                    :
                                    <Button key={index} bsPrefix="BrandShoppBrand" style={{ backgroundColor: "white", width: isMobile ? 60 : undefined, padding: isMobile ? "0" : "0 20px", textAlign: "center" }} onClick={() => setDataBrand(brand)}><Langu {...brand.name} /></Button>
                            }
                        </div>
                    </div>
                    )
                }
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", padding: isMobile ? 5 : "0 30px" }}>
                <MdKeyboardDoubleArrowRight
                    size={40}
                    color={scrollRef.current?.scrollLeft !== 0 ? "black" : "#dddddd"}
                    onClick={() => {
                        if (scrollRef.current) {
                            scrollRef.current.scrollLeft = 0;
                        }
                    }}
                    style={{ cursor: "pointer", animation: "0.5s" }}
                />
                <MdKeyboardDoubleArrowLeft
                    size={40}
                    color={isAtEnd ? "#dddddd" : "black"}
                    style={{ cursor: "pointer", animation: "0.5s" }}
                    onClick={() => {
                        if (scrollRef.current) {
                            const scrollLeft = (scrollRef.current?.scrollWidth - scrollRef.current?.clientWidth) * -1
                            scrollRef.current.scrollLeft = scrollLeft;
                        }
                    }}
                />
            </div>
            <div
                ref={scrollRef}
                onMouseDown={onMouseDown}
                onMouseLeave={onMouseLeaveOrUp}
                onMouseUp={onMouseLeaveOrUp}
                onMouseMove={onMouseMove}
                onScroll={handleScroll}
                style={{ display: 'flex', width: "100%", gap: "2%", overflowX: "auto", scrollbarWidth: "none", cursor: "grab" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", gap: isMobile ? "3%" : 20, flexWrap: "nowrap", padding: "0 20px" }}>
                    {
                        dataProducts?.length! > 0 ? dataProducts?.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()).slice(0, 10).map((Product, index) => (
                            <AdminCardPeoduct Product={Product} dragging={dragging} key={index} />
                        ))
                            :
                            <div style={{ width: "100%", display: 'flex', justifyContent: "center", minHeight: "50vh", alignItems: "center", fontSize: "22px" }}>
                                {t("Market.There are no products with these specifications.")}
                            </div>
                    }
                </div>
            </div>
        </div>
    </>)
}
export default ShoppBrandAdmin;