import { useStripe, useElements, CardElement, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LoadingBtn } from "../../../components/laoding/laoding";
import { cleenItems, WalletRedux } from "../../../redux/counterSlice";
import { setUser } from "../../../redux/userSlice";
import { useNavigate } from "react-router";
import { OthersProps } from "../../../Interfaces";
import { ChatContext } from "../../../components/context/ChatContext";
import { OrderContext } from "../../../components/context/OrderContext";


interface CardPaymentProps {
    totalPrice: number;
    finalPrice: number;
    cartItems: {
        itemId: string | undefined;
        product: { en: string; ar: string } | undefined;
        price: number | undefined;
        newPrice: number | undefined;
        offerForTwoProducts: boolean | undefined;
        priceOfferForTwoProducts: number | undefined;
        offers: boolean | undefined;
        quantity: number;
        color: string | undefined;
    }[];
    payment: string;
    receiptAddress: {
        address?: {
            country?: string;
            neighborhood?: string;
            street?: string;
            buildingNumber?: string | number;
            floor?: number;
            apartmentNo?: number;
        }
    };
    userId: string;
}

const CardPayment: React.FC<CardPaymentProps> = ({ totalPrice, cartItems, payment, receiptAddress, userId }) => {
    const userData = useSelector((state: RootState) => state.userData)
    const CarentItem = useSelector((state: RootState) => state.counterData)
    const { t } = useTranslation()
    const stripe = useStripe()
    const elements = useElements()
    const dispatch = useDispatch()
    const nav = useNavigate()
    const chatContext = useContext(ChatContext)
    const { baseCurrency, currency, dataCurrency } = chatContext!
    const orderContext = useContext(OrderContext);
    const { isMobile } = orderContext!
    const [cardName, setCardName] = useState<string>()
    const [cardPhone, setCardPhone] = useState<string>()
    const [cardEmail, setCardEmail] = useState<string>()
    const [cardAddress, setCardAddress] = useState<string>()
    const [cardErr, setCardErr] = useState<string>()
    const [statusBtnPay, setStatusBtnPay] = useState<any>(t("Market.pay"))
    const [dataOthers, setDataOthers] = useState<OthersProps>()


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Others/GetOthers`)
            .then((doc) => setDataOthers(doc.data?.[0]))
            .catch((err) => console.log(err))
    }, [])
    const handleCardChange = (event: StripeCardElementChangeEvent) => {
        if (event.error) {
            setCardErr(event.error.message);
        } else {
            setCardErr('');
        }
    };

    const location = userData.user.userDetals?.address?.country;

    const delivery = location && (location === "West Bank" ? dataOthers?.delivery?.westBank : location === "Inside" ? dataOthers?.delivery?.inside : dataOthers?.delivery?.jordan)

    const handlaPayment = async () => {
        setStatusBtnPay(<LoadingBtn />)
        if (!elements || !stripe) {
            console.error("Stripe Elements لم يتم تحميله بعد");
            return;
        }

        const cardElement = elements.getElement(CardNumberElement)

        if (!cardElement) {
            console.error("Card element not found.");
            return;
        }

        const billinginfo = {
            name: cardName,
            // phone: cardPhone,
            email: userData.user.userDetals?.email,
            // address: {
            //     line1: cardAddress,
            // }
        }

        const offerForTwoProducts = cartItems?.reduce((total, cartitem) => {
            const totall = total + ((cartitem.offerForTwoProducts && cartitem.quantity! >= 2 && (((cartitem.newPrice! || cartitem.price!) - cartitem.priceOfferForTwoProducts!) || 0)) || 0);
            return totall;
        }, 0)!;

        const ProductDiscount: number = cartItems?.reduce((total, cartitem) => {
            return total + ((cartitem.newPrice! ? ((cartitem.price! - cartitem.newPrice!) || 0) : 0) * cartitem.quantity!)
        }, 0)!

        const finalPrice = (((totalPrice * baseCurrency) + (Number(delivery) * baseCurrency) - (CarentItem.valueWallet * baseCurrency) - (ProductDiscount) - (offerForTwoProducts)) / dataCurrency.ILS) * dataCurrency.EUR

        try {
            const paymentIntent = await axios.post(`${process.env.REACT_APP_URL_API}/API/PayCard/PaymentsCard`, {
                amount: Math.round(finalPrice * 100)
            }, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + userData.user.token,
                }
            });

            const paymentIntentid = paymentIntent.data.id;
            const dataPostOrder = {
                dataItems: cartItems,
                payment,
                address: receiptAddress,
                userId,
                totalPrice: totalPrice * baseCurrency,
                finalPrice: finalPrice * baseCurrency,
                paymentMethod: payment,
                paymentIntentid,
                phone: userData?.user?.userDetals?.phone!,
                email: userData.user.userDetals?.email,
                userName: userData.user.userDetals?.userName!,
                useWallet: CarentItem.useWallet,
                valueWallet: { currency: CarentItem.valueWallet * baseCurrency, usd: CarentItem.valueWallet },
                delivery: Number(delivery) * baseCurrency,
                currency,
                baseCurrency: baseCurrency
            };

            const paymentMethodObj = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: billinginfo,
            });
            if (paymentMethodObj.error) {
                setCardErr(paymentMethodObj.error.message)
                setStatusBtnPay(t("Pay"))
            }
            const confirmedPayment = await stripe.confirmCardPayment(
                paymentIntent.data.client_secret,
                { payment_method: paymentMethodObj.paymentMethod!.id }
            )
            if (confirmedPayment.error) {
                setCardErr(confirmedPayment.error.message)
                setStatusBtnPay(t("Pay"))
            }
            await axios.post(`${process.env.REACT_APP_URL_API}/API/Orders/PostOrder`, dataPostOrder, {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            })
                .then((doc) => {
                    setStatusBtnPay(t("Market.pay"))
                    dispatch(cleenItems())
                    dispatch(setUser({ userDetals: { ...userData.user.userDetals, pointsInvite: userData.user.userDetals?.pointsInvite! - CarentItem.valueWallet } }));
                    dispatch(WalletRedux({ useWallet: false, valueWallet: 0 }))
                    nav(`/client/market/invoice/${doc.data._id}`)
                })
                .catch(err => console.log(err))
        } catch (error) {
            setStatusBtnPay(t("Market.errPay"))
            setTimeout(() => {
                setStatusBtnPay(t("Market.pay"))
            }, 3000)
            console.error("Error processing payment:", error);
        }
    }

    return (
        <div>
            {
                cardErr &&
                <div style={{ backgroundColor: "#ffeaea", textAlign: "center", color: "red" }}>
                    {cardErr}
                </div>
            }
            {/* <div>
                <CardElement
                    options={{ hidePostalCode: true, style: { base: { fontSize: isMobile ? "18px" : "28px" } } }}
                    onChange={handleCardChange}
                />
            </div> */}
            <form onSubmit={handlaPayment} className="payment-form" style={{ padding: isMobile ? 20 : 40 }}>
                <input
                    type="text"
                    placeholder={'Card Name'}
                    value={cardName}
                    onChange={(e) => setCardName(e.target.value)}
                    className="input-field"
                />
                <div className="stripe-element">
                    <CardNumberElement options={{ showIcon: true }} />
                </div>

                <div className="row">
                    <div className="col">
                        <div className="stripe-element">
                            <CardExpiryElement />
                        </div>
                    </div>
                    <div className="col">
                        <div className="stripe-element">
                            <CardCvcElement />
                        </div>
                    </div>
                </div>

                {/* <button type="submit" disabled={!stripe} className="pay-button">إتمام الدفع</button> */}
                <div style={{ textAlign: "center" }}>
                    <Button bsPrefix="BtnBNB" onClick={handlaPayment}>{statusBtnPay}</Button>
                </div>
            </form>

        </div>
    );
}

export default CardPayment;
