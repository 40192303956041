interface Notification {
    chatId?: string;
    createdAt?: Date;
    senderId?: string;
    text?: string;
    updatedAt?: Date;
    isRead: boolean;
    _id?: string
}

interface UnreadNotificationProps {
    notificationDB: {
        chatId?: string;
        createdAt?: Date;
        senderId?: string;
        isRead: boolean;
        text?: string;
        updatedAt?: Date;
        _id?: string
    }[];
}

export const UnreadNotification = ({ notificationDB }: UnreadNotificationProps): Notification[] => {
    return notificationDB.filter((notificationDB: { isRead: boolean; }) => notificationDB.isRead === false);
};

