import { createContext, ReactNode, useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "../../redux/store";
import { UsersProps } from "../../Interfaces";
import { decodeToken } from "react-jwt";
import Cookies from "universal-cookie";
import { setUser } from "../../redux/userSlice";

interface OrderContextProviderProps {
    children: ReactNode;
    user?: UsersProps;
}

interface OrderContexttype {
    isMobile: boolean;
}

export const OrderContext = createContext<OrderContexttype | undefined>(undefined);

export const OrderContextProvider = ({ children, user }: OrderContextProviderProps) => {
    const userData = useSelector((state: RootState) => state.userData);
    const dispatch = useDispatch();
    const nav = useNavigate();
    const cookie = new Cookies();
    const getTokenCookie = cookie.get("bearer");

    let TokenReducer = userData?.user?.token;
    const decodedToken = decodeToken(TokenReducer!) as { exp: number, iat: number } | null;
    const [refreshTimeout, setRefreshTimeout] = useState<NodeJS.Timeout | null>(null);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 500);

    let RefreshToken = getTokenCookie?.refreshToken;

    // **دالة تحديث التوكن**
    // const refreshAccessToken = async () => {
    //     try {
    //         console.log("🔄 بدء تحديث التوكن...");
    //         const response = await axios.get(`${process.env.REACT_APP_URL_API}/API/Auth/RefreshToken`, {
    //             headers: {
    //                 Accept: "application/json",
    //                 Authorization: "Bearer " + RefreshToken,
    //             },
    //         });
    //         const newToken = response.data.token;
    //         const userDetails = response.data.data;

    //         cookie.set("bearer", { token: newToken, refreshToken: RefreshToken, userDetails }, {
    //             path: "/",
    //             expires: new Date(Date.now() + 2 * 30 * 24 * 60 * 60 * 1000)
    //         });

    //         dispatch(setUser({ token: newToken, refreshToken: RefreshToken, userDetals: userData.user.userDetals }));

    //         console.log("✅ تم تحديث التوكن بنجاح");

    //     } catch (error) {
    //         console.error("❌ فشل تحديث التوكن:", error);
    //     }
    // };

    // // **مراقبة انتهاء التوكن قبل 5 ثوانٍ من انتهائه**
    // useEffect(() => {
    //     if (decodedToken?.exp) {
    //         const currentTime = Math.floor(Date.now() / 1000); // الوقت الحالي بالثواني
    //         const timeUntilExpiration = decodedToken.exp - currentTime; // الوقت المتبقي لانتهاء التوكن
    //         const refreshTime = timeUntilExpiration - 5; // تحديث التوكن قبل 5 ثواني من انتهاءه
    //         console.log("userData.user.token : ", userData.user.token)
    //         console.log("userData.user.refreshToken : ", userData.user.refreshToken)

    //         console.log(`⏳ سيتم تجديد التوكن بعد: ${refreshTime} ثانية`);

    //         // إلغاء المؤقت السابق إذا كان موجودًا
    //         if (refreshTimeout) clearTimeout(refreshTimeout);

    //         if (refreshTime > 0) {
    //             const timeout = setTimeout(refreshAccessToken, refreshTime * 1000);
    //             setRefreshTimeout(timeout);
    //         } else {
    //             refreshAccessToken(); // إذا كان التوكن منتهي أصلاً، يتم تحديثه مباشرةً
    //         }
    //     }

    //     return () => {
    //         if (refreshTimeout) clearTimeout(refreshTimeout);
    //     };
    // }, [decodedToken?.exp, RefreshToken, TokenReducer]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <OrderContext.Provider value={{
            isMobile
        }}>
            {children}
        </OrderContext.Provider>
    );
};
