import { useEffect } from "react";
import { useTranslation } from "react-i18next";




export const PrivacyPolicy = () => {
    const { t, i18n } = useTranslation();
    useEffect(() => { window.scrollTo(0, 0) }, [])

    return (<>
        <div style={{ width: "100%", margin: 10, display: "flex", justifyContent: "center", direction: i18n.language === "ar" ? "ltr" : "ltr", }}>
            <div style={{ width: "100%", maxWidth: "1440px" }}>
                <div style={{ fontSize: 45, fontWeight: 900, width: "100%", textAlign: "center", margin: "40px 0" }}>
                    Privacy Policy
                </div>
                <div>
                    Effective Date: March 26, 2025
                </div>
                <div>
                    {/* App Name: TreatMe */}
                </div>
                <div>
                    Company: HLDSH
                </div>
                <div style={{ fontSize: 20, fontWeight: 700 }}>
                    1. Information We Collect
                </div>
                <div style={{ marginLeft: 25 }}>
                    We only collect the necessary personal information required to create and maintain your account and provide services. This includes:
                </div>
                <div style={{ marginLeft: 20 }}>
                    - Full name
                </div>
                <div style={{ marginLeft: 20 }}>
                    - Email address
                </div>
                <div style={{ marginLeft: 20 }}>
                    - Phone number
                </div>
                <div style={{ marginLeft: 20 }}>
                    - Country of residence
                </div>
                <div style={{ marginLeft: 25 }}>
                    We do NOT collect:
                </div>
                <div style={{ marginLeft: 20 }}>
                    - Device information (e.g., device model, operating system)
                </div>
                <div style={{ marginLeft: 20 }}>
                    - Usage or activity data within the app
                </div>
                <div style={{ fontSize: 20, fontWeight: 700 }}>
                    2. How We Use Your Information
                </div>
                <div style={{ marginLeft: 25 }}>
                    We use your personal data for the following purposes:
                </div>
                <div style={{ marginLeft: 20 }}>
                    - To register and manage your account
                </div>
                <div style={{ marginLeft: 20 }}>
                    - To contact you with service-related updates or support
                </div>
                <div style={{ marginLeft: 20 }}>
                    - To tailor services based on your country or preferences
                </div>
                <div style={{ marginLeft: 20 }}>
                    - To ensure account security and prevent misuse\
                </div>
                <div style={{ marginLeft: 20 }}>
                    We do not use your data for any unauthorized marketing or third-party purposes.
                </div>
                <div style={{ fontSize: 25, fontWeight: 700 }}>
                    3. User Choices & Rights
                </div>
                <div style={{ marginLeft: 25 }}>
                    As a user, you have full control over your personal information. You can:
                </div>
                <div style={{ marginLeft: 20 }}>
                    - Access the data we have stored about you
                </div>
                <div style={{ marginLeft: 20 }}>
                    - Correct or update any inaccurate information
                </div>
                <div style={{ marginLeft: 20 }}>
                    - Request account deletion and complete removal of your data
                </div>
                <div style={{ marginLeft: 20 }}>
                    - Request to permanently close your account
                </div>
                <div style={{ marginLeft: 20 }}>
                    - Opt out of non-essential communications
                </div>
                <div style={{ marginLeft: 25 }}>
                    All requests will be handled promptly and securely in accordance with GDPR.
                </div>
                <div style={{ fontSize: 20, fontWeight: 700 }}>
                    4. Legal Compliance
                </div>
                <div style={{ marginLeft: 25 }}>
                    TreatMe strictly complies with:
                </div>
                <div style={{ marginLeft: 20 }}>
                    - EU GDPR regulations
                </div>
                <div style={{ marginLeft: 20 }}>
                    - Google Play Developer Program Policies
                </div>
                <div style={{ marginLeft: 20 }}>
                    - We do not share any user data with third parties without explicit consent
                </div>
                <div style={{ marginLeft: 20 }}>
                    - We implement industry-standard security measures to protect your information
                </div>
                <div style={{ fontSize: 20, fontWeight: 700 }}>
                    5. Policy Updates
                </div>
                <div style={{ marginLeft: 20 }}>
                    We may update this Privacy Policy from time to time. If any significant changes are made, we will notify users through appropriate channels.
                </div>
                <div style={{ fontSize: 20, fontWeight: 700 }}>
                    6. Contact Us
                </div>
                <div style={{ marginLeft: 20 }}>
                    If you have any questions or concerns regarding this Privacy Policy or your data, please contact us at:
                </div>
                <div style={{ marginLeft: 20, fontWeight: 600 }}>
                    Email: support@hldshtech.com
                </div>
            </div>
        </div>
    </>)
}

export const RefundPolicy = () => {
    return (<>
        <div>
            RefundPolicy
        </div>
    </>)
}

export const TermsAndConditions = () => {
    return (<>
        <div>
            TermsAndConditions
        </div>
    </>)
}