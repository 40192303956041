import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import "./ProductDetailsAdmin.css"
import ProgressBar from 'react-bootstrap/ProgressBar';
import { ThemeColor } from '../../../theme';
import { ChatContext } from '../../../components/context/ChatContext';
import { Product } from '../../../Interfaces';

interface CompleteInformationProps {
    data: Product
}

const CompleteInformationAdmin: React.FC<CompleteInformationProps> = ({ data }) => {
    const { t } = useTranslation()
    const maxNumber = data?.maxNumber ?? 1;
    const context = useContext(ChatContext)
    const { baseCurrency, currency } = context!
    const now = ((Number(data?.number!) / maxNumber) * 100).toFixed(0);

    const finshNumber = (num: number) => {
        const Currency = currency === "ILS" ? "ILS" : currency === "USD" ? "USD" : "JOD"
        if (currency === "ILS") {
            const afterNum = Math.round(Number(num) * baseCurrency)
            const remainder = afterNum % 10;
            if (remainder < 5) {
                return (afterNum - remainder).toLocaleString('en-US') + " " + Currency
            } else {
                return (afterNum + (10 - remainder)).toLocaleString('en-US') + " " + Currency
            }
        }
        else {
            return Math.round(Number(num) * baseCurrency) + " " + Currency
        }
    }

    return (<>
        <div style={{ display: "flex", flexDirection: "column", gap: 20, padding: "0 10px 10px 10px" }}>
            {
                data?.favorite?.length! > 0 &&
                <div style={{ fontSize: 16, fontWeight: 500 }}>
                    {data?.favorite?.length}  اعجب بهذا المنتج
                </div>
            }
            <div style={{ fontSize: 17, fontWeight: 500 }}>
                {t("Market.HurryOnly", { number: data?.number })}
            </div>
            <div style={{ display: "flex", justifyContent: 'center', marginTop: "10px" }}>
                <ProgressBar variant="danger" style={{ width: "100%" }} now={Number(now)} label={`${now}%`} >
                    <div
                        style={{
                            width: `${now}%`,
                            backgroundColor: ThemeColor.text, // لون الرينج المخصص
                            height: "100%",
                            borderRadius: "5px",
                            textAlign: "center",
                            color: "#fff", // لون النص
                        }}
                    >
                        {`${now}%`}
                    </div>
                </ProgressBar>
            </div>
            {
                data?.priceOfferForTwoProducts &&
                <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
                    <div style={{ fontWeight: 600, fontSize: 20 }}>{t("Market.offer")}</div> :
                    <div style={{ color: "red", fontWeight: 500 }}>
                        {t("Market.Buy one and get the second at a price")} {finshNumber(data.priceOfferForTwoProducts!)}
                    </div>
                </div>
            }
            <div style={{ fontSize: 18, fontWeight: 500 }}>
                {t('Market.Sell from him')} :  {data?.sold}
            </div>


        </div>
    </>)
}

export default CompleteInformationAdmin;