import axios from "axios";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Button, Form, FormLabel, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Langu from "../../../components/languge/languge";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Product } from "../../../Interfaces";
import { ChatContext } from "../../../components/context/ChatContext";


interface ModalXLProps {
    show: boolean;
    setShowModalXL: React.Dispatch<React.SetStateAction<boolean>>;
    data: Product;
}

const ModelXLAdmin: React.FC<ModalXLProps> = ({ show, setShowModalXL, data }) => {
    const userData = useSelector((state: RootState) => state.userData)
    const { t } = useTranslation()
    const nav = useNavigate()
    const context = useContext(ChatContext)
    const { baseCurrency, currency } = context!
    const [newDataProduct, setNewDataProduct] = useState<Product>()
    const [activePhoto, setActivePhoto] = useState<string>(data?.file?.[0]!)
    const [statusSwitch, setStatusSwitch] = useState<boolean>(newDataProduct ? newDataProduct?.disable! : false)
    const [forYouMyLady, setForYouMyLady] = useState<boolean>(newDataProduct ? newDataProduct?.ForYouMyLady! : false)

    useEffect(() => {
        setActivePhoto(data?.file?.[0]!)
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProduct/${data?._id}`)
            .then((doc) => setNewDataProduct(doc.data))
            .catch((err) => console.log(err))
    }, [show, data])


    useEffect(() => {
        setStatusSwitch(newDataProduct ? newDataProduct?.disable! : false)
        setForYouMyLady(newDataProduct ? newDataProduct?.ForYouMyLady! : false)
    }, [newDataProduct])

    const DeleteBtn = async () => {
        await axios.delete(`${process.env.REACT_APP_URL_API}/API/Products/DeleteProduct/${data?._id}`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then(() => setShowModalXL(false))
            .catch((err) => console.log(err))
    }

    const BtnSwitch = async (e: ChangeEvent<HTMLInputElement>) => {
        const newStatusSwitch: boolean = e.target.checked;
        setStatusSwitch(newStatusSwitch)
        await axios.patch(`${process.env.REACT_APP_URL_API}/API/Products/PutProductDisable/${data._id}`, { disable: newStatusSwitch }, {
            headers: {
                Authorization: "Bearer " + userData.user.token,
            }
        })
            .catch((err) => console.log(err))
    }

    const BtnSwitchsetForYouMyLady = async (e: ChangeEvent<HTMLInputElement>) => {
        const newStatusSwitch: boolean = e.target.checked;
        setForYouMyLady(newStatusSwitch)
        await axios.patch(`${process.env.REACT_APP_URL_API}/API/Products/PutProductForYouMyLady/${data._id}`, { ForYouMyLady: newStatusSwitch }, {
            headers: {
                Authorization: "Bearer " + userData.user.token,
            }
        })
            .catch((err) => console.log(err))
    }

    const finshNumber = (num: number) => {
        const Currency = currency === "ILS" ? "ILS" : currency === "USD" ? "USD" : "JOD"
        if (currency === "ILS") {
            const afterNum = (Number(num) * baseCurrency)
            return (afterNum.toFixed(2)) + " " + Currency
        }
        else {
            return (Number(num) * baseCurrency).toFixed(2) + " " + Currency
        }
    }

    return (<>
        {
            <Modal size="xl" show={show} onHide={() => setShowModalXL(false)}>
                <Modal.Header className="CloseBtn" closeButton>
                    <Modal.Title>
                        <Langu {...data?.product!} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ display: "flex" }}>
                    <div style={{ width: "60%", display: "flex" }}>
                        <div style={{ width: "25%", border: "1px solid gray", margin: "5px", cursor: "pointer", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <img onClick={() => { setActivePhoto(data?.file?.[0]!) }} className="ImgModal" src={`${process.env.REACT_APP_URL_API}/API/files/Products/${data?.file?.[0]}`} />
                            {data?.file?.[1] && <img onClick={() => { setActivePhoto(data?.file?.[1]!) }} className="ImgModal" src={`${process.env.REACT_APP_URL_API}/API/files/Products/${data?.file?.[1]}`} />}
                            {data?.file?.[2] && <img onClick={() => { setActivePhoto(data?.file?.[2]!) }} className="ImgModal" src={`${process.env.REACT_APP_URL_API}/API/files/Products/${data?.file?.[2]}`} />}
                        </div>
                        {activePhoto && <img style={{ maxWidth: "75%", minWidth: "75%", maxHeight: "50vh", minHeight: "50vh" }} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${activePhoto}`} />}
                    </div>
                    <div style={{ width: "40%", padding: "2%" }}>
                        <div>
                            {t("Market.brand")} : {' '}
                            <Langu {...data?.brand!} />
                        </div>
                        <div>
                            {t("Market.product")} : {' '}
                            <Langu {...data?.product!} />
                            {data.colors?.length! > 0 &&
                                <div style={{ display: "flex" }}>
                                    {data.colors?.map((color: string, index) => (
                                        <Button
                                            key={index}
                                            style={{ backgroundColor: color, minWidth: 25, minHeight: 25, border: "1px solid #dddddd", margin: "10px 5px" }}>
                                        </Button>
                                    ))}
                                </div>
                            }
                        </div>
                        <div>
                            {t("Market.information")} : {' '}
                            <Langu {...data?.information!} />
                        </div>
                        <div style={{ width: "100%", fontSize: "18px" }}>
                            {t("Market.price")} : {' '}
                            {
                                data.newPrice ?
                                    <div style={{ display: "flex", width: "100%", justifyContent: "center", gap: '20px' }}>
                                        <div style={{ textDecoration: "line-through", color: "gray", fontSize: 20, fontWeight: 600 }}>
                                            {finshNumber(data.price!)}
                                        </div>
                                        <div style={{ color: "red", fontSize: 20, fontWeight: 600 }}>
                                            {finshNumber(data.newPrice!)}
                                        </div>
                                    </div>
                                    :
                                    <div style={{ textAlign: "center", color: "gray", fontSize: 20, fontWeight: 600 }}>
                                        {finshNumber(data.price!)}
                                    </div>
                            }
                        </div>
                        <div>
                            {t("Market.number")} : {' '}
                            {data?.number}
                        </div>
                        <div>
                            {t("Market.Remind me when available")} : {' '}
                            {data?.remind?.length ?? 0}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ alignItems: "center", justifyContent: "center" }}>
                    <Button bsPrefix="BtnBNB" onClick={() => nav(`/admin/market/updateProduct/${data?._id}`)}>{t("Market.Update Product")}</Button>
                    <Button variant="danger" onClick={DeleteBtn}>{t("Market.Delete")}</Button>
                    <FormLabel htmlFor="disable">disable : </FormLabel>
                    <Form.Check id="disable" type="switch" checked={statusSwitch} onChange={(e) => BtnSwitch(e)}></Form.Check>
                    <FormLabel htmlFor="forYouMyLady">for You My Lady : </FormLabel>
                    <Form.Check id="forYouMyLady" type="switch" checked={forYouMyLady} onChange={(e) => BtnSwitchsetForYouMyLady(e)}></Form.Check>
                </Modal.Footer>
            </Modal>
        }

    </>)
}

export default ModelXLAdmin;