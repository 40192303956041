import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OthersProps } from "../../../Interfaces";
import { Button, Form } from "react-bootstrap";
import { LoadingBtn } from "../../../components/laoding/laoding";
import { OrderContext } from "../../../components/context/OrderContext";

const InvitationBonus = () => {
    const { t } = useTranslation()
    const orderContext = useContext(OrderContext);
    const { isMobile } = orderContext!
    const [dataOthers, setDataOthers] = useState<OthersProps>()
    const [invitationBonus, setInvitationBonus] = useState<number>(dataOthers?.invitationBonus!)
    const [statusBtnCreate, setStatusBtnCreate] = useState<any>(t("Market.Add bonus"))
    const [statusBtnPut, setStatusBtnPut] = useState<any>(t("Market.Bonus adjustment"))
    const [disabledBtnCreate, setDisabledBtnCreate] = useState<boolean>(false)
    const [disabledBtnPut, setDisabledBtnPut] = useState<boolean>(false)
    const [reload, setReload] = useState<boolean>(false)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Others/GetOthers`)
            .then((doc) => setDataOthers(doc.data?.[0]))
            .catch((err) => console.log(err))
    }, [reload])

    useEffect(() => {
        setInvitationBonus(dataOthers?.invitationBonus!)
    }, [dataOthers])

    const BtnPostInvitationBonus = async () => {
        setStatusBtnCreate(<LoadingBtn />)
        setDisabledBtnCreate(true)
        await axios.post(`${process.env.REACT_APP_URL_API}/API/Others/PostOthers`, { invitationBonus: invitationBonus.toString() })
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtnCreate(t("Market.Add bonus"))
                setDisabledBtnCreate(false)
                setReload(!reload)
            })
    }

    const BtnPutInvitationBonus = async () => {
        setStatusBtnPut(<LoadingBtn />)
        setDisabledBtnPut(true)
        await axios.put(`${process.env.REACT_APP_URL_API}/API/Others/PutOthersInvitationBonus/${dataOthers?._id}`, { invitationBonus: invitationBonus.toString() })
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtnPut(t("Market.Bonus adjustment"))
                setDisabledBtnPut(false)
                setReload(!reload)
            })
    }

    return (<>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "1440px", width: "100%", boxShadow: "rgb(214, 214, 214) 5px 6px 10px", padding: "30px", borderRadius: "10px", border: "solid 1px #d6d6d6", margin: isMobile ? 10 : "20px 10% 0 10%" }}>
                <h1 style={{ width: "100%", borderBottom: "1px solid rgb(214, 214, 214)", textAlign: "center", paddingBottom: "20px" }}>{t("Market.InvitationBonus")}</h1>
                <div>
                    <Form.Label>{t("Market.InvitationBonus")} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <Form.Control type="number" placeholder="" onChange={e => { setInvitationBonus(parseFloat(e.target.value)) }} defaultValue={invitationBonus} /> %
                        {/* <Form.Control as="textarea" placeholder="עִברִית" onChange={e => { setInformationHE(e.target.value) }} /> */}
                    </Form.Group>
                </div>
                {
                    dataOthers ?
                        <div>
                            <Button onClick={BtnPutInvitationBonus} disabled={disabledBtnPut} bsPrefix="BtnBNB">{statusBtnPut}</Button>
                        </div>
                        :
                        <div>
                            <Button onClick={BtnPostInvitationBonus} disabled={disabledBtnCreate} bsPrefix="BtnBNB">{statusBtnCreate}</Button>
                        </div>
                }
            </div>
        </div >
    </>)
}

export default InvitationBonus;