import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { UsersProps } from "../../Interfaces";
import { OrderContext } from "../../components/context/OrderContext";
import { LoadingBtn } from "../../components/laoding/laoding";


const Dashboard: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData)
    const orderContext = useContext(OrderContext);
    const { isMobile } = orderContext!
    const { t } = useTranslation()
    const nav = useNavigate()
    const [dataUsers, setDataUsers] = useState<UsersProps[]>()
    const [limit, setLimit] = useState<number>(30)
    const [statusBtnViewMore, setStatusBtnViewMore] = useState<any>(t("Market.View more"))

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUsers/${limit}`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => setDataUsers(doc.data))
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtnViewMore(t("Market.View more"))
            })
    }, [userData.user.token, limit])

    const handleLimit = () => {
        setStatusBtnViewMore(<LoadingBtn />)
        setLimit(limit + 20)
    }
    return (<>
        <div style={{ display: "flex", justifyContent: "center" }}>
            {
                isMobile ?
                    <div style={{ backgroundColor: "#dddddd", width: "100%" }}>
                        {dataUsers?.map((user, index) => (
                            <div key={index} style={{ backgroundColor: "white", margin: "5px 10px", borderRadius: 5, padding: 5, display: "flex", flexDirection: "column", gap: 10 }}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        {t("Home.name")} : {user.userName}
                                    </div>
                                    <div>
                                        {t("Home.phone")} : {user.phone}
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        {t("Home.email")} :{user.email}
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-around" }}>
                                    <div>
                                        {t("Home.role")} : {user?.role?.toString()}
                                    </div>
                                    <div>

                                        {t("Home.active")} : {user?.active?.toString()}
                                    </div>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <Button bsPrefix="BtnBNB" onClick={() => nav(`/admin/dashboard/userId/${user._id}`)}>{t("Home.User details")}</Button>
                                </div>
                            </div>
                        ))}
                        <div style={{ margin: 10, backgroundColor: "white", padding: 10, textAlign: "center" }}>
                            <Button bsPrefix="BtnBNB" onClick={handleLimit}>{statusBtnViewMore}</Button>
                        </div>
                    </div>
                    :
                    <div style={{ maxWidth: "1440px", width: "100%", padding: "0 40px" }}>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{t("Home.name")}</th>
                                    <th>{t("Home.phone")}</th>
                                    <th>{t("Home.email")}</th>
                                    <th>{t("Home.role")}</th>
                                    <th>{t("Home.active")}</th>
                                    <th style={{ width: "15%" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataUsers?.map((user, index) =>
                                        <tr key={user._id}>
                                            <td>{index + 1}</td>
                                            <td>{user.userName}</td>
                                            <td>{user.phone}</td>
                                            <td>{user.email}</td>
                                            <td>{user?.role?.toString()}</td>
                                            <td>{user?.active?.toString()}</td>
                                            <td style={{ width: "15%" }}><Button bsPrefix="BtnBNB" onClick={() => nav(`/admin/dashboard/userId/${user._id}`)}>{t("Home.User details")}</Button></td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                        <div style={{ padding: 20, textAlign: 'center' }}>
                            <Button bsPrefix="BtnBNB" onClick={handleLimit}>{statusBtnViewMore}</Button>
                        </div>
                    </div>
            }
        </div>
    </>)
}
export default Dashboard;