import { Button, Form, FormLabel } from "react-bootstrap";
import Langu from "../../../components/languge/languge";
import axios from "axios";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import { ChatContext } from "../../../components/context/ChatContext";
import { Product, UsersProps } from "../../../Interfaces";
import { useNavigate } from "react-router";
import ModeleDeleteProduct from "../../market/Modales/ModeleDeleteProduct";

interface MessageProductProps {
    data: Product
}

const MessageProductAdmin: React.FC<MessageProductProps> = ({ data }) => {
    const userData = useSelector((state: RootState) => state.userData);
    const { t } = useTranslation()
    const nav = useNavigate()
    const chatContext = useContext(ChatContext);
    const { baseCurrency, currency } = chatContext!;
    const [dataUser, setDataUser] = useState<UsersProps>();
    const [colorActive, setColorActive] = useState<string>(data?.colors?.[0]!);
    const [statusSwitch, setStatusSwitch] = useState<boolean>(data ? data?.disable! : false)
    const [forYouMyLady, setForYouMyLady] = useState<boolean>(data ? data?.ForYouMyLady! : false)
    const [showModale, setShowModale] = useState<boolean>(false)


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${userData.user.userDetals?._id}`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => { setDataUser(doc.data) })
            .catch((err) => console.log(err))
    }, [])

    useEffect(() => {
        setColorActive(data?.colors?.[0]!)
    }, [data])

    useEffect(() => {
        setTimeout(() => {
            setColorActive(data?.colors?.[0]!)
        }, 500)
    }, [dataUser])

    const BtnSwitch = async (e: ChangeEvent<HTMLInputElement>) => {
        const newStatusSwitch: boolean = e.target.checked;
        setStatusSwitch(newStatusSwitch)
        await axios.patch(`${process.env.REACT_APP_URL_API}/API/Products/PutProductDisable/${data._id}`, { disable: newStatusSwitch }, {
            headers: {
                Authorization: "Bearer " + userData.user.token,
            }
        })
            .then(() => { })
            .catch((err) => console.log(err))
    }

    const BtnSwitchsetForYouMyLady = async (e: ChangeEvent<HTMLInputElement>) => {
        const newStatusSwitch: boolean = e.target.checked;
        setForYouMyLady(newStatusSwitch)
        await axios.patch(`${process.env.REACT_APP_URL_API}/API/Products/PutProductForYouMyLady/${data._id}`, { ForYouMyLady: newStatusSwitch }, {
            headers: {
                Authorization: "Bearer " + userData.user.token,
            }
        })
            .then(() => { })
            .catch((err) => console.log(err))
    }

    const finshNumber = (num: number) => {
        const Currency = currency === "ILS" ? "ILS" : currency === "USD" ? "USD" : "JOD"
        if (currency === "ILS") {
            const afterNum = Math.round(Number(num) * baseCurrency)
            const remainder = afterNum % 10;
            if (remainder < 5) {
                return (afterNum - remainder).toLocaleString('en-US') + " " + Currency
            } else {
                return (afterNum + (10 - remainder)).toLocaleString('en-US') + " " + Currency
            }
        }
        else {
            return Math.round(Number(num) * baseCurrency) + " " + Currency
        }
    }

    return (<>
        <div style={{ minHeight: 100, position: "fixed", width: "100%", bottom: 0, borderTop: "1px solid #dddddd", backgroundColor: "white", display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                <div style={{ maxWidth: 1660, display: "flex", justifyContent: "space-around", width: "100%", padding: "0 40px", alignItems: "center" }}>
                    <div style={{ display: "flex", gap: 20 }}>
                        <img style={{ width: 80, height: 80, borderRadius: "50%", border: "1px solid #dddddd" }} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${data?.file?.[0]}`} />
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <div style={{ fontWeight: 600, fontSize: 21 }}>
                                <Langu {...data?.product!} />
                            </div>
                            <div>
                                {
                                    data?.newPrice ?
                                        <div style={{ display: "flex", width: "100%", justifyContent: "start", gap: '20px' }}>
                                            <div>
                                                {t('Market.price')} :
                                            </div>
                                            <div style={{ textDecoration: "line-through", color: "black", fontWeight: 700 }}>
                                                {finshNumber(data?.price!)}
                                            </div>
                                            <div style={{ color: "red", fontWeight: 700 }}>
                                                {t('Market.newPrice')} : {finshNumber(data?.newPrice!)}
                                            </div>
                                        </div>
                                        :
                                        <div style={{ textAlign: "start", color: "black", fontWeight: 700 }}>
                                            {t('Market.price')} : {finshNumber(data?.price!)}
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ display: "flex" }}>
                            {data?.colors?.length! > 0 && data?.colors?.map((color: string, index) => (
                                <Button
                                    key={index}
                                    onClick={() => setColorActive(color)}
                                    style={{ backgroundColor: color, minWidth: 25, minHeight: 25, border: "1px solid #dddddd", margin: "10px 5px", boxShadow: colorActive == color ? `0px 0px 10px 5px ${colorActive}` : '' }}>
                                </Button>
                            ))}
                        </div>
                        <div style={{ padding: 10, display: "flex", justifyContent: "space-around", gap: 30, alignItems: "center" }}>
                            <Button bsPrefix="BtnBNB" onClick={() => nav(`/admin/market/updateProduct/${data?._id}`)}>{t("Market.Update Product")}</Button>
                            <Button variant="danger" onClick={() => setShowModale(true)}>{t("Market.Delete")}</Button>
                            <FormLabel htmlFor="disable">disable : </FormLabel>
                            <Form.Check id="disable" type="switch" checked={statusSwitch} onChange={(e) => BtnSwitch(e)}></Form.Check>
                            <FormLabel htmlFor="forYouMyLady">for You My Lady : </FormLabel>
                            <Form.Check id="forYouMyLady" type="switch" checked={forYouMyLady} onChange={(e) => BtnSwitchsetForYouMyLady(e)}></Form.Check>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModeleDeleteProduct id={data._id!} show={showModale} ModeleDeleteProduct={setShowModale} />
    </>)
}

export default MessageProductAdmin;