import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Product } from "../../../Interfaces";
import { OrderContext } from "../../../components/context/OrderContext";


const VideoAndPhoto = () => {
    const params = useParams()
    const contextOrder = useContext(OrderContext)
    const { isMobile } = contextOrder!
    const [dataProduct, setDataProduct] = useState<Product>()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProduct/${params.id}`)
            .then((doc) => { setDataProduct(doc.data) })
            .catch((err) => console.log(err))
    }, [])

    return (<>
        <div>
            {
                dataProduct?.introVideo &&
                <div>
                    <video width={isMobile ? "300" : "800"} height={isMobile ? "300" : "800"} controls>
                        <source src={`${process.env.REACT_APP_URL_API}/API/files/Videos/${dataProduct.introVideo}`} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video >
                </div>
            }
            <div style={{ display: 'flex', flexDirection: "column", gap: 20 }}>
                {
                    dataProduct?.fileIllustrativePictures?.length! > 0 &&
                    dataProduct?.fileIllustrativePictures?.map((img, index) => (
                        <div key={index}>
                            <img style={{ width: isMobile ? 300 : 800, height: isMobile ? 300 : 800 }} src={`${process.env.REACT_APP_URL_API}/API/files/IllustrativePictures/${img ?? ""}`} />
                        </div>
                    ))
                }
            </div>
        </div>
    </>)
}

export default VideoAndPhoto;