import axios from "axios";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { LoadingBtn } from "../../../components/laoding/laoding";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import Langu from "../../../components/languge/languge";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { ChatContext } from "../../../components/context/ChatContext";
import { Brand, Category, Product } from "../../../Interfaces";


const UpdataProduct: React.FC = () => {
    let params = useParams()
    const { t, i18n } = useTranslation()
    let userData = useSelector((state: RootState) => state.userData)
    let token = userData.user.token
    const context = useContext(ChatContext)
    const { baseCurrency } = context!
    const [statusBtnUpdate, setStatusBtnUpdate] = useState<any>(t("Market.Update Product"))
    const [disabledBtnUpdate, setDisabledBtnUpdate] = useState<boolean>(false)
    const [dataCategory, setDataCategory] = useState<Category[]>()
    const [categoryDetails, setCategoryDetails] = useState<Category>()
    const [dataBrands, setDataBrands] = useState<Brand[]>()
    const [dataProduct, setDataProduct] = useState<Product>()
    const [category, setCategory] = useState<string>(JSON.stringify({ en: dataProduct?.category?.en, ar: dataProduct?.category?.ar }))
    const [brand, setBrand] = useState<string>(JSON.stringify({ en: dataProduct?.brand?.en, ar: dataProduct?.brand?.ar }))
    const [productEN, setProductEN] = useState<string>('')
    const [productAR, setProductAR] = useState<string>('')
    const [informationEN, setInformationEN] = useState<string>('')
    const [informationAR, setInformationAR] = useState<string>('')
    const [color, setColor] = useState<string>()
    const [colorsPost, setColorsPost] = useState<(string | undefined)[]>(dataProduct ? dataProduct?.colors! : []);
    const [price, setPrice] = useState<number | undefined>()
    const [newPrice, setNewPrice] = useState<number | undefined>()
    const [number, setNumber] = useState<number | undefined>()
    const [photo, setPhoto] = useState<File[]>([])
    const [photoIllustrativePictures, setPhotoIllustrativePictures] = useState<File[]>([])
    const [video, setVideo] = useState<File>();
    const [errInput, setErrInput] = useState<string>('')
    const [offerStatus, setOfferStatus] = useState<boolean>(false)
    const [hoveredItemId, setHoveredItemId] = useState<string | null | undefined>(null)
    // const [targetStatus, setTargetStatus] = useState<boolean>(false)
    // const [target, setTarget] = useState<string>("Men's")
    const [checkedCategorys, setCheckedCategorys] = useState<Record<string, string>>({})
    const [priceOfferForTwoProducts, setPriceOfferForTwoProducts] = useState<number>()
    const [statusOfferForTwoProducts, setStatusOfferForTwoProducts] = useState<boolean>(false)
    const [forYouMyLady, setForYouMyLady] = useState<boolean>(false)


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategorys`)
            .then((doc) => {
                setDataCategory(doc.data)
                if (doc.data && doc.data.length > 0) {
                    setCategory(JSON.stringify({
                        en: doc.data[0].name.en,
                        ar: doc.data[0].name.ar
                    }))
                }
            })
            .catch((err) => console.log(err))

        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProduct/${params.id}`)
            .then((doc) => setDataProduct(doc.data))
            .catch((err) => console.log(err))
        axios.get(`${process.env.REACT_APP_URL_API}/API/Brands/GetBrands`)
            .then((doc) => { setDataBrands(doc.data) })
            .catch((err) => console.log(err))
    }, [params.id])

    const discount: number | undefined = price && newPrice ? ((price - newPrice) / price) * 100 : undefined;

    useEffect(() => {
        category && axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategory/${JSON.parse(category).en}`)
            .then((doc) => setCategoryDetails(doc.data))
            .catch((err) => console.log(err))
    }, [category])

    useEffect(() => {
        setTimeout(() => {
            setColorsPost(dataProduct?.colors!)
        }, 300)
    }, [dataProduct])

    useEffect(() => {
        if (dataProduct) {

            const timer = setTimeout(() => {
                setCategory(JSON.stringify({
                    en: dataProduct?.category?.en || dataCategory?.[0].name?.en,
                    ar: dataProduct?.category?.ar || dataCategory?.[0].name?.ar,
                    // he: dataProduct?.category?.he || dataCategory?.[0].name?.he,
                }));
                setBrand(JSON.stringify({
                    en: dataProduct?.brand?.en || dataBrands?.[0].name?.en,
                    ar: dataProduct?.brand?.ar || dataBrands?.[0].name?.ar,
                    // he: dataProduct?.brand?.he || dataBrands?.[0].name?.he
                }));
                // setTarget(dataProduct?.target || "Men's")
            }, 200);

            setProductEN(dataProduct?.product?.en || "");
            setProductAR(dataProduct?.product?.ar || "");
            // setProductHE(dataProduct?.product?.he || "");

            setInformationEN(dataProduct?.information?.en || "");
            setInformationAR(dataProduct?.information?.ar || "");
            // setInformationHE(dataProduct?.information?.he || "");

            setPrice(dataProduct?.price! * baseCurrency);
            setNewPrice(dataProduct?.newPrice! * baseCurrency);
            setNumber(dataProduct?.number || 0);
            setOfferStatus(dataProduct.offers || false)
            setCheckedCategorys(dataProduct?.categorys! || "")
            setStatusOfferForTwoProducts(dataProduct.offerForTwoProducts || false)
            setPriceOfferForTwoProducts(dataProduct.priceOfferForTwoProducts! * baseCurrency)
            setForYouMyLady(dataProduct.ForYouMyLady || false)
            return () => clearTimeout(timer);
        }
    }, [dataProduct, dataBrands, dataCategory, baseCurrency]);

    useEffect(() => {
        setErrInput('')
    }, [productEN, productAR, informationEN, informationAR, price, number])

    const handelPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setPhoto(Array.from(files))
        }
    }

    const handelPhotoIllustrativePictures = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setPhotoIllustrativePictures(Array.from(files))
        }
    }

    const handelVideo = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const video = e.target.files[0]; // فيديو واحد فقط
            setVideo(video)
            // رفع الفيديو أو تخزينه مؤقتًا
        }
    };

    const changeImageIllustrativePictures = (fromIndex: number, toIndex: number) => {
        const newPhotos = [...photoIllustrativePictures];
        const [movedPhoto] = newPhotos.splice(fromIndex, 1);
        newPhotos.splice(toIndex, 0, movedPhoto);
        setPhotoIllustrativePictures(newPhotos);
    }

    const BtnUpdate = async () => {
        setStatusBtnUpdate(<LoadingBtn />)
        setDisabledBtnUpdate(true)
        if (!productEN || !productAR || !informationEN || !informationAR || !price || !number) {
            setDisabledBtnUpdate(false)
            setStatusBtnUpdate("Market.Update Product")
            setErrInput(t('Market.Please enter data'))
            return;
        }

        const Category = JSON.parse(category)
        const Brand = JSON.parse(brand)

        const selectedFiles = []

        for (let i = 0; i < photo.length; i++) {
            selectedFiles.push(photo[i]);
        }
        const updatedFormData = new FormData();
        updatedFormData.append("categoryEN", Category.en)
        updatedFormData.append("categoryAR", Category.ar)
        // updatedFormData.append("categoryHE", Category.he)
        updatedFormData.append("brandEN", Brand.en)
        updatedFormData.append("brandAR", Brand.ar)
        // updatedFormData.append("brandHE", Brand.he)
        updatedFormData.append("productEN", productEN)
        updatedFormData.append("productAR", productAR)
        // updatedFormData.append("productHE", productHE)
        updatedFormData.append("informationEN", informationEN)
        updatedFormData.append("informationAR", informationAR)
        // updatedFormData.append("informationHE", informationHE)
        updatedFormData.append("colors", JSON.stringify(colorsPost))
        if (forYouMyLady) {
            updatedFormData.append("forYouMyLady", forYouMyLady.toString())
        }
        if (price !== undefined) {
            if (offerStatus && newPrice !== undefined) {
                updatedFormData.append("price", (price / baseCurrency).toString());
                updatedFormData.append("newPrice", (newPrice / baseCurrency).toString());
                updatedFormData.append("offers", offerStatus.toString());
            }
            if (discount !== undefined) {
                updatedFormData.append("discount", discount.toFixed(0).toString());
            }
            else {
                updatedFormData.append("price", (price / baseCurrency).toString());
            }
        }
        if (statusOfferForTwoProducts && priceOfferForTwoProducts) {
            updatedFormData.append("offerForTwoProducts", statusOfferForTwoProducts.toString());
            updatedFormData.append("priceOfferForTwoProducts", (priceOfferForTwoProducts / baseCurrency).toString());
        }
        else {
            updatedFormData.append("offerForTwoProducts", statusOfferForTwoProducts.toString());
        }
        // if (targetStatus) {
        //     updatedFormData.append("target", target)
        // }
        if (checkedCategorys) {
            updatedFormData.append("categorys", JSON.stringify(checkedCategorys))
        }
        if (number !== undefined) {
            updatedFormData.append("number", number.toString());
        }

        for (let i = 0; i < selectedFiles.length; i++) {
            updatedFormData.append("file", selectedFiles[i]);
        }
        if (photoIllustrativePictures.length > 0) {
            for (let i = 0; i < photoIllustrativePictures.length; i++) {
                updatedFormData.append("fileIllustrativePictures", photoIllustrativePictures[i]);
            }
        }
        if (video) {
            updatedFormData.append("introVideo", video);
        }
        await axios.put(`${process.env.REACT_APP_URL_API}/API/Products/PutProduct/${params.id}`, updatedFormData, {
            headers: {
                Authorization: "Bearar " + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .catch((err) => console.log(err))
        setStatusBtnUpdate(t("Market.Update Product"))
        setDisabledBtnUpdate(false)
    }

    const addColors = () => {
        if (color) {
            setColorsPost(prevColors => [...prevColors, (color || "#000000")]);
        }
    };

    const BtnDeleteColor = () => {
        setColorsPost(prevColors => prevColors.filter(c => c !== hoveredItemId));
    }

    // const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setTarget(e.target.value)
    // }

    const changeImageOrder = (fromIndex: number, toIndex: number) => {
        const newPhotos = [...photo];
        const [movedPhoto] = newPhotos.splice(fromIndex, 1);
        newPhotos.splice(toIndex, 0, movedPhoto);
        setPhoto(newPhotos);
    }

    const handelCheckedCategorys = (categoryKey: string, value: string) => {
        setCheckedCategorys((prev) => ({
            ...prev,
            [categoryKey]: value, // تحديث الاختيار للتصنيف المعين
        }));
    }

    const BtnSwitchsetForYouMyLady = async (e: ChangeEvent<HTMLInputElement>) => {
        const newStatusSwitch: boolean = e.target.checked;
        setForYouMyLady(newStatusSwitch)
    }

    return (<>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "1440px", width: "100%", boxShadow: "rgb(214, 214, 214) 5px 6px 10px", padding: "30px", borderRadius: "10px", border: "solid 1px #d6d6d6", margin: "20px 10% 30px 10%" }}>
                <h1 style={{ width: "100%", borderBottom: "1px solid rgb(214, 214, 214)", textAlign: "center", paddingBottom: "20px" }}>{t("Market.Update Product")}</h1>
                <div style={{ width: "100%", maxWidth: "1000px", display: "flex", gap: "10px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Category" style={{ width: "49%" }}>
                        <Form.Label>{t("Market.Category name")} :</Form.Label>
                        <Form.Select onChange={e => { setCategory(e.target.value) }} value={category} defaultValue={i18n.language === "ar" ? dataProduct?.category?.ar : dataProduct?.category?.en}>
                            {
                                dataCategory?.map((category, index) => (
                                    <option key={index} value={JSON.stringify({ en: category.name.en, ar: category.name.ar })}><Langu {...category.name} /></option>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                    <Form.Group style={{ width: "49%" }}>
                        <Form.Label>{t("Market.Brand name")} :</Form.Label>
                        <Form.Select onChange={e => { setBrand(e.target.value) }} value={brand}>
                            {
                                dataBrands?.map((brands, index) => (
                                    <option key={index} value={JSON.stringify({ en: brands.name.en, ar: brands.name.ar })}><Langu {...brands.name} /></option>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    {categoryDetails?.Categorys?.map((item: any, index: number) => (
                        <div key={index}>
                            <div> <Langu {...item.name} /> : </div>
                            <Form.Check className="mb-3" style={{ display: "flex", gap: 20 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                                    <Form.Label htmlFor={`null--${index}`}>بلا</Form.Label>
                                    <Form.Check
                                        type="radio"
                                        id={`null--${index}`}
                                        value={"null"}
                                        name={`Categorys-${index}`}
                                        checked={checkedCategorys[item.name.en] === "null"}
                                        onChange={() => handelCheckedCategorys(item.name.en, "null")}
                                    />
                                </div>
                                {item?.Categories?.map((category: any, subIndex: number) => (
                                    <div key={subIndex} style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                                        <Form.Label htmlFor={`${category}-${index}-${subIndex}`}> <Langu {...category} /></Form.Label>
                                        <Form.Check
                                            type="radio"
                                            id={`${category}-${index}-${subIndex}`}
                                            name={`Categorys-${index}`}
                                            value={category.en}
                                            checked={checkedCategorys[item.name.en] === category.en}
                                            onChange={() => handelCheckedCategorys(item.name.en, category.en)}
                                        />
                                    </div>
                                ))}
                            </Form.Check>
                        </div>
                    ))}
                </div>
                <div style={{ width: "100%", maxWidth: "1000px" }}>
                    <Form.Label>{t("Market.Product name")} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.Product" style={{ display: "flex", gap: "10px" }}>
                        <Form.Control type="text" placeholder="English" autoFocus onChange={e => { setProductEN(e.target.value) }} defaultValue={productEN} />
                        <Form.Control type="text" placeholder="العربية" onChange={e => { setProductAR(e.target.value) }} defaultValue={productAR} />
                        {/* <Form.Control type="text" placeholder="עִברִית" onChange={e => { setProductHE(e.target.value) }} defaultValue={productHE} /> */}
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column" }}>
                    <Form.Label>{t("Market.information")} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "grid", gap: "10px" }}>
                        <Form.Control as="textarea" placeholder="English" onChange={e => { setInformationEN(e.target.value) }} defaultValue={informationEN} />
                        <Form.Control as="textarea" placeholder="العربية" onChange={e => { setInformationAR(e.target.value) }} defaultValue={informationAR} />
                        {/* <Form.Control as="textarea" placeholder="עִברִית" onChange={e => { setInformationHE(e.target.value) }} defaultValue={informationHE} /> */}
                    </Form.Group>
                </div>
                {/* <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Check type="switch" label={t("Market.target")} style={{ margin: "20px 0" }} onChange={((e) => setTargetStatus(e.target.checked))} />
                    {targetStatus &&
                        <Form.Group style={{ marginBottom: 10 }}>
                            <label className="radio-label" htmlFor="Men's" style={{ display: "flex", gap: "5px", color: "black" }}>
                                <input
                                    type="radio"
                                    id="Men's"
                                    name="target"
                                    value="Men's"
                                    checked={target === "Men's"}
                                    onChange={handleChangeRadio}
                                />
                                {t("Market.Men's")}
                                <span className="radio-custom"></span>
                            </label> <label className="radio-label" htmlFor="Women's" style={{ display: "flex", gap: "5px", color: "black" }}>
                                <input
                                    type="radio"
                                    id="Women's"
                                    name="target"
                                    value="Women's"
                                    checked={target === "Women's"}
                                    onChange={handleChangeRadio}
                                />
                                {t("Market.Women's")}
                                <span className="radio-custom"></span>
                            </label>
                        </Form.Group>
                    }
                </div> */}
                <div style={{ width: "100%", maxWidth: "1000px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.colors" style={{ display: "flex", gap: "10px" }}>
                        <Form.Label>{t("colors")} :</Form.Label>
                        <Form.Control type="color" placeholder="" autoFocus onChange={e => { setColor(e.target.value) }} />
                        <Button bsPrefix="BtnBNB" onClick={addColors}>{t("add")}</Button>
                        {colorsPost?.map((co, index) => (
                            <div
                                key={index}
                                onMouseEnter={() => setHoveredItemId(co)}
                                onMouseLeave={() => setHoveredItemId(null)}
                                style={{ backgroundColor: co, minWidth: 28, minHeight: 28, border: "1px solid #dddddd", margin: "10px 5px", cursor: "pointer" }}
                                onClick={BtnDeleteColor}
                            >
                            </div>
                        ))}
                    </Form.Group>
                </div>
                <Form.Check id="forYouMyLady" label={`for You My Lady`} type="switch" checked={forYouMyLady} onChange={(e) => BtnSwitchsetForYouMyLady(e)}></Form.Check>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Check type="switch" label={`${t("Market.offer")}?`} style={{ margin: "20px 0" }} checked={offerStatus} onChange={((e) => setOfferStatus(e.target.checked))} />
                    {offerStatus ?
                        <Form.Group className="mb-3" controlId="exampleForm.newPrice">
                            <Form.Label form="Price">{t("Market.price")} :</Form.Label>
                            <Form.Control type="number" placeholder="" id="Price" onChange={e => { setPrice(parseFloat(e.target.value) || undefined) }} defaultValue={price} />
                            <Form.Label form="newPrice">{t("Market.newPrice")} :</Form.Label>
                            <Form.Control type="number" placeholder="" id="newPrice" onChange={e => { setNewPrice(parseFloat(e.target.value) || undefined) }} defaultValue={newPrice} />
                        </Form.Group>
                        :
                        <Form.Group className="mb-3" controlId="exampleForm.Price">
                            <Form.Label>{t("Market.price")} :</Form.Label>
                            <Form.Control type="number" placeholder="" onChange={e => { setPrice(parseFloat(e.target.value) || undefined) }} defaultValue={price} />
                        </Form.Group>
                    }
                </div>
                <div>
                    <Form.Check type="switch" label={t("Market.عرض المنتجين?")} checked={statusOfferForTwoProducts} style={{ margin: "20px 0" }} onChange={((e) => setStatusOfferForTwoProducts(e.target.checked))} />
                </div>
                {
                    statusOfferForTwoProducts &&
                    <div style={{ width: "100%", maxWidth: "700px" }}>
                        <Form.Group className="mb-3" controlId="exampleForm.Price">
                            <Form.Label>{t("Market.سعر المنتج الثاني")} :</Form.Label>
                            <Form.Control type="number" placeholder="" onChange={e => { setPriceOfferForTwoProducts(parseFloat(e.target.value) || undefined) }} defaultValue={priceOfferForTwoProducts} />
                        </Form.Group>
                    </div>
                }
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Price">
                        <Form.Label>{t("Market.Available number")} :</Form.Label>
                        <Form.Control type="number" placeholder="" onChange={e => { setNumber(parseFloat(e.target.value) || undefined) }} defaultValue={number} />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Photos">
                        <Form.Label>{t("Market.Photos")} :</Form.Label>
                        <Form.Control type="file" onChange={handelPhoto} multiple accept="image/*" />
                    </Form.Group>
                    <div>
                        {photo.length > 0 &&
                            <div style={{ display: "flex", gap: "15%", height: 250, flexDirection: "column", marginBottom: 50 }}>
                                <h3>الصور التي تم اختيارها:</h3>
                                <div style={{ display: "flex", gap: 15 }}>
                                    {photo.map((i, index) => (
                                        <div key={index} style={{ marginBottom: '10px', display: "flex", flexDirection: 'column', justifyContent: "space-between" }}>
                                            <p>الصورة {index + 1}: {i.name}</p>
                                            <img src={URL.createObjectURL(i)} alt={`photo-${index}`} width="100" />
                                            <div style={{ display: "flex", gap: 10 }}>
                                                {index > 0 && (
                                                    <Button bsPrefix="BtnBNB" style={{ fontSize: 10, padding: "0 10px" }} onClick={() => changeImageOrder(index, index - 1)}>
                                                        <FaArrowUp size={20} />
                                                    </Button>
                                                )}
                                                {index < photo.length - 1 && (
                                                    <Button bsPrefix="BtnBNB" style={{ fontSize: 10, padding: "0 10px" }} onClick={() => changeImageOrder(index, index + 1)}>
                                                        <FaArrowDown size={20} />
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Video">
                        <Form.Label>{t("Market.Video")} :</Form.Label>
                        <Form.Control type="file" name="video" onChange={handelVideo} accept="video/*" />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px", marginBottom: 50 }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Photos">
                        <Form.Label>{t("Market.Illustrative pictures")} :</Form.Label>
                        <Form.Control type="file" onChange={handelPhotoIllustrativePictures} name="photos" multiple accept="image/*" />
                    </Form.Group>
                    <div>
                        {photoIllustrativePictures.length > 0 &&
                            <div style={{ display: "flex", gap: "15%", height: 250, flexDirection: "column" }}>
                                <h3>الصور التي تم اختيارها:</h3>
                                <div style={{ display: "flex", gap: 15 }}>
                                    {photoIllustrativePictures.map((i, index) => (
                                        <div key={index} style={{ marginBottom: '10px', display: "flex", flexDirection: 'column', justifyContent: "space-between" }}>
                                            <p>الصورة {index + 1}: {i.name}</p>
                                            <img src={URL.createObjectURL(i)} alt={`photo-${index}`} width="100" />
                                            <div style={{ display: "flex", gap: 10 }}>
                                                {index > 0 && (
                                                    <Button bsPrefix="BtnBNB" style={{ fontSize: 10, padding: "0 10px" }} onClick={() => changeImageIllustrativePictures(index, index - 1)}>
                                                        <FaArrowUp size={20} />
                                                    </Button>
                                                )}
                                                {index < photo.length - 1 && (
                                                    <Button bsPrefix="BtnBNB" style={{ fontSize: 10, padding: "0 10px" }} onClick={() => changeImageIllustrativePictures(index, index + 1)}>
                                                        <FaArrowDown size={20} />
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div>
                    <div style={{ color: "red" }}>{errInput}</div>
                    <Button bsPrefix="BtnBNB" onClick={BtnUpdate} disabled={disabledBtnUpdate} >{statusBtnUpdate}</Button>
                </div>
            </div>
        </div>
    </>)
}

export default UpdataProduct;