import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";




const SocialMediaIcons = () => {
    const { i18n } = useTranslation()

    return (<>
        <div style={{ position: "fixed", right: i18n.language === "en" ? 0 : undefined, left: i18n.language === "ar" ? 0 : undefined, top: "50%", margin: "0 50px", gap: 10, display: 'flex', flexDirection: "column" }}>
            <div>
                <Button bsPrefix="BtnBNBIconFavorite">
                    <FaInstagram size={23} color="#fe017d" />
                </Button>
            </div>
            <div>
                <Button bsPrefix="BtnBNBIconFavorite">
                    <FaTiktok size={23} color="black" />
                </Button>
            </div>
            <div>
                <Button bsPrefix="BtnBNBIconFavorite">
                    <FaFacebookF size={23} color={"#0866ff"} />
                </Button>
            </div>
        </div>
    </>)
}

export default SocialMediaIcons;