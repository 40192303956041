import "./Home.css"
import Offer1Guest from "./Offer1.guest"
import CategorysGuest from "./Categorye.guest"
import Product1Guest from "./Product1.guest"
import NewProductsGuest from "./NewProducts.guest"
import PackagesGuest from "./Packages.guest"
import MostCommonGuest from "./MostCommon.guest"
import ShoppBrandGuest from "./ShoppBrand.guest"
import { Button, Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import img from '../../files/HomeGuest/اعلانالوسط.jpg'
import MostLikedGuest from "./MostLiked.guest"
import AllProductsGuest from "./AllProductsGuest"
import { FaInstagram, FaFacebookF, FaTiktok } from "react-icons/fa";
import { SiWhatsapp } from "react-icons/si";
import { useContext, useEffect, useState } from "react"
import { OrderContext } from "../../components/context/OrderContext"
import { FcAssistant } from "react-icons/fc"
import ForYouMyLadyGuset from "./ForYouMyLadyGuest"

const Home: React.FC = () => {
    const { t } = useTranslation()
    const orderContext = useContext(OrderContext);
    const { isMobile } = orderContext!
    const [showWhatsapp, setShowWhatsapp] = useState<boolean>(false)
    useEffect(() => { window.scrollTo(0, 0) }, [])
    return (<>
        <div>
            <div>
                <div>
                    <Offer1Guest />
                </div>
                <div>
                    <CategorysGuest />
                </div>
                <div>
                    <ForYouMyLadyGuset />
                </div>
                <div>
                    <NewProductsGuest />
                </div>
                <div>
                    <Product1Guest />
                </div>
                <div style={{ margin: "20px 0" }}>
                    <div
                        style={{
                            minHeight: isMobile ? "100%" : "100vh", width: "100%", backgroundImage: `url(${img})`, backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            display: "flex", justifyContent: isMobile ? "center" : "left", alignItems: "center"
                        }}
                    >
                        <div style={{ margin: "5%", paddingTop: "10%", display: "flex", flexDirection: "column", width: "100%" }}>
                            {/* <Button bsPrefix="BtnFollowusonInstagram">{t("Home.FollowusonInstagram")}</Button> */}
                            <div style={{ margin: "0 5%", width: "90%", textAlign: "center" }} className={`d-flex flex-column ${isMobile ? "align-items-center" : "align-items-end"}  gap-3 #283730 p-4 rounded-3`}>
                                <Button bsPrefix="custom-social-btn" style={{ minWidth: 220 }} onClick={() => { setShowWhatsapp(true) }}>
                                    <SiWhatsapp size={22} color="#00a884" /> تواصلي معنا على الواتس أب
                                </Button>
                                <Button bsPrefix="custom-social-btn" style={{ minWidth: 220 }}>
                                    <FaInstagram size={22} className="text-danger" /> تابعينا علي الإنستغرام
                                </Button>
                                {
                                    !isMobile &&
                                    <>
                                        <Button bsPrefix="custom-social-btn" style={{ minWidth: 220 }}>
                                            <FaFacebookF size={22} className="text-primary" /> تابعينا علي الفيسبوك
                                        </Button>
                                        <Button bsPrefix="custom-social-btn" style={{ minWidth: 220 }}>
                                            <FaTiktok size={22} className="text-dark" /> تابعينا علي التيكتوك
                                        </Button>
                                    </>
                                }
                            </div>
                            {
                                !isMobile &&
                                <div style={{ margin: "0  5%", width: "90%" }} className={`d-flex flex-column ${isMobile ? "align-items-center" : "align-items-start"}  gap-3 #283730 p-4 rounded-3`}>
                                    <Button bsPrefix="custom-social-btn" style={{ minWidth: 220, minHeight: 80 }}>
                                        <FaTiktok size={22} className="text-dark" /> تابعينا علي التيكتوك
                                    </Button>
                                    <Button bsPrefix="custom-social-btn" style={{ minWidth: 220, minHeight: 80 }}>
                                        <FaTiktok size={22} className="text-dark" /> تابعينا علي التيكتوك
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <PackagesGuest />
                </div>
                <div>
                    <MostCommonGuest />
                </div>
                <div>
                    <MostLikedGuest />
                </div>
                <div>
                    <ShoppBrandGuest />
                </div>
                <div>
                    <AllProductsGuest />
                </div>
            </div>
        </div>
        <Modal show={showWhatsapp} onHide={() => setShowWhatsapp(false)}>
            <Modal.Header className="CloseBtn" style={{ backgroundColor: "#22cd5b" }} closeButton>
                <Modal.Title style={{ color: "white" }}>
                    {t("Hedar.We are here to help you, contact us on WhatsApp")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
                <a style={{ textDecoration: "none", fontSize: 30, color: "rgb(0, 168, 132)", alignItems: "center", display: "flex", gap: 10, width: "100%" }} href='https://api.whatsapp.com/send/?phone=905527864653&text&type=phone_number&app_absent=0'>  <FcAssistant size={40} />  {"  "}  هلال {" "} </a>
            </Modal.Body>
            <Modal.Footer style={{ alignItems: "center", justifyContent: "center" }}>
                <Button variant="secondary" onClick={() => setShowWhatsapp(false)}>{t("Hedar.Close")}</Button>
            </Modal.Footer>
        </Modal>
    </>)
}

export default Home;