import { Accordion, Button, Form, FormLabel } from "react-bootstrap";
import Langu from "../../../components/languge/languge";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import axios from "axios";
import { ChatContext } from "../../../components/context/ChatContext";
import { useNavigate, useParams } from "react-router";
import { Product, UsersProps } from "../../../Interfaces";
import CompleteInformationAdmin from "./CompleteInformationAdmin";
import ModeleDeleteProduct from "../../market/Modales/ModeleDeleteProduct";

interface InfoProductClientProps {
    data: Product
}

const InfoProductAdmin: React.FC<InfoProductClientProps> = ({ data }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const nav = useNavigate()
    const params = useParams()
    const chatContext = useContext(ChatContext);
    const { baseCurrency, currency } = chatContext!;
    const CarentItem = useSelector((state: RootState) => state.counterData);
    const userData = useSelector((state: RootState) => state.userData);
    const [dataUser, setDataUser] = useState<UsersProps>();
    const [dataProduct, setDataProduct] = useState<Product>();
    const [colorActive, setColorActive] = useState<string>(data?.colors?.[0]!);
    const [statusSwitch, setStatusSwitch] = useState<boolean>(data ? data?.disable! : false)
    const [forYouMyLady, setForYouMyLady] = useState<boolean>(data ? data?.ForYouMyLady! : false)
    const [showModale, setShowModale] = useState<boolean>(false)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProduct/${params.id}`)
            .then((doc) => { setDataProduct(doc.data) })
            .catch((err) => console.log(err))
    }, [params.id])


    const BtnSwitch = async (e: ChangeEvent<HTMLInputElement>) => {
        const newStatusSwitch: boolean = e.target.checked;
        setStatusSwitch(newStatusSwitch)
        await axios.patch(`${process.env.REACT_APP_URL_API}/API/Products/PutProductDisable/${data._id}`, { disable: newStatusSwitch }, {
            headers: {
                Authorization: "Bearer " + userData.user.token,
            }
        })
            .then(() => { })
            .catch((err) => console.log(err))
    }

    const BtnSwitchsetForYouMyLady = async (e: ChangeEvent<HTMLInputElement>) => {
        const newStatusSwitch: boolean = e.target.checked;
        setForYouMyLady(newStatusSwitch)
        await axios.patch(`${process.env.REACT_APP_URL_API}/API/Products/PutProductForYouMyLady/${data._id}`, { ForYouMyLady: newStatusSwitch }, {
            headers: {
                Authorization: "Bearer " + userData.user.token,
            }
        })
            .then((doc) => { })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        setTimeout(() => {
            setColorActive(data?.colors?.[0]!)
        }, 500)
    }, [dataUser, data?._id, data?.colors])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${userData.user.userDetals?._id}`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => { setDataUser(doc.data) })
            .catch((err) => console.log(err))
    }, [userData.user.token, userData.user.userDetals?._id])

    const finshNumber = (num: number) => {
        const Currency = currency === "ILS" ? "ILS" : currency === "USD" ? "USD" : "JOD"
        if (currency === "ILS") {
            const afterNum = (Number(num) * baseCurrency)
            return (afterNum.toFixed(2)) + " " + Currency
        }
        else {
            return (Number(num) * baseCurrency).toFixed(2) + " " + Currency
        }
    }

    return (<>
        <div style={{ padding: "10px 10px 0 10px", display: "flex", flexDirection: "column", gap: 10 }}>
            <div style={{ fontWeight: 700, fontSize: 22 }}><Langu {...data.product!} /></div>
            <div style={{ width: "100%", fontSize: "18px" }}>
                {
                    data.newPrice ?
                        <div style={{ display: "flex", width: "100%", justifyContent: "start", gap: '20px', fontWeight: 500, flexDirection: "column" }}>
                            <div>
                                {t('Market.price')} :
                            </div>
                            <div style={{ display: "flex", gap: 10 }}>
                                <div style={{ textDecoration: "line-through", color: "black", fontWeight: 700, fontSize: 25 }}>
                                    {finshNumber(data.price!)}
                                </div>
                                <div style={{ color: "red", fontWeight: 700, fontSize: 25 }}>
                                    {t('Market.newPrice')} : {finshNumber(data.newPrice!)}
                                </div>
                            </div>
                        </div>
                        :
                        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                            <div style={{ textAlign: "start", color: "black", fontWeight: 700 }}>
                                {t('Market.price')} :
                            </div>
                            <div style={{ textAlign: "start", color: "black", fontWeight: 700, fontSize: 25 }}>
                                {finshNumber(data.price!)}
                            </div>
                        </div>

                }
            </div>
            <div style={{ display: "flex" }}>
                {data.colors?.length! > 0 && data.colors?.map((color: string, index) => (
                    <Button
                        key={index}
                        onClick={() => setColorActive(color)}
                        style={{ backgroundColor: color, minWidth: 25, minHeight: 25, border: "1px solid #dddddd", margin: "10px 5px", boxShadow: colorActive === color ? `0px 0px 10px 5px ${colorActive}` : '' }}>
                    </Button>
                ))}
            </div>
        </div>
        <div>
            <CompleteInformationAdmin data={dataProduct!} />
        </div>
        <div style={{ padding: 10, display: "flex", justifyContent: "space-around", margin: "20px 0", alignItems: "center", flexWrap: "wrap", gap: 10 }}>
            <Button bsPrefix="BtnBNB" onClick={() => nav(`/admin/market/updateProduct/${data?._id}`)}>{t("Market.Update Product")}</Button>
            <Button variant="danger" onClick={() => setShowModale(true)}>{t("Market.Delete")}</Button>
            <FormLabel htmlFor="disable">{t("Home.disable")} : </FormLabel>
            <Form.Check id="disable" type="switch" checked={statusSwitch} onChange={(e) => BtnSwitch(e)}></Form.Check>
            <FormLabel htmlFor="forYouMyLady">{t("Home.for You My Lady")} : </FormLabel>
            <Form.Check id="forYouMyLady" type="switch" checked={forYouMyLady} onChange={(e) => BtnSwitchsetForYouMyLady(e)}></Form.Check>
        </div>
        <div>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{t("Market.Shipping and Returns")} </Accordion.Header>
                    <Accordion.Body>
                        شبسيشبسش سيش بشسي بشسي بيس بسيب شسيب سشيب شسيب شسيب شسيب سشيب سشيب شسيبسشيبصثب سيشب شسيب سشيب سشبصثضب سيش بقشثصبلسيش  بشب صثبسيب سشيب شصثبشسيب سشيبسيبصشث بسيش
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
        <ModeleDeleteProduct id={data._id!} show={showModale} ModeleDeleteProduct={setShowModale} />
    </>)
}

export default InfoProductAdmin;