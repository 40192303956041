import axios from "axios";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { LoadingBtn } from "../../components/laoding/laoding";



const ChangeEmail: React.FC = () => {
    const { t } = useTranslation()
    const nav = useNavigate()
    const params = useParams()
    const [siccess, setSuccess] = useState<boolean>(false)

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_URL_API}/API/Auth/VerifyTokenChangeEmail/${params.token}/${params.id}`)
            .then(() => {
                nav(`/client/myAccount/AccountDetals`)
            })
            .catch(() => setSuccess(false))
    }, [])

    return (<>
        {
            siccess ?
                null
                :
                <div style={{ width: "100%", height: "50vh", display: "flex", justifyContent: "center", color: "red", alignItems: "center", fontSize: "20px" }}>
                    انتهاء صلاحية الكود
                </div>
        }
    </>)
}

export default ChangeEmail;