import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Langu from "../../components/languge/languge";
import { Category } from "../../Interfaces";
import { OrderContext } from "../../components/context/OrderContext";


const CategorysGuest: React.FC = () => {
    const nav = useNavigate()
    const { t } = useTranslation();
    const ordercontext = useContext(OrderContext)
    const { isMobile } = ordercontext!
    const [dataCategorys, setDataCategorys] = useState<Category[]>();
    const scrollRef = useRef<HTMLDivElement>(null);
    const [dragging, setDragging] = useState(false);
    const isDragging = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);
    const dragThreshold = 5;

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategorys`)
            .then((doc) => setDataCategorys(doc.data))
            .catch((err) => console.log(err));
        window.scrollTo(0, 0)
    }, []);

    const onMouseDown = (e: React.MouseEvent) => {
        isDragging.current = true;
        startX.current = e.pageX - (scrollRef.current?.offsetLeft || 0);
        scrollLeft.current = scrollRef.current?.scrollLeft || 0;
        setDragging(true);
    };

    const onMouseLeaveOrUp = (e: React.MouseEvent) => {
        if (isDragging.current) {
            const x = e.pageX - (scrollRef.current?.offsetLeft || 0);
            const distance = Math.abs(x - startX.current);
            if (distance < dragThreshold) {
                setDragging(false);
            }
        }
        isDragging.current = false;
    };

    const onMouseMove = (e: React.MouseEvent) => {
        if (!isDragging.current) return;
        e.preventDefault();
        const x = e.pageX - (scrollRef.current?.offsetLeft || 0);
        const walk = (x - startX.current) * 1;
        if (scrollRef.current) {
            scrollRef.current.scrollLeft = scrollLeft.current - walk;
        }
    };

    const onClickCategory = (e: React.MouseEvent, category: Category) => {
        if (dragging) {
            e.preventDefault();
        } else {
            nav(`/guest/category/${category.name.en}`, { state: category });
        }
    };

    return (
        <div style={{ margin: "10px 0" }}>
            <div style={{ width: "100%", textAlign: "center", padding: "15px 0" }}>
                <h2 style={{
                    position: "relative",
                    display: "inline-block",
                    paddingBottom: "20px"
                }}>
                    {t("Home.Our varieties")}
                    <span style={{
                        position: "absolute",
                        bottom: "0",
                        left: "50%",
                        transform: "translateX(-50%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px"
                    }}>
                        <div style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "black",
                            borderRadius: "50%",
                        }}></div>
                        <div style={{
                            width: "40px",
                            height: "5px",
                            backgroundColor: "black",
                            borderRadius: "3px",
                        }}></div>
                        <div style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "black",
                            borderRadius: "50%",
                        }}></div>
                    </span>
                </h2>
            </div>
            <div
                ref={scrollRef}
                onMouseDown={onMouseDown}
                onMouseLeave={onMouseLeaveOrUp}
                onMouseUp={onMouseLeaveOrUp}
                onMouseMove={onMouseMove}
                style={{
                    margin: "10px 0",
                    display: "flex",
                    flexWrap: isMobile ? "wrap" : undefined,
                    justifyContent: isMobile ? "center" : undefined,
                    overflowX: "auto",
                    scrollbarWidth: "none",
                    cursor: "grab",
                }}
            >
                {
                    dataCategorys?.map((Category, index) => (
                        <div onClick={(e) => onClickCategory(e, Category)} style={{ textDecoration: "none", color: "initial", width: isMobile ? "30%" : undefined, height: isMobile ? "30%" : undefined }} key={index}>
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", margin: "0 10px", cursor: "pointer" }}>
                                <img draggable={false} alt="وصف الصورة" style={{ width: isMobile ? "100px" : "300px", height: isMobile ? "100px" : "300px", borderRadius: "50%", border: "1px solid #dddddd" }} src={`${process.env.REACT_APP_URL_API}/API/files/Categorys/${Category.file ? Category.file[0] : ""}`} />
                                <div style={{ fontWeight: "600", marginTop: 10, fontSize: isMobile ? 20 : 23 }}><Langu {...Category.name} /></div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default CategorysGuest;
