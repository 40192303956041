import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LoadingBtn } from "../../components/laoding/laoding";
import CardProducts from "../component/CardProductsGuest";
import { Product } from "../../Interfaces";
import { OrderContext } from "../../components/context/OrderContext";


const AllProductsGuest = () => {
    const [dataProducts, setDataPoducts] = useState<Product[]>()
    const { t } = useTranslation()
    const ordercontext = useContext(OrderContext)
    const { isMobile } = ordercontext!
    const [statusBtnViewMore, setStatusBtnViewMore] = useState<any>(t("Market.View more"))
    const [limit, setLimit] = useState<number>(30)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProducts/${limit}`)
            .then((doc) => setDataPoducts(doc.data))
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtnViewMore(t("Market.View more"))
            })
    }, [limit, t])

    const handleLimit = () => {
        setStatusBtnViewMore(<LoadingBtn />)
        setLimit(limit + 20)
    }

    return (<>
        <div style={{ margin: "10px 0" }}>
            <div style={{ display: "flex", width: "100%", padding: "50px 20px", justifyContent: "center", marginBottom: "10px" }}>
                <div style={{ textAlign: "center", position: "relative", width: "100%" }}>
                    <h2 style={{ margin: 0 }}>{t("Home.BrowseAllProducts")}</h2>
                    <div style={{
                        position: "absolute",
                        bottom: "-15px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "8px"
                    }}>
                        <div style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "black",
                            borderRadius: "50%",
                        }}></div>
                        <div style={{
                            width: "50px",
                            height: "4px",
                            backgroundColor: "black",
                            borderRadius: "2px",
                        }}></div>
                        <div style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "black",
                            borderRadius: "50%",
                        }}></div>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", gap: isMobile ? "1%" : 20, flexWrap: "wrap", justifyContent: "center", padding: isMobile ? "0 10px" : "0 20px" }}>
                {
                    dataProducts?.length! > 0 ? dataProducts?.map((Product, index) => (
                        <CardProducts Product={Product} dragging={false} key={index} />
                    ))
                        :
                        <div style={{ width: "100%", display: 'flex', justifyContent: "center", minHeight: "50vh", alignItems: "center", fontSize: "22px" }}>
                            {t("Market.There are no products with these specifications.")}
                        </div>
                }
            </div>
            <div style={{ textAlign: "center", margin: "30px" }}>
                <Button bsPrefix="BtnBNB" onClick={handleLimit}>{statusBtnViewMore}</Button>
            </div>
        </div>

    </>)
}

export default AllProductsGuest;