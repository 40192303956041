import { Alert, Button } from "react-bootstrap";
import Langu from "../../../components/languge/languge";
import { FaRegHeart } from "react-icons/fa";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useTranslation } from "react-i18next";
// import { setFavorites } from "../../../redux/userSlice";
import { ChatContext } from "../../../components/context/ChatContext";
import { Product, UsersProps } from "../../../Interfaces";

interface MessageProductProps {
    data: Product
}

const MessageProductGuest: React.FC<MessageProductProps> = ({ data }) => {
    // const dispatch = useDispatch()
    const userData = useSelector((state: RootState) => state.userData);
    const { t } = useTranslation()
    const chatContext = useContext(ChatContext);
    const { baseCurrency, currency } = chatContext!;
    const [remind, setRemind] = useState<boolean>(data?.remind?.includes(userData.user.userDetals?._id!)!);
    const [disableBtnRemind, setDisableBtnRemind] = useState<boolean>(false)
    // const [favorite, setFavorite] = useState<boolean>(data?.favorite?.includes(data?._id!)!);
    const [dataUser, setDataUser] = useState<UsersProps>();
    // const [showAlertAdd, setShowAlertAdd] = useState<boolean>(false);
    // const [showAlertDis, setShowAlertDis] = useState<boolean>(false);
    const [colorActive, setColorActive] = useState<string>(data?.colors?.[0]!);
    const [showAlirtMsg, setShowAlirtMsg] = useState<boolean>(false)

    // const setShowAlertAd = () => {
    //     setShowAlertAdd(false)
    //     setShowAlertDis(true)
    // }
    // const setShowAlertDi = () => {
    //     setShowAlertDis(false)
    //     setShowAlertAdd(true)
    // }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${userData.user.userDetals?._id}`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => { setDataUser(doc.data) })
            .catch((err) => console.log(err))
    }, [userData.user.userDetals?._id, userData.user.token])


    useEffect(() => {
        setColorActive(data?.colors?.[0]!)
        setRemind(data?.remind?.includes(userData?.user?.userDetals?._id!)!)
    }, [data, userData?.user?.userDetals?._id])

    useEffect(() => {
        setTimeout(() => {
            setColorActive(data?.colors?.[0]!)
            // setFavorite(dataUser?.favorite?.includes(data?._id!)!)
        }, 500)
    }, [dataUser, data?.colors])

    // const AddFavorite = async () => {
    //     await axios.put(`${process.env.REACT_APP_URL_API}/API/Users/PutAddFavorite/${userData.user.userDetals?._id}`, { FavoriteId: data?._id, favorite },
    //         {
    //             headers: {
    //                 Authorization: "Bearer " + userData.user.token,
    //             }
    //         }
    //     )
    //         .then(() => {
    //             favorite === true ? setShowAlertAd() : setShowAlertDi()
    //             dispatch(setFavorites(data?._id!))
    //         })
    //         .catch((err) => console.log(err))
    // }

    const BtnRemind = async () => {
        setDisableBtnRemind(true)
        await axios.put(`${process.env.REACT_APP_URL_API}/API/Products/PutProductRemind/${data?._id!}`, { userId: userData.user.userDetals?._id },
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .catch((err) => console.log(err))
            .finally(() => setDisableBtnRemind(false))
    }

    const finshNumber = (num: number) => {
        const Currency = currency === "ILS" ? "ILS" : currency === "USD" ? "USD" : "JOD"
        if (currency === "ILS") {
            const afterNum = Math.round(Number(num) * baseCurrency)
            const remainder = afterNum % 10;
            if (remainder < 5) {
                return (afterNum - remainder).toLocaleString('en-US') + " " + Currency
            } else {
                return (afterNum + (10 - remainder)).toLocaleString('en-US') + " " + Currency
            }
        }
        else {
            return Math.round(Number(num) * baseCurrency) + " " + Currency
        }
    }

    return (<>
        <div style={{ minHeight: 100, position: "fixed", width: "100%", bottom: 0, borderTop: "1px solid #dddddd", backgroundColor: "white", display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                <div style={{ maxWidth: 1660, display: "flex", justifyContent: "space-around", width: "100%", padding: "0 40px", alignItems: "center" }}>
                    <div style={{ display: "flex", gap: 20 }}>
                        <img alt="" style={{ width: 80, height: 80, borderRadius: "50%", border: "1px solid #dddddd" }} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${data?.file?.[0]}`} />
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <div style={{ fontWeight: 600, fontSize: 21 }}>
                                <Langu {...data?.product!} />
                            </div>
                            <div>
                                {
                                    data?.newPrice ?
                                        <div style={{ display: "flex", width: "100%", justifyContent: "start", gap: '20px' }}>
                                            <div>
                                                {t('Market.price')} :
                                            </div>
                                            <div style={{ textDecoration: "line-through", color: "black", fontWeight: 700 }}>
                                                {finshNumber(data?.price!)}
                                            </div>
                                            <div style={{ color: "red", fontWeight: 700 }}>
                                                {t('Market.newPrice')} : {finshNumber(data?.newPrice!)}
                                            </div>
                                        </div>
                                        :
                                        <div style={{ textAlign: "start", color: "black", fontWeight: 700 }}>
                                            {t('Market.price')} : {finshNumber(data?.price!)}
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ display: "flex" }}>
                            {data?.colors?.length! > 0 && data?.colors?.map((color: string, index) => (
                                <Button
                                    key={index}
                                    onClick={() => setColorActive(color)}
                                    style={{ backgroundColor: color, minWidth: 25, minHeight: 25, border: "1px solid #dddddd", margin: "10px 5px", boxShadow: colorActive === color ? `0px 0px 10px 5px ${colorActive}` : '' }}>
                                </Button>
                            ))}
                        </div>
                        <div style={{ padding: 10, display: "flex", justifyContent: "space-around", gap: 30, alignItems: "center" }}>
                            <div>
                                {
                                    data?.disable ?
                                        remind ?
                                            <Button bsPrefix="BtnBNB" disabled={disableBtnRemind} onClick={BtnRemind} >{t("Market.You will be reminded when available")}</Button>
                                            :
                                            <Button bsPrefix="BtnBNB" disabled={disableBtnRemind} onClick={BtnRemind} >{t("Market.Remind me when available")}</Button>
                                        :
                                        <Button bsPrefix="BtnBNB" onClick={() => { setShowAlirtMsg(true) }} >{t("Market.add to cart")}</Button>
                                }
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <Button bsPrefix="BtnBNBIconFavorite" onClick={() => { setShowAlirtMsg(true) }}><FaRegHeart size={"23px"} /></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        < Alert
            show={showAlirtMsg}
            variant={"danger"}
            style={{ position: "fixed", top: '10px', left: "30px", minWidth: "20%", zIndex: "10" }}
            onClose={() => setShowAlirtMsg(false)} dismissible
        >
            <Alert.Heading style={{ textAlign: "start" }}>
                {t("Market.Error message")}
            </Alert.Heading>
            <p>
                {t("Market.You are not registered, please log in to continue")}
            </p>
        </Alert>
    </>)
}

export default MessageProductGuest;