import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import CoProduMostCommonAdmin from "../market/getAlLProduct/getMostCommon.admin/Co.Produ.MostCommon.admin";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Product } from "../../Interfaces";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import AdminCardPeoduct from "../components/CardsProducts/AdminCardProduct";
import { OrderContext } from "../../components/context/OrderContext";



const MostCommonAdmin: React.FC = () => {
    const { t } = useTranslation()
    const nav = useNavigate()
    const ordercontext = useContext(OrderContext)
    const { isMobile } = ordercontext!
    const [dataProducts, setDataProducts] = useState<Product[]>()
    const scrollRef = useRef<HTMLDivElement>(null);
    const isDragging = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);
    const dragThreshold = 5;
    const [dragging, setDragging] = useState(false);
    const [isAtEnd, setIsAtEnd] = useState(false);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProducts`)
            .then((doc) => setDataProducts(doc.data))
            .catch((err) => console.log(err))
    }, [])

    const onMouseDown = (e: React.MouseEvent) => {
        isDragging.current = true;
        startX.current = e.pageX - (scrollRef.current?.offsetLeft || 0);
        scrollLeft.current = scrollRef.current?.scrollLeft || 0;
        setDragging(false);
    };

    const onMouseLeaveOrUp = (e: React.MouseEvent) => {
        if (isDragging.current) {
            const x = e.pageX - (scrollRef.current?.offsetLeft || 0);
            const distance = Math.abs(x - startX.current);
            if (distance > dragThreshold) {
                setDragging(true);
            }
        }
        isDragging.current = false;
    };

    const onMouseMove = (e: React.MouseEvent) => {
        if (!isDragging.current) return;
        e.preventDefault();
        const x = e.pageX - (scrollRef.current?.offsetLeft || 0);
        const walk = (x - startX.current) * 1;
        if (scrollRef.current) {
            scrollRef.current.scrollLeft = scrollLeft.current - walk;
        }
    };

    const handleScroll = () => {
        if (scrollRef.current) {
            const { scrollWidth, scrollLeft, clientWidth } = scrollRef.current;
            setIsAtEnd((scrollLeft * -1) + clientWidth >= scrollWidth - 1);
        }
    };
    return (<>
        <div style={{ margin: isMobile ? 0 : "30px 0px" }}>
            <div>
                <div style={{ display: "flex", width: "100%", padding: "50px 0", justifyContent: "center", marginBottom: "10px" }}>
                    {!isMobile &&
                        <div style={{ width: "150px" }}></div>
                    }
                    <div style={{ textAlign: "center", position: "relative", width: "100%" }}>
                        <h2 style={{ margin: 0 }}>{t("Home.Most popular")}</h2>
                        <div style={{
                            position: "absolute",
                            bottom: "-15px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "8px"
                        }}>
                            <div style={{
                                width: "10px",
                                height: "10px",
                                backgroundColor: "black",
                                borderRadius: "50%",
                            }}></div>
                            <div style={{
                                width: "50px",
                                height: "4px",
                                backgroundColor: "black",
                                borderRadius: "2px",
                            }}></div>
                            <div style={{
                                width: "10px",
                                height: "10px",
                                backgroundColor: "black",
                                borderRadius: "50%",
                            }}></div>
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Button bsPrefix="BrandShoppBrand" style={{ width: "150px", backgroundColor: "white" }} onClick={() => nav("/admin/market/getMostCommon")}>{t("Home.Show all")}</Button>
                    </div>
                </div>
                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", padding: isMobile ? 5 : "0 30px" }}>
                    <MdKeyboardDoubleArrowRight
                        size={40}
                        color={scrollRef.current?.scrollLeft !== 0 ? "black" : "#dddddd"}
                        onClick={() => {
                            if (scrollRef.current) {
                                scrollRef.current.scrollLeft = 0;
                            }
                        }}
                        style={{ cursor: "pointer" }}
                    />
                    <MdKeyboardDoubleArrowLeft
                        size={40}
                        color={isAtEnd ? "#dddddd" : "black"}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            if (scrollRef.current) {
                                const scrollLeft = (scrollRef.current?.scrollWidth - scrollRef.current?.clientWidth) * -1
                                scrollRef.current.scrollLeft = scrollLeft;
                            }
                        }}
                    />
                </div>
                <div
                    ref={scrollRef}
                    onMouseDown={onMouseDown}
                    onMouseLeave={onMouseLeaveOrUp}
                    onMouseUp={onMouseLeaveOrUp}
                    onMouseMove={onMouseMove}
                    onScroll={handleScroll}
                    style={{ display: 'flex', width: "100%", gap: "2%", overflowX: "auto", scrollbarWidth: "none", cursor: "grab" }}>
                    {/* <CoProduMostCommonAdmin dataProducts={dataProducts!} dragging={dragging} homePage={true} /> */}
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", gap: isMobile ? "3%" : 20, flexWrap: "nowrap", padding: "0 20px" }}>
                        {
                            dataProducts?.length! > 0 ? dataProducts?.sort((a, b) => b.sold - a.sold).map((Product, index) => (
                                <AdminCardPeoduct Product={Product} dragging={dragging} key={index} />
                            ))
                                :
                                <div style={{ width: "100%", display: 'flex', justifyContent: "center", minHeight: "50vh", alignItems: "center", fontSize: "22px" }}>
                                    {t("Market.There are no products with these specifications.")}
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div >
    </>)
}

export default MostCommonAdmin;



