import React, { useContext, useState } from "react";
import Langu from "../../../components/languge/languge";
import { useTranslation } from "react-i18next";
import ModelXLAdmin from "../../market/Modales/ModaleXL.admin";
import { Button } from "react-bootstrap";
import { Product } from "../../../Interfaces";
import { ChatContext } from "../../../components/context/ChatContext";
import { useNavigate } from "react-router";
import { OrderContext } from "../../../components/context/OrderContext";

interface dataProductsProps {
    Product: Product;
    dragging: boolean;
}

const AdminCardPeoduct: React.FC<dataProductsProps> = ({ Product, dragging }) => {
    const { t } = useTranslation()
    const nav = useNavigate()
    const context = useContext(ChatContext)
    const { baseCurrency, currency } = context!
    const ordercontext = useContext(OrderContext)
    const { isMobile } = ordercontext!
    // const [showModalXL, setShowModalXL] = useState<any>({
    //     setShow: false,
    //     data: {}
    // })

    const onClickProduct = (event: React.MouseEvent, product: Product) => {
        if ((event.target as HTMLElement).classList.contains("BtnBNB")) {
            return;
        }
        else if (!dragging) {
            nav(`/admin/market/productDetails/${Product?._id}`)
        }
    };

    const truncateText = (text: string): string => {
        let shortText = text.substring(0, 50)
        if (text.length > 50) {
            shortText = shortText + "..."
        }
        return shortText
    }
    const extractTextFromElement = (element: React.ReactNode): string => {
        // التأكد من أن العنصر هو نص (string)
        if (typeof element === "string") {
            return element;
        }
        // إذا كان العنصر JSX، نحاول استخراج النص من داخله
        if (React.isValidElement(element) && element.props.children) {
            return String(element.props.children);
        }
        return "";
    };

    const finshNumber = (num: number) => {
        const Currency = currency === "ILS" ? "ILS" : currency === "USD" ? "USD" : "JOD"
        if (currency === "ILS") {
            const afterNum = (Number(num) * baseCurrency)
            return (afterNum.toFixed(2)) + " " + Currency
        }
        else {
            return (Number(num) * baseCurrency).toFixed(2) + " " + Currency
        }
    }

    return (<>
        <div
            onClick={(event) => onClickProduct(event, Product)}
            style={{ width: isMobile ? "48%" : 302, minWidth: isMobile ? "48%" : undefined, maxWidth: isMobile ? "48%" : undefined, border: "1px solid #dddddd", minHeight: isMobile ? 330 : 450, marginTop: "1%" }}
        >
            <div style={{ display: 'flex', justifyContent: "space-between", flexDirection: "column", alignItems: "center", width: "100%", cursor: "pointer", position: "relative", height: "100%", minHeight: isMobile ? 300 : 450 }}>
                <div className="cardHome" style={{ borderRadius: "5px", width: isMobile ? "100%" : 300, height: isMobile ? "60%" : 300 }}>
                    <img alt="" draggable={false} style={{ width: isMobile ? "100%" : undefined, height: isMobile ? "100%" : undefined, minHeight: isMobile ? 160 : undefined }} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[0] : ""}`} />
                    <img alt="" draggable={false} style={{ width: isMobile ? "100%" : undefined, height: isMobile ? "100%" : undefined, minHeight: isMobile ? 160 : undefined }} className="imgHomeHover2" src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[1] : ""}`} />
                </div>
                {
                    Product.offers &&
                    <div style={isMobile ? { position: "absolute", top: Product.disable ? "50px" : "10px", left: "10px", backgroundColor: "red", borderRadius: "15px", padding: "10px", color: "white", width: isMobile ? "auto" : "65px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center" } :
                        { position: "absolute", top: Product.disable ? "90px" : "20px", left: "20px", backgroundColor: "red", borderRadius: "50%", padding: "10px", color: "white", width: "65px", height: "60px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {Product.discount! * -1} %
                    </div>
                }
                {
                    Product.disable &&
                    <div style={isMobile ? { position: "absolute", top: "10px", left: "10px", backgroundColor: "black", borderRadius: "15px", padding: "10px", color: "white", width: isMobile ? "auto" : "65px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" } :
                        { position: "absolute", top: "20px", left: "20px", backgroundColor: "black", borderRadius: "50%", padding: "10px", color: "white", width: "65px", height: "65px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                        {t("Market.Sold out")}
                    </div>
                }
                {Product.colors?.length! > 0 &&
                    <div style={{ display: "flex" }}>
                        {Product.colors?.map((color: string, index) => (
                            <Button
                                key={index}
                                style={{ backgroundColor: color, minWidth: 25, minHeight: 25, border: "1px solid #dddddd", margin: "10px 5px" }}>
                            </Button>
                        ))}
                    </div>
                }
                <div style={{ fontSize: "20px", padding: "5px" }}>{truncateText(extractTextFromElement(Langu(Product.product!)))}</div>
                <div style={{ width: "100%", fontSize: "15px" }}>
                    {
                        Product.newPrice ?
                            <div style={{ display: "flex", width: "100%", justifyContent: "center", gap: 5, flexDirection: isMobile ? "column" : undefined, alignItems: isMobile ? "center" : undefined }}>
                                <div style={{ textDecoration: "line-through", color: "gray", fontSize: 20, fontWeight: 600 }}>
                                    {finshNumber(Product.price!)}
                                </div>
                                <div style={{ color: "red", fontSize: 20, fontWeight: 600 }}>
                                    {finshNumber(Product.newPrice!)}
                                </div>
                            </div>
                            :
                            <div style={{ textAlign: "center", color: "gray", fontSize: 20, fontWeight: 600 }}>
                                {finshNumber(Product.price!)}
                            </div>
                    }
                </div>
            </div>
        </div>

        {/* {
            showModalXL.setShow &&
            <ModelXLAdmin show={showModalXL.setShow} setShowModalXL={setShowModalXL} data={showModalXL.data} />
        } */}
    </>)
}

export default AdminCardPeoduct;