import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router";
import { Button, Table } from "react-bootstrap";
import { RootState } from "../../../../redux/store";
import { OrderProps } from "./GetOrders.admin";
import { LoadingBtn } from "../../../../components/laoding/laoding";
import { ThemeColor } from "../../../../theme";




const GetOrdersMobileAdmin: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData)
    const { t } = useTranslation()
    const nav = useNavigate()
    const [dataOrders, setDataOrders] = useState<OrderProps[]>()
    const [statusBtn, setStatusBtn] = useState<any>(t("Market.View more"))
    const [disabledBtn, setDisabled] = useState<boolean>(false)
    const [limit, setLimit] = useState<number>(30)
    const [search, setSearch] = useState<string>()


    useEffect(() => { window.scrollTo(0, 0) }, [])

    const getSearchData = async () => {
        setStatusBtn(<LoadingBtn />)
        setDisabled(true)
        await axios.post(`${process.env.REACT_APP_URL_API}/API/Orders/PostSearchOrders/${limit}`, { search },
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => setDataOrders(doc.data.movies))
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtn(t("Market.View more"))
                setDisabled(false)
            })
    }

    useEffect(() => {
        const debounce = setTimeout(() => {
            getSearchData()
        }, 800)
        return () => clearTimeout(debounce)
    }, [search, limit])


    return (<>
        <div style={{ display: "flex", justifyContent: "center", backgroundColor: "#ededed" }}>
            <div style={{ maxWidth: "1440px", width: "100%", padding: 0 }}>
                {
                    dataOrders?.map((order, index) => (
                        <div key={index} style={{ border: "1px solid #dddddd", backgroundColor: "white", margin: 10 }}>
                            <div style={{ display: "flex", justifyContent: "space-between", padding: 10, borderBottom: "1px solid #dddddd" }}>
                                <div>
                                    <div>
                                        {new Date(order?.createdAt!).toLocaleString('en-GB', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false
                                        })}
                                    </div>
                                    <div style={{ color: ThemeColor.text }}>
                                        {
                                            order.totalPrice?.toFixed(2) + " " + order.currency
                                        }
                                    </div>
                                </div>
                                <div>
                                    <Button bsPrefix="BtnBNB" style={{ height: "100%" }} onClick={() => nav(`/admin/market/orders/getOrder/${order._id}`)}>{t("Market.Order details")}</Button>
                                </div>
                            </div>
                            <div style={{ borderBottom: "1px solid #dddddd", padding: 10 }}>
                                <div>
                                    {t("Home.name")} : {order.userName}
                                </div>
                                <div>
                                    {t("Home.phone")} : {order.phone}
                                </div>
                                <div>
                                    {t("Market.PayPage.Payment methods")} : {t(`Market.PayPage.${order.paymentMethod}`)}
                                </div>
                            </div>
                            <div style={{ padding: 10 }}>
                                <div>
                                    {t(`Market.PayPage.${order.status}`)}
                                </div>
                                <div style={{ display: "flex", gap: 5, flexWrap: "wrap" }}>
                                    {
                                        order.dataItems?.map((product, index) => (
                                            <img key={index} draggable={false} alt="وصف الصورة" style={{ width: 70, height: 70 }} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${product.file ? product.file : ""}`} />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }
                <div style={{ textAlign: "center", margin: "20px 0" }}>
                    <Button bsPrefix="BtnBNB" onClick={() => { setLimit(limit + 20) }} disabled={disabledBtn} >{statusBtn}</Button>
                </div>
            </div>
        </div >
    </>)
}

export default GetOrdersMobileAdmin;