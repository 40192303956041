import { useContext } from "react";
import { Form } from "react-bootstrap";
import { ChatContext } from "../context/ChatContext";
import { OrderContext } from "../context/OrderContext";


const SetCurrency: React.FC = () => {
    const context = useContext(ChatContext)
    const { setCurrency } = context!
    const ordercontext = useContext(OrderContext)
    const { isMobile } = ordercontext!
    return (<>
        <Form.Select style={{
            paddingLeft: isMobile ? 5 : "10px",
            paddingRight: isMobile ? 5 : "10px",
            width: "100%",
            height: "35px",
            appearance: "none",
            background: `#ffffff`,
            // backgroundSize: "0px 0px, 0%",
            // backgroundColor: "#ffffff",
            textAlign: "center",
            cursor: "pointer"
        }} onChange={(e) => { setCurrency(e.target.value) }}>
            <option style={{ textAlign: "center" }} value="ILS"> ILS</option>
            <option style={{ textAlign: "center" }} value="USD"> USD</option>
            <option style={{ textAlign: "center" }} value="JOD"> JOD</option>
        </Form.Select>
    </>)
}

export default SetCurrency;