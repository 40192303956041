import { Button, CloseButton } from 'react-bootstrap';
import './HedarGuest.css'
import LoginModal from './LoginModal';
import { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaBars, FaRegUser } from "react-icons/fa";
import { IoSearch, IoWalletOutline } from "react-icons/io5";
import { MdOutlineShoppingCart } from "react-icons/md";
import { GrFavorite } from "react-icons/gr";
import Search from './Search';
import ShoppingCart from './ShoppingCart';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import I18nGuest from './I18n.guest';
import { ThemeColor } from '../../theme';
import { offerHedarRedux } from '../../redux/counterSlice';
import axios from 'axios';
import Langu from '../../components/languge/languge';
import SetCurrency from '../../components/setCurrency/SetCurrency';
import { Category, OthersProps } from '../../Interfaces';
import { ChatContext } from '../../components/context/ChatContext';
import { OrderContext } from '../../components/context/OrderContext';
import img2 from "../../files/HomeGuest/شعارتريتميأبيض.jpg"


const HedarGuest: React.FC = () => {
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch();
    const nav = useNavigate()
    const CarentItem = useSelector((state: RootState) => state.counterData)
    const chatContext = useContext(ChatContext)
    const { baseCurrency, currency } = chatContext!
    const ordercontext = useContext(OrderContext)
    const { isMobile } = ordercontext!

    const [showModalLogin, setShowModalLogin] = useState<boolean>(false)
    const [showSearch, setShowSearch] = useState<boolean>(false)
    const [showShoppingCart, setShowShoppingCart] = useState<boolean>(false)
    const [dataCategotys, setDataCategorys] = useState<Category[]>()
    const [dataOthers, setDataOthers] = useState<OthersProps>()
    const [isVisible, setIsVisible] = useState<boolean>(true);
    const [close, setClose] = useState<Boolean>(isVisible)
    const [menuVisible, setMenuVisible] = useState<boolean>(false);

    const handleClose = () => {
        setIsVisible(false);
        setTimeout(() => {
            setClose(false)
            dispatch(offerHedarRedux({ offerHedar: true }));
        }, 700);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategorys`)
            .then((doc) => setDataCategorys(doc.data))
            .catch((err) => console.log(err))
        axios.get(`${process.env.REACT_APP_URL_API}/API/Others/GetOthers`)
            .then((doc) => setDataOthers(doc.data?.[0]))
            .catch((err) => console.log(err))
        document.body.dir = i18n.dir()
    }, [])

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    return (<>
        <nav>
            <div style={{ minHeight: close ? isMobile ? "120px" : "95px" : "65px" }}>
                <div className="NavHedar" style={{ flexDirection: "column" }}>
                    {!CarentItem.offerHedar && (
                        <div
                            className="offer-banner"
                            style={{
                                backgroundColor: ThemeColor.bgColor(1),
                                color: "white",
                                display: "flex",
                                justifyContent: "space-between",
                                padding: 5,
                                minHeight: 20,
                                minWidth: "100%",
                                opacity: isVisible ? 1 : 0,
                                transition: "opacity 0.7s ease-out",
                            }}
                        >
                            <div></div>
                            <div style={{ fontSize: 14, fontWeight: 500, textAlign: isMobile ? "center" : undefined }}>
                                {t("Hedar.Shipping to Palestine and Jordan", { Jordan: (dataOthers?.delivery?.jordan! * baseCurrency).toFixed(0), Inside: (dataOthers?.delivery?.inside! * baseCurrency).toFixed(0), WestBank: (dataOthers?.delivery?.westBank! * baseCurrency).toFixed(0), currency })}
                            </div>
                            <div>
                                <CloseButton
                                    variant="white"
                                    style={{ margin: "0 20px" }}
                                    onClick={handleClose}
                                />
                            </div>
                        </div>
                    )}
                    <div style={{ maxWidth: "1440px", width: "100%", padding: isMobile ? "0 10px" : "0 40px", minHeight: 65, alignContent: "center" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            {
                                isMobile ?
                                    <div style={{ display: "flex", width: "100%" }}>
                                        <div style={{ width: "33.33%" }}>
                                            <Button
                                                onClick={toggleMenu}
                                                style={{
                                                    background: 'none',
                                                    backgroundColor: menuVisible ? "white" : ThemeColor.bgColor(1),
                                                    border: `solid 1px ${ThemeColor.bgColor(1)}`,
                                                    fontSize: '20px',
                                                    color: menuVisible ? ThemeColor.bgColor(1) : "white",
                                                    cursor: 'pointer',
                                                    padding: "2px 10px",
                                                }}
                                            >
                                                <FaBars />
                                            </Button>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "center", width: "33.33%" }}>
                                            <Button bsPrefix="BtnBNBIcon" style={{ border: "none", padding: 0 }} onClick={() => nav(`/guest/homePage`)} >
                                                <img style={{ maxHeight: 40, padding: 5 }} src={img2} />
                                            </Button>
                                        </div>
                                        <div style={{ display: "flex", width: "33.33%", justifyContent: "end", gap: 10 }}>
                                            <Button bsPrefix='BtnBNBIcon' style={{ display: "flex", alignItems: "center", color: "black" }} onClick={() => setShowShoppingCart(true)}>
                                                <div style={{ backgroundColor: ThemeColor.bgColor(1), minWidth: "24px", borderRadius: "50%", color: "white" }}></div>
                                                <MdOutlineShoppingCart className='IconHedar' size={"30px"} />
                                            </Button>
                                            <Button bsPrefix='BtnBNBIcon' onClick={() => setShowSearch(true)}><IoSearch size={"25px"} /></Button>
                                            <Button bsPrefix='BtnBNBIcon' onClick={() => setShowModalLogin(true)}><FaRegUser className='IconHedar' size={"22px"} /></Button>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className='MenuNavLink' style={{ display: "flex", gap: "20px" }}>
                                            {/* <Notification /> */}
                                            <NavLink className={"NavLinkHedar"} to="/guest/homePage">{t("Home.home")}</NavLink>
                                            {dataCategotys && dataCategotys.slice(0, 7).map((Categoty, index) => (
                                                <NavLink className={"NavLinkHedar"} to={`/guest/category/${Categoty.name.en}`} key={index}><Langu {...Categoty.name} /></NavLink>
                                            ))}
                                        </div>
                                    </div>
                            }
                            <div style={{
                                position: 'absolute',
                                top: close ? 120 : '60px',
                                right: '0',
                                backgroundColor: 'white',
                                width: '100%',
                                boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)',
                                opacity: menuVisible ? 1 : 0,
                                display: menuVisible ? 'flex' : "none",
                                flexDirection: 'column',
                                gap: '10px',
                                padding: '10px',
                                transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
                                transform: menuVisible ? 'translateY(0)' : 'translateY(-20px)',
                                zIndex: 61,
                            }}>
                                <NavLink className={"NavLinkHedar"} to="/guest/homePage" style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                    fontWeight: '700',
                                    transition: 'color 0.3s',
                                    padding: "0 0 10px 0",
                                    display: "flex",
                                    gap: 10,
                                    borderBottom: "2px solid #dddddd",
                                }}
                                    onClick={toggleMenu}
                                    onMouseEnter={(e) => e.currentTarget.style.color = '#ff9100'}
                                    onMouseLeave={(e) => e.currentTarget.style.color = 'black'}
                                >
                                    {/* <img draggable={false} style={{ width: 20, height: 20 }} src={img3} /> */}
                                    <p style={{ margin: 0 }}>{t("Home.home")}</p>
                                </NavLink>
                                {dataCategotys && dataCategotys.slice(0, 5).map((catogory, index) => (
                                    <NavLink
                                        key={index}
                                        to={`/guest/category/${catogory.name.en}`}
                                        style={{
                                            textDecoration: 'none',
                                            color: 'black',
                                            fontWeight: '700',
                                            transition: 'color 0.3s',
                                            display: "flex",
                                            padding: "0 0 10px 0",
                                            gap: 10,
                                            borderBottom: "2px solid #dddddd",
                                        }}
                                        onClick={toggleMenu}
                                        onMouseEnter={(e) => e.currentTarget.style.color = ThemeColor.text}
                                        onMouseLeave={(e) => e.currentTarget.style.color = 'black'}
                                    >
                                        {/* <img draggable={false} style={{ width: 20, height: 20 }} src={`${process.env.REACT_APP_URL_API}/API/files/Categorys/${catogory.file ? catogory.file?.[2] : ""}`} /> */}
                                        <Langu {...catogory.name} />
                                    </NavLink>
                                ))}
                                {/* <NavLink style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                    fontWeight: '700',
                                    transition: 'color 0.3s',
                                    display: "flex",
                                    padding: "0 0 10px 0",
                                    gap: 10,
                                    borderBottom: "2px solid #dddddd",
                                }}
                                    onClick={toggleMenu}
                                    to={``} >
                                    <GrFavorite className='IconHedar' size={"25px"} />
                                    {t('Hedar.Favorites')}
                                    <div style={{ backgroundColor: "#842cff", minWidth: "24px", borderRadius: "50%", color: "white", textAlign: "center" }}></div>
                                </NavLink>
                                <NavLink style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                    fontWeight: '700',
                                    transition: 'color 0.3s',
                                    display: "flex",
                                    padding: "0 0 10px 0",
                                    gap: 10,
                                    borderBottom: "2px solid #dddddd",
                                }}
                                    onClick={toggleMenu}
                                    to={``} >
                                    <IoWalletOutline size={20} /> <div>{t("Hedar.Wallet")} :</div> <div style={{ fontSize: 14, alignContent: "center", color: "green" }}>0</div>
                                </NavLink> */}
                                <div>
                                    <SetCurrency />
                                </div>
                                <div>
                                    <I18nGuest />
                                </div>
                            </div>
                            <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
                                {!isMobile &&
                                    <>
                                        <Button bsPrefix='BtnBNBIcon' style={{ display: "flex" }} onClick={() => setShowShoppingCart(true)}>
                                            <div style={{ backgroundColor: "#842cff", minWidth: "24px", borderRadius: "50%", color: "white" }}>0</div>
                                            <MdOutlineShoppingCart className='IconHedar' size={"25px"} />
                                        </Button>
                                        <Button bsPrefix='BtnBNBIcon' style={{ display: "flex", gap: "2px" }} onClick={() => { }}>
                                            <div style={{ backgroundColor: "#842cff", minWidth: "24px", borderRadius: "50%", color: "white", }}>0</div>
                                            <GrFavorite className='IconHedar' size={"25px"} />
                                        </Button>
                                        <Button bsPrefix='BtnBNBIcon' onClick={() => setShowModalLogin(true)}><FaRegUser className='IconHedar' size={"22px"} /></Button>
                                        <Button bsPrefix='BtnBNBIcon' onClick={() => setShowSearch(true)}><IoSearch className='IconHedar' size={"25px"} /> </Button>
                                        <div>
                                            <SetCurrency />
                                        </div>
                                        <div>
                                            <I18nGuest />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LoginModal show={showModalLogin} setShowModalLogin={setShowModalLogin} />
            <Search show={showSearch} setShowSearch={setShowSearch} />
            <ShoppingCart show={showShoppingCart} setShowShoppingCart={setShowShoppingCart} />
        </nav>
    </>)
}

export default HedarGuest;