import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Button } from "react-bootstrap";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import CardProducts from "../CardProductsGuest";
import { Product } from "../../../Interfaces";
import { OrderContext } from "../../../components/context/OrderContext";

interface ProductsCategoryProps {
    category: string
}

const ProductsCategoryGuest: React.FC<ProductsCategoryProps> = ({ category }) => {
    const { t } = useTranslation()
    const nav = useNavigate()
    const ordercontext = useContext(OrderContext)
    const { isMobile } = ordercontext!
    const scrollRef = useRef<HTMLDivElement>(null);
    const isDragging = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);
    const dragThreshold = 5;
    const [dragging, setDragging] = useState(false);
    const [dataProductsCategory, setDataProductsCategory] = useState<Product[]>()
    const [isAtEnd, setIsAtEnd] = useState(false);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProducts/${category}`)
            .then((doc) => { setDataProductsCategory(doc.data) })
            .catch((err) => console.log(err))
    }, [category])

    const onMouseDown = (e: React.MouseEvent) => {
        isDragging.current = true;
        startX.current = e.pageX - (scrollRef.current?.offsetLeft || 0);
        scrollLeft.current = scrollRef.current?.scrollLeft || 0;
        setDragging(false);
    };

    const onMouseLeaveOrUp = (e: React.MouseEvent) => {
        if (isDragging.current) {
            const x = e.pageX - (scrollRef.current?.offsetLeft || 0);
            const distance = Math.abs(x - startX.current);
            if (distance > dragThreshold) {
                setDragging(true);
            }
        }
        isDragging.current = false;
    };

    const onMouseMove = (e: React.MouseEvent) => {
        if (!isDragging.current) return;
        e.preventDefault();
        const x = e.pageX - (scrollRef.current?.offsetLeft || 0);
        const walk = (x - startX.current) * 1;
        if (scrollRef.current) {
            scrollRef.current.scrollLeft = scrollLeft.current - walk;
        }
    };

    const handleScroll = () => {
        if (scrollRef.current) {
            const { scrollWidth, scrollLeft, clientWidth } = scrollRef.current;
            setIsAtEnd((scrollLeft * -1) + clientWidth >= scrollWidth - 1);
        }
    };

    return (<>
        <div style={{ margin: "30px 0 ", width: "100%", padding: isMobile ? 3 : "0 20px" }}>
            <div style={{ display: "flex", width: "100%", padding: "50px 0", justifyContent: "center", marginBottom: "10px" }}>
                {!isMobile && <div style={{ width: "150px" }}></div>}
                <div style={{ textAlign: "center", position: "relative", width: "100%" }}>
                    <h2 style={{ margin: 0 }}>{t("Market.Shop from the same category")}</h2>
                    <div style={{
                        position: "absolute",
                        bottom: "-15px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "8px"
                    }}>
                        <div style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "black",
                            borderRadius: "50%",
                        }}></div>
                        <div style={{
                            width: "50px",
                            height: "4px",
                            backgroundColor: "black",
                            borderRadius: "2px",
                        }}></div>
                        <div style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "black",
                            borderRadius: "50%",
                        }}></div>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Button bsPrefix="BrandShoppBrand" style={{ width: "150px", backgroundColor: "white" }} onClick={() => nav(`/guest/category/${category}`)}>{t("Home.Show all")}</Button>
                </div>
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", padding: isMobile ? "0 10px" : "0 30px" }}>
                <MdKeyboardDoubleArrowRight
                    size={40}
                    color={scrollRef.current?.scrollLeft !== 0 ? "black" : "#dddddd"}
                    onClick={() => {
                        if (scrollRef.current) {
                            scrollRef.current.scrollLeft = 0;
                        }
                    }}
                    style={{ cursor: "pointer" }}
                />
                <MdKeyboardDoubleArrowLeft
                    size={40}
                    color={isAtEnd ? "#dddddd" : "black"}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        if (scrollRef.current) {
                            const scrollLeft = (scrollRef.current?.scrollWidth - scrollRef.current?.clientWidth) * -1
                            scrollRef.current.scrollLeft = scrollLeft;
                        }
                    }}
                />
            </div>
            <div
                ref={scrollRef}
                onMouseDown={onMouseDown}
                onMouseLeave={onMouseLeaveOrUp}
                onMouseUp={onMouseLeaveOrUp}
                onMouseMove={onMouseMove}
                onScroll={handleScroll}
                style={{ display: 'flex', width: "100%", gap: "2%", overflowX: "auto", scrollbarWidth: "none", cursor: "grab" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", gap: isMobile ? "3%" : 20, flexWrap: "nowrap", padding: isMobile ? 0 : "0 20px" }}>
                    {
                        dataProductsCategory?.length! > 0 ? dataProductsCategory?.sort((a, b) => b.sold - a.sold).slice(0, 10).map((Product, index) => (
                            <CardProducts Product={Product} dragging={dragging} key={index} />
                        ))
                            :
                            <div style={{ width: "100%", display: 'flex', justifyContent: "center", minHeight: "50vh", alignItems: "center", fontSize: "22px" }}>
                                {t("Market.There are no products with these specifications.")}
                            </div>
                    }
                </div>
            </div>
        </div>
    </>)
}

export default ProductsCategoryGuest;