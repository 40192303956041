import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { OrderProps } from "../../admin/market/orders/getOrders/GetOrders.admin";
import { Button, Table } from "react-bootstrap";
import Langu from "../../components/languge/languge";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import img from '../../files/HomeGuest/8.png'
import imgMo from '../../files/HomeGuest/8Mo.jpg'
import { LoadingBtn } from "../../components/laoding/laoding";
import { ChatContext } from "../../components/context/ChatContext";
import { OrderContext } from "../../components/context/OrderContext";
import MyOrdersMobile from "./MyOrdersMobile";



const MyOrders: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData)
    const { t } = useTranslation()
    const nav = useNavigate()
    const orderContext = useContext(OrderContext);
    const { isMobile } = orderContext!
    const [dataOrders, setDataOrders] = useState<OrderProps[]>()
    const [statusBtn, setStatusBtn] = useState<any>(t("Market.View more"))
    const [disabledBtn, setDisabled] = useState<boolean>(false)
    const [limit, setLimit] = useState<number>(30)

    useEffect(() => {
        setStatusBtn(<LoadingBtn />)
        setDisabled(true)
        axios.get(`${process.env.REACT_APP_URL_API}/API/Orders/GetOrdersUserId/${userData?.user?.userDetals?._id}/${limit}`, {
            headers: {
                Authorization: "Bearer " + userData.user.token,
            }
        })
            .then((doc) => setDataOrders(doc.data))
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtn(t("Market.View more"))
                setDisabled(false)
            })
    }, [userData?.user?.userDetals?._id, userData.user.token, limit])

    return (<>
        {
            isMobile ? <MyOrdersMobile /> :
                <>
                    <div style={{ background: `url(${img}) no-repeat center center`, backgroundSize: 'cover', minHeight: "20vh", width: '100%' }}>
                        {/* <div className="nameOffer" style={{ top: "calc(10vh + 60px)" }}>{t("Home.ForYouMyLady")}</div> */}
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div style={{ maxWidth: "1440px", width: "100%", padding: isMobile ? 0 : "0 40px" }}>
                            <Table striped style={{ border: "1px solid #dddddd" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{t("Market.product")}</th>
                                        <th>{t("Market.Total price")}</th>
                                        <th>{t("Market.PayPage.Payment methods")}</th>
                                        <th>{t("Market.Timing")}</th>
                                        <th>{t("Market.status")}</th>
                                        <th style={{ width: "15%" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        dataOrders?.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()).map((order, index) =>
                                            <tr key={index} >
                                                <td style={{ alignContent: "center", fontWeight: "500" }}>{index + 1}</td>
                                                <td style={{ alignContent: "center", fontWeight: "500" }}>
                                                    <div >
                                                        {order.dataItems?.map((item, index) =>
                                                            <div key={index}>
                                                                <Langu  {...item.product!} /> × {item.quantity}
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                                <td style={{ alignContent: "center", fontWeight: "500" }}>{(order?.finalPrice?.toFixed(2)) + " " + order.currency}</td>
                                                <td style={{ alignContent: "center", fontWeight: "500" }}>{t(`Market.PayPage.${order.paymentMethod}`)}</td>
                                                <td style={{ alignContent: "center", fontWeight: "500" }}>{new Date(order?.createdAt!).toLocaleString('en-GB', {
                                                    year: 'numeric',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: false
                                                })}</td>
                                                <td style={{ alignContent: "center", fontWeight: "500" }}>{t(`Market.PayPage.${order.status}`)}</td>
                                                <td style={{ width: "15%", alignContent: "center", height: "100%", maxHeight: "100%" }}><Button bsPrefix="BtnBNB" style={{}} onClick={() => nav(`/client/myAccount/orderDetals/${order._id}`)}>{t("Market.Order details")}</Button></td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                            <div style={{ textAlign: "center", margin: "20px 0" }}>
                                <Button bsPrefix="BtnBNB" onClick={() => { setLimit(limit + 20) }} disabled={disabledBtn} >{statusBtn}</Button>
                            </div>
                        </div>
                    </div >
                </>
        }
    </>)
}

export default MyOrders;