import { Form } from "react-bootstrap";
import img1 from '../../files/HedarGuest/images.png'
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { OrderContext } from "../../components/context/OrderContext";





const I18nGuest: React.FC = () => {
    const { i18n } = useTranslation()
    const ordercontext = useContext(OrderContext)
    const { isMobile } = ordercontext!

    useEffect(() => {
        document.body.dir = i18n.dir()
    }, [])

    const BtnLanguge = (lan: string | undefined) => {
        i18n.changeLanguage(lan);
        window.location.reload();
    }
    return (<>
        <Form.Select style={{
            paddingLeft: isMobile ? 20 : "40px",
            paddingRight: "20px",
            width: "100%",
            height: "35px",
            appearance: "none",
            background: `url(${img1}) no-repeat 10px center`,
            backgroundSize: "30px 30px, 100%",
            backgroundColor: "#ffffff",
            // backgroundImage: `url(${img1}) no-repeat 10px center`,
            textAlign: "left",
            WebkitAppearance: "none",
            MozAppearance: "none",
            cursor: "pointer"
        }} className="language-selector" onChange={(e) => BtnLanguge(e.target.value)} value={i18n.language}>
            <option style={{ textAlign: "center" }} value={"ar"}>AR</option>
            <option style={{ textAlign: "center" }} value={"en"}>EN</option>
            {/* <option value={"he"}>HE</option> */}
        </Form.Select>
    </>)
}

export default I18nGuest;