import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { UsersProps, WalletProps } from "../../Interfaces";
import { ChatContext } from "../../components/context/ChatContext";
import { Alert, Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { OrderProps } from "../../admin/market/orders/getOrders/GetOrders.admin";
import { useNavigate } from "react-router";



const Wallet = () => {
    const userData = useSelector((state: RootState) => state.userData)
    const { t } = useTranslation()
    const nav = useNavigate()
    const chatContext = useContext(ChatContext)
    const { baseCurrency, currency } = chatContext!
    const [dataWallet, setDataWallet] = useState<WalletProps[]>()
    const [newDataUser, setNewDataUser] = useState<UsersProps>()
    const [showAlirtMsg, setShowAlirtMsg] = useState<boolean>(false)
    const [dataOrders, setDataOrders] = useState<OrderProps[]>()
    const [statusBtn, setStatusBtn] = useState<any>(t("Market.View more"))
    const [disabledBtn, setDisabled] = useState<boolean>(false)
    const [limit, setLimit] = useState<number>(30)
    const [limitWallet, setLimitWallet] = useState<number>(30)

    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${userData.user.userDetals?._id}`, {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + userData?.user?.token,
            }
        })
            .then((doc) => { setNewDataUser(doc.data); })
            .catch((err) => console.log(err))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Wallets/GetWalletId/${userData.user.userDetals?.invitationName}`, {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + userData?.user?.token,
            }
        })
            .then((doc) => setDataWallet(doc.data))
            .catch((err) => console.log(err))

    }, [limitWallet])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Orders/GetOrdersUserIdWallet/${userData?.user?.userDetals?._id}/${limit}`, {
            headers: {
                Authorization: "Bearer " + userData.user.token,
            }
        })
            .then((doc) => setDataOrders(doc.data))
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtn(t("Market.View more"))
                setDisabled(false)
            })
    }, [limit])

    useEffect(() => {
        if (showAlirtMsg) {
            const timer = setTimeout(() => {
                setShowAlirtMsg(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [showAlirtMsg]);

    const finelTotall2 = (order: OrderProps) => {
        return order?.dataItems?.reduce((total: number, cartitem: any) => {
            const totall = total +
                (((cartitem.newPrice || cartitem.price || 0) * cartitem.quantity!) -
                    ((cartitem.newPrice || cartitem.price || 0) -
                        (((cartitem.offerForTwoProducts && cartitem.quantity! > 1) ? cartitem.priceOfferForTwoProducts! : (cartitem.newPrice || cartitem.price || 0)))));
            return totall + order.delivery
        }, 0) ?? 0
    }

    const TotalWallet: number = dataWallet?.reduce((total, wallet) => {
        const totall = total + wallet.BonusAmount
        return totall
    }, 0)!

    return (<>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <div style={{ fontSize: 30, fontWeight: 700, textAlign: "center", margin: "30px 0" }}>
                جزدانك في TREAT ME , ممتلئ بالمفاجآت!
            </div>
            <div style={{ maxWidth: 1660, width: "100%", margin: "30px 0", display: "flex", gap: 20 }}>
                <div style={{ width: "33.33%", maxWidth: 400, display: "flex", flexDirection: "column", gap: 30 }}>
                    <div style={{ backgroundColor: "#e7dddc", borderRadius: 20, padding: 30 }}>
                        <div style={{ fontSize: 35, fontWeight: 900, textAlign: "center" }}>
                            جزدانك
                        </div>
                        <div style={{ fontSize: 18, fontWeight: 500, textAlign: "center" }}>
                            رصيدك الحالي
                        </div>
                        <div style={{ fontSize: 35, fontWeight: 900, color: "red", textAlign: "center" }}>
                            {((newDataUser?.pointsInvite ?? 0) * baseCurrency).toFixed(2)} {currency}
                        </div>
                    </div>
                    <div style={{ backgroundColor: "#e7dddc", borderRadius: 20, padding: 30 }}>
                        <div style={{ fontSize: 35, fontWeight: 900, textAlign: "center" }}>
                            وفرت حتى الآن!!
                        </div>
                        <div style={{ fontSize: 35, fontWeight: 900, color: "red", textAlign: "center" }}>
                            {TotalWallet?.toFixed(2)} {currency}
                        </div>
                    </div>
                    <Button title={`${t("Hedar.Click to copy")}`} style={{ backgroundColor: "initial", border: "none", color: "black" }} onClick={() => {
                        const textToCopy = userData.user.userDetals?.invitationName;
                        if (textToCopy) {
                            navigator.clipboard
                                .writeText(textToCopy)
                                .then(() => setShowAlirtMsg(true))
                        } else {
                            alert("لا توجد قيمة للنسخ!");
                        }
                    }}>
                        <div style={{ backgroundColor: "#e7dddc", borderRadius: 20, padding: 30, display: "flex", flexDirection: "column", gap: 10 }}>
                            <div style={{ fontSize: 35, fontWeight: 900, textAlign: "center" }}>
                                خصمك بلمسة واحدة!
                            </div>
                            <div style={{ fontSize: 18, fontWeight: 500, textAlign: "center" }}>
                                انقر على الكود للنسخ
                            </div>
                            <div style={{ fontSize: 18, fontWeight: 500, textAlign: "center", padding: 30, backgroundColor: "white", borderRadius: 10, boxShadow: "10px 10px 10px rgb(121, 121, 121)" }}>
                                {newDataUser?.invitationName}
                            </div>
                        </div>
                    </Button>
                </div>
                <div style={{ backgroundColor: "#e7dddc", borderRadius: 20, padding: 30, display: "flex", flexDirection: "column", gap: 10, width: "33.33%", }}>
                    <div style={{ fontSize: 35, fontWeight: 900, textAlign: "center" }}>
                        ايداعات إلى محفظتك
                    </div>
                    <div>
                        <table style={{ width: "100%" }}>
                            <thead style={{ backgroundColor: "e7dddc", width: "100%", borderBottom: "solid 1px" }}>
                                <tr>
                                    <th style={{ textAlign: "start", width: "33%" }}>المصدر</th>
                                    <th style={{ textAlign: "center", width: "33%" }}>التاريخ</th>
                                    <th style={{ textAlign: "end", width: "33%" }}>المبلغ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataWallet?.map((wallet, index) => (
                                        <tr key={index} style={{ borderBottom: "1px solid rgb(196, 196, 196)" }}>
                                            <th style={{ textAlign: "start", width: "33%", padding: 10 }}>{t(`Market.${wallet.Source}`)}</th>
                                            <th style={{ textAlign: "center", width: "33%" }}>{new Date(wallet.createdAt).toLocaleDateString("en-GB")}</th>
                                            <th style={{ textAlign: "end", width: "33%", color: "red" }}>{(wallet.totalPrice * wallet.invitationBonus / 100).toFixed(2)} {currency}</th>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>

                    </div>
                </div>
                <div style={{ backgroundColor: "#e7dddc", borderRadius: 20, padding: 30, display: "flex", flexDirection: "column", gap: 10, width: "33.33%", }}>
                    <div style={{ fontSize: 35, fontWeight: 900, textAlign: "center" }}>
                        نفقاتك
                    </div>
                    <div>
                        <table style={{ width: "100%" }}>
                            <thead style={{ backgroundColor: "e7dddc", width: "100%", borderBottom: "solid 1px" }}>
                                <tr>
                                    <th style={{ textAlign: "start", width: "33%" }}>الطلب</th>
                                    <th style={{ textAlign: "center", width: "33%" }}>المبلغ الكلي</th>
                                    <th style={{ textAlign: "end", width: "33%" }}>المبلغ المخفف</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataOrders?.map((order, index) => (
                                        <tr key={index} style={{ cursor: "pointer", borderBottom: "1px solid rgb(196, 196, 196)" }} onClick={() => { nav(`/client/myAccount/orderDetals/${order._id}`) }}>
                                            <th style={{ textAlign: "start", width: "33%", padding: 10 }}>{order._id}</th>
                                            <th style={{ textAlign: "center", width: "33%" }}>{finelTotall2(order).toFixed(2)}</th>
                                            <th style={{ textAlign: "end", width: "33%" }}>{(finelTotall2(order) - order.valueWallet.currency).toFixed(2)}</th>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <Alert
            show={showAlirtMsg}
            variant={"success"}
            style={{ position: "fixed", top: '10px', left: "30px", minWidth: "20%", zIndex: "10" }}
            onClose={() => setShowAlirtMsg(false)} dismissible
        >
            <Alert.Heading style={{ textAlign: "center" }}>
                {t("Hedar.Copied successfully")}
            </Alert.Heading>
        </Alert>
    </>)
}

export default Wallet;