import { useContext, useEffect } from "react"
import { ChatContext } from "../context/ChatContext";
import { Stack } from "react-bootstrap";
import UserChat from "./ChatUser";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import ChatBox from "./ChatBox";


const Chat = () => {

    const userData = useSelector((state: RootState) => state.userData);
    const chatContext = useContext(ChatContext);

    const { userChats, isUserChatsLoading, UpDateCurrentChat } = chatContext!;

    useEffect(() => {
        return () => {
            if (UpDateCurrentChat) {
                UpDateCurrentChat([]);
            }
        };
    }, [UpDateCurrentChat]);
    return <>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ maxWidth: "1440px", width: "100%" }}>
                {/* <PotentialChats /> */}
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    {
                        userChats && userChats?.length < 1 ? null : (
                            <Stack style={{ display: "flex", flexDirection: "row", maxWidth: "1440px", margin: "30px", border: "1px solid #dddddd", minHeight: "70vh" }}>
                                <Stack style={{ width: "30%", maxWidth: "400px", border: "1px solid #dddddd" }}>
                                    {isUserChatsLoading && <p>Loading chats...</p>}
                                    {
                                        userChats?.map((chat, index) => {
                                            return (
                                                <div key={index} onClick={() => UpDateCurrentChat && UpDateCurrentChat(chat)} style={{ cursor: "pointer" }}>
                                                    <UserChat chat={chat!} user={userData?.user?.userDetals!} />
                                                </div>
                                            )
                                        })
                                    }
                                </Stack>
                                <ChatBox />
                            </Stack>
                        )
                    }
                </div>
            </div>
        </div>
    </>
}


export default Chat;