import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LoadingBtn } from "../../components/laoding/laoding";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";


interface ChangeEmailModalProps {
    show: boolean;
    setShowModalChangeEmail: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModaleChangeEmail: React.FC<ChangeEmailModalProps> = ({ show, setShowModalChangeEmail }) => {
    const { t } = useTranslation()
    const userData = useSelector((state: RootState) => state.userData);

    const [email, setEmail] = useState<string>('')
    const [errmsgLogin, setErrMsgLogin] = useState<string>('')
    const [statusBtn, setStatusBtn] = useState<any>(t("Home.Account.Change Email"))
    const [disabledBtn, setDisabledBtn] = useState<boolean>(false)

    const btnChangeEmail = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLInputElement>) => {
        setDisabledBtn(true)
        setStatusBtn(<LoadingBtn />)
        await axios.post(`${process.env.REACT_APP_URL_API}/API/Auth/SendVerifyChangeEmail/${userData.user.userDetals?._id}`, { email },
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .catch(err => { setErrMsgLogin(err.response?.data?.errors?.[0].msg); console.log(err) })
        setStatusBtn(t("Home.Account.Change Email"))
        setDisabledBtn(false)
    }

    useEffect(() => {
        setErrMsgLogin('')
    }, [email])

    const handleKeyPressLogin = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            btnChangeEmail(event);
        }
    };

    return (<>
        <Modal show={show} onHide={() => setShowModalChangeEmail(false)}>
            <Modal.Header className="CloseBtn" closeButton>
                <Modal.Title>{t("Home.Account.Change Email")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>{t("Hedar.Email address")} :</Form.Label>
                    <Form.Control onKeyDown={handleKeyPressLogin} type="email" placeholder="name@example.com" autoFocus onChange={e => setEmail(e.target.value)} />
                </Form.Group>
                {
                    errmsgLogin ?
                        <div style={{ color: "red", width: "100%", textAlign: "center", padding: "5px", backgroundColor: "#ffe9e9", borderRadius: "5px" }}>
                            {errmsgLogin}
                        </div>
                        :
                        null
                }
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={btnChangeEmail} disabled={disabledBtn} bsPrefix="BtnBNB">
                    {statusBtn}
                </Button>
                <Button variant="secondary" onClick={() => setShowModalChangeEmail(false)}>
                    {t("Hedar.Close")}
                </Button>
            </Modal.Footer>
        </Modal>
    </>)
}

export default ModaleChangeEmail;