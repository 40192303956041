import { useNavigate, useParams } from 'react-router';
import '../../homeClient/Home.client.css'
import { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Langu from '../../../components/languge/languge';
import ClientCardPeoduct from '../../components/CardsProduct/ClientCardPeoduct';
import { Button, Form } from 'react-bootstrap';
import ClientYouMayAlsoLike from '../../components/CardsProduct/ClientYouMayAlsoLike';
import { Brand, Category, ProductSearch } from '../../../Interfaces';
import { ChatContext } from '../../../components/context/ChatContext';
import { Loading, LoadingBtn } from '../../../components/laoding/laoding';
import { OrderContext } from '../../../components/context/OrderContext';

const CategoryClient: React.FC = () => {
    const param = useParams()
    const categoryName = param.name
    const nav = useNavigate()
    const ordercontext = useContext(OrderContext)
    const { isMobile } = ordercontext!
    const [dataCategorys, setDataCategorys] = useState<Category[]>();
    const { t } = useTranslation()
    const chatContext = useContext(ChatContext)
    const { currency, baseCurrency } = chatContext!
    // const [price, setPrice] = useState<string>("All")
    const [dataProducts, setDataProducts] = useState<ProductSearch>()
    const [dataBrands, setDataBrands] = useState<Brand[]>()
    const [checkBrand, setCheckBrand] = useState<string[]>([])
    // const [checkTarget, setCheckTarget] = useState<string>("Alll")
    const [dragging, setDragging] = useState(false);
    const [minPrice, setMinPrice] = useState<number>(0)
    const [maxPrice, setMaxPrice] = useState<number>(1000)
    const [limit, setLimit] = useState<number>(30)
    const [statusBtnViewMore, setStatusBtnViewMore] = useState<any>(t("Market.View more"))
    const [disabledBtnViewMore, setDisabledViewMore] = useState<boolean>(false)
    const scrollRef = useRef<HTMLDivElement>(null);
    const isDragging = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);
    const dragThreshold = 5;

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Brands/GetBrands`)
            .then((doc) => setDataBrands(doc.data))
            .catch((err) => console.log(err))
    }, [])

    useEffect(() => { window.scrollTo(0, 0); }, [param])
    const dataSearch = { minPrice: minPrice / baseCurrency, maxPrice: maxPrice / baseCurrency, brand: checkBrand }

    const dataCategory: Category | undefined = dataCategorys?.find((i) => i.name.en === categoryName)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategorys`)
            .then((doc) => setDataCategorys(doc.data))
            .catch((err) => console.log(err))
    }, [categoryName])

    useEffect(() => {

        const getProducts = async () => {
            await axios.put(`${process.env.REACT_APP_URL_API}/API/Products/PutSearchProductsCategory/${categoryName}/${limit}`, dataSearch)
                .then((doc) => setDataProducts(doc.data))
                .catch((err) => console.log(err))
                .finally(() => {
                    setStatusBtnViewMore(t("Market.View more"))
                    setDisabledViewMore(false)
                })
        }
        const debounce = setTimeout(() => {
            getProducts()
        }, 800)
        return () => clearTimeout(debounce)
    }, [checkBrand, minPrice, maxPrice, baseCurrency, categoryName, limit])

    // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setPrice(e.target.value);
    // };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;
        if (checked) {
            setCheckBrand([...checkBrand!, value]);
        } else {
            setCheckBrand(checkBrand!.filter((brand) => brand !== value));
        }
    };
    const onMouseDown = (e: React.MouseEvent) => {
        isDragging.current = true;
        startX.current = e.pageX - (scrollRef.current?.offsetLeft || 0);
        scrollLeft.current = scrollRef.current?.scrollLeft || 0;
        setDragging(true);
    };

    const onMouseLeaveOrUp = (e: React.MouseEvent) => {
        if (isDragging.current) {
            const x = e.pageX - (scrollRef.current?.offsetLeft || 0);
            const distance = Math.abs(x - startX.current);
            if (distance < dragThreshold) {
                setDragging(false);
            }
        }
        isDragging.current = false;
    };

    const onMouseMove = (e: React.MouseEvent) => {
        if (!isDragging.current) return;
        e.preventDefault();
        const x = e.pageX - (scrollRef.current?.offsetLeft || 0);
        const walk = (x - startX.current) * 1;
        if (scrollRef.current) {
            scrollRef.current.scrollLeft = scrollLeft.current - walk;
        }
    };
    // const handleCheckboxChangeTarget = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setCheckTarget(e.target.value);
    // };

    const handleMinPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (parseFloat(e.target.value) <= maxPrice - 10) {
            setMinPrice(parseFloat(e.target.value))
        }
    }

    const handleMaxPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (parseFloat(e.target.value) >= minPrice + 10) {
            setMaxPrice(parseFloat(e.target.value))
        }
    }

    return (<>
        <div>
            <div style={{
                backgroundImage: dataCategory && `url(${process.env.REACT_APP_URL_API}/API/files/Categorys/${dataCategory?.file?.[0]})`,
                minHeight: "20vh",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                backgroundBlendMode: "darken",
            }}>
                <div className="nameOffer" style={{ top: "calc(10vh + 60px)" }}><Langu {...dataCategory?.name!} /></div>
            </div>
            <div ref={scrollRef}
                onMouseDown={onMouseDown}
                onMouseLeave={onMouseLeaveOrUp}
                onMouseUp={onMouseLeaveOrUp}
                onMouseMove={onMouseMove}
                style={{ margin: "10px 0", display: "flex", overflowX: "auto", scrollbarWidth: "none", cursor: "grab", borderBottom: "1px solid #dddddd" }}>
                {
                    dataCategorys?.map((Category, index) => (
                        <div onClick={() => nav(`/client/market/category/${Category.name.en}`, { state: Category })} style={{ textDecoration: "none", color: "initial" }} key={index}>
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", margin: "0 10px", cursor: "pointer" }}>
                                <img draggable={false} style={{ width: isMobile ? "100px" : "200px", height: isMobile ? "100px" : "200px", borderRadius: "50%" }} src={`${process.env.REACT_APP_URL_API}/API/files/Categorys/${Category.file ? Category.file[0] : ""}`} />
                                <div style={{ fontWeight: "500", margin: "10 0" }}><Langu {...Category.name} /></div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                {!isMobile && <div style={{ width: "20%", height: "100%", maxWidth: "250px", border: "1px solid #dddddd", margin: "50px 20px 50px 0", padding: "10px" }}>
                    <div style={{ minHeight: '30vh' }}>
                        <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Home.You may also like")}</div>
                        <div>
                            <ClientYouMayAlsoLike />
                        </div>
                    </div>
                    <div style={{ minHeight: '10vh' }}>
                        <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Market.price")}</div>
                        <div>
                            {/* <Form.Group className="mb-3" controlId="exampleForm.newPrice">
                                <div className="radio-container" style={{ borderBottom: "1px solid #dddddd", paddingBottom: "10px", display: "flex", gap: "30px", flexDirection: "column" }}>
                                    {["All", "0-49", "50-99", "100-299"].map((pricee, index) => (
                                        <label key={index} className="radio-label" htmlFor={`${pricee}`} style={{ display: "flex", gap: "5px" }}>
                                            <input
                                                type="radio"
                                                id={`${pricee}`}
                                                name="priceFiltar"
                                                value={`${pricee}`}
                                                checked={price === `${pricee}`}
                                                onChange={handleChange}
                                            />
                                            {`${pricee}`}
                                            <span className="radio-custom"></span>
                                        </label>
                                    ))}
                                    <label className="radio-label" htmlFor="300" style={{ display: "flex", gap: "5px" }}>
                                        <input
                                            type="radio"
                                            id="300"
                                            name="priceFiltar"
                                            value="300"
                                            checked={price === "300"}
                                            onChange={handleChange}
                                        />
                                        {t("Market.more than 300")}
                                        <span className="radio-custom"></span>
                                    </label>
                                </div>
                            </Form.Group> */}
                            <div style={{ margin: "20px auto" }}>
                                <label htmlFor="minPrice">السعر الأدنى: <span>{minPrice}</span> {currency}</label>
                                <input style={{ width: "100%" }} type="range" id="minPrice" min="0" max="1000" onChange={handleMinPrice} value={minPrice} step="10" />

                                <label htmlFor="maxPrice">السعر الأقصى: <span>{maxPrice}</span> {currency}</label>
                                <input style={{ width: "100%" }} type="range" id="maxPrice" min="0" max="1000" onChange={handleMaxPrice} value={maxPrice} step="10" />
                            </div>
                        </div>
                    </div>
                    <div style={{ minHeight: '20vh' }}>
                        <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Market.brand")}</div>
                        <div>
                            <Form style={{ display: "flex", flexDirection: 'column', gap: '10px' }}>
                                {
                                    dataBrands?.map((brand, index) => (
                                        <Form.Check
                                            key={index}
                                            type="checkbox"
                                            id={brand.name.en}
                                            label={<Langu {...brand.name} />}
                                            value={brand.name.en}
                                            onChange={handleCheckboxChange}
                                        />
                                    ))
                                }
                            </Form>
                        </div>
                    </div>
                    {/* <div style={{ minHeight: '20vh' }}>
                        <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Market.target")}</div>
                        <div className="radio-container" style={{ paddingBottom: "10px", display: "flex", gap: "20px", flexDirection: "column" }}>
                            {["Alll", "Men's", "Women's"].map((target) => (
                                <label key={`target-${target}`} className="radio-label" htmlFor={`${target}`} style={{ display: "flex", gap: "5px" }}>
                                    <input
                                        type="radio"
                                        id={`${target}`}
                                        name="target"
                                        value={`${target}`}
                                        checked={checkTarget === `${target}`}
                                        onChange={handleCheckboxChangeTarget}
                                    />
                                    {t(`Market.${target}`)}
                                    <span className="radio-custom"></span>
                                </label>
                            ))}
                        </div>
                    </div> */}
                </div>}
                <div style={{ display: "flex", flexDirection: "column", width: "100%", maxWidth: 1440 }}>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: isMobile ? "3%" : 20, flexWrap: "wrap", justifyContent: "center", alignContent: "flex-start" }}>
                        {dataProducts ?
                            dataProducts?.movies?.length! > 0 ? dataProducts?.movies?.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()).slice(0, 10).map((Product, index) => (
                                <ClientCardPeoduct Product={Product} dragging={dragging} key={index} />
                            ))
                                :
                                <div style={{ width: "100%", display: 'flex', justifyContent: "center", minHeight: "50vh", alignItems: "center", fontSize: "22px" }}>
                                    {t("Market.There are no products with these specifications.")}
                                </div>
                            :
                            <Loading />
                        }
                    </div>  
                    <div style={{ margin: 30, textAlign: "center", marginTop:  50  }}>
                        <Button disabled={disabledBtnViewMore} onClick={() => { setLimit(limit + 20); setStatusBtnViewMore(<LoadingBtn />); setDisabledViewMore(true) }} bsPrefix="BtnBNB">{statusBtnViewMore}</Button>
                    </div>
                </div>
            </div >
        </div>
    </>)
}

export default CategoryClient;