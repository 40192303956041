import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { UsersProps } from "../../Interfaces";
import { OrderContext } from "../../components/context/OrderContext";


const UserId: React.FC = () => {
    const { t } = useTranslation()
    const orderContext = useContext(OrderContext);
    const { isMobile } = orderContext!
    const userData = useSelector((state: RootState) => state.userData)
    let params = useParams()
    const [dataUser, setDataUser] = useState<UsersProps>()
    const [refresh, setRefresh] = useState<boolean>(false)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${params.id}`,
            {
                headers: {
                    Authorization: "Bearer " + userData?.user?.token,
                }
            }
        )
            .then((doc) => setDataUser(doc.data))
            .catch((err) => console.log(err))
    }, [refresh])

    const btnActiveUser = async () => {
        if (dataUser?.active) {
            await axios.patch(`${process.env.REACT_APP_URL_API}/API/Users/PatchUser/${params.id}`, { active: false },
                {
                    headers: {
                        Authorization: "Bearer " + userData?.user?.token,
                    }
                }
            )
                .then(() => setRefresh(!refresh))
        } else {
            await axios.patch(`${process.env.REACT_APP_URL_API}/API/Users/PatchUser/${params.id}`, { active: true },
                {
                    headers: {
                        Authorization: "Bearer " + userData?.user?.token,
                    }
                }
            )
                .then(() => setRefresh(!refresh))
        }
    }

    const btnRoleUser = async () => {
        if (dataUser?.role) {
            await axios.patch(`${process.env.REACT_APP_URL_API}/API/Users/PatchUser/${params.id}`, { role: false },
                {
                    headers: {
                        Authorization: "Bearer " + userData?.user?.token,
                    }
                }
            )
                .then(() => setRefresh(!refresh))
        } else {
            await axios.patch(`${process.env.REACT_APP_URL_API}/API/Users/PatchUser/${params.id}`, { role: true },
                {
                    headers: {
                        Authorization: "Bearer " + userData?.user?.token,
                    }
                }
            )
                .then(() => setRefresh(!refresh))
        }
    }

    return (<>
        <div style={{ backgroundColor: "gray", minHeight: "20vh", width: '100%' }}>
            <div className="nameOffer" style={{ top: "calc(10vh + 60px)" }}>{t("Hedar.AccountDetals")}</div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", fontSize: "18px", }}>
            <div style={{ maxWidth: "1040px", width: "100%", padding: "20px", border: "1px solid #dddddd", borderRadius: "20px", margin: isMobile ? "40px 20px " : "50px", display: 'flex', flexDirection: 'column', gap: "5px" }}>
                <div>
                    {t("Home.userId")} : {dataUser?._id}
                </div>
                <div>
                    {t("Home.name")} : {dataUser?.userName}
                </div>
                <div>
                    {t("Home.email")} : {dataUser?.email}
                </div>
                <div>
                    {t("Home.phone")}: {dataUser?.phone}
                </div>
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                    <div>
                        {t("Home.active")} : {dataUser?.active?.toString()}
                    </div>
                    <div style={{ marginLeft: " 50%" }}>
                        <Button bsPrefix="BrandShoppBrand" onClick={btnActiveUser} style={{ backgroundColor: "white", fontSize: "16px", color: 'blue' }}>
                            {t("Home.Account.change")}
                        </Button>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                    <div>
                        {t("Home.role")} : {dataUser?.role?.toString()}
                    </div>
                    <div style={{ marginLeft: " 50%" }}>
                        <Button bsPrefix="BrandShoppBrand" onClick={btnRoleUser} style={{ backgroundColor: "white", fontSize: "16px", color: 'blue' }}>
                            {t("Home.Account.change")}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default UserId;