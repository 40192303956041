import { useContext, useEffect, useState } from "react";
import { Alert, Dropdown, DropdownButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaRegUser } from "react-icons/fa";
import './Hedar.client.css'
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/userSlice";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import { RootState } from "../../redux/store";
import { ChatContext } from "../../components/context/ChatContext";
import { MdAccountCircle } from "react-icons/md";
import { IoWalletOutline } from "react-icons/io5";
import { CiViewList } from "react-icons/ci";
import { LuUserCog } from "react-icons/lu";
import { TbLogout } from "react-icons/tb";
import { FaRegCopy } from "react-icons/fa6";


const AccuntDropdownClient: React.FC = () => {
    const cookie = new Cookies()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const nav = useNavigate()
    const userData = useSelector((state: RootState) => state.userData)
    const context = useContext(ChatContext)
    const { baseCurrency, currency } = context!
    const [accuntHover, setAccuntHover] = useState<boolean>(false)
    const [showAlirtMsg, setShowAlirtMsg] = useState<boolean>(false)

    const LogoutBtn = async () => {
        await axios.get(`${process.env.REACT_APP_URL_API}/API/Auth/Logout`, {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + userData.user.token,
            }
        })
            .then(() => {
                dispatch(setUser({ token: undefined, refreshToken: undefined, userDetals: undefined }))
                cookie.remove("bearer", { path: "/" })
                nav('/guest/homePage')
            })
            .catch((err) => console.log(err))
    }

    const finshNumber = (num: number) => {
        const Currency = currency === "ILS" ? "ILS" : currency === "USD" ? "USD" : "JOD";
        return (Number(num) * baseCurrency).toFixed(2) + " " + Currency;
    };

    useEffect(() => {
        if (showAlirtMsg) {
            const timer = setTimeout(() => {
                setShowAlirtMsg(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [showAlirtMsg]);

    return (<>
        <div
            style={{ minHeight: "60px", display: "flex", alignItems: "center" }}
            onMouseEnter={() => setAccuntHover(true)}
            onClick={() => setAccuntHover(true)}
            onMouseLeave={() => setTimeout(() => {
                setAccuntHover(false)
            }, 200)}
        >
            <DropdownButton
                show={accuntHover}
                size="sm"
                bsPrefix={"AccuntDropdownButton"}
                // variant="light"
                title={<FaRegUser size={"25px"} />}
            >
                <div style={{ textAlign: "start", padding: '0 10px', display: "flex", justifyContent: "space-between", alignItems: "center", fontWeight: 600 }}>
                    <div>
                        {t("Hedar.Welcome")} {userData.user.userDetals?.userName}
                    </div>
                    <div style={{ height: "40px", width: "40px", borderRadius: "50%" }}>
                        <MdAccountCircle style={{ width: 45, height: 45 }} />
                    </div>
                </div>
                <Dropdown.Divider />
                <Dropdown.Item title={`${t("Hedar.Click to copy")}`} style={{ textAlign: "start", fontWeight: 600 }} eventKey="1" onClick={() => {
                    const textToCopy = userData.user.userDetals?.invitationName;
                    if (textToCopy) {
                        navigator.clipboard
                            .writeText(textToCopy)
                            .then(() => setShowAlirtMsg(true))
                    } else {
                        alert("لا توجد قيمة للنسخ!");
                    }
                }}><FaRegCopy size={20} /> {t("Hedar.invitationName")} : {userData.user.userDetals?.invitationName}</Dropdown.Item>
                <Dropdown.Item style={{ textAlign: "start", fontWeight: 600 }} onClick={() => nav('/client/myAccount/myOrders')} eventKey="2"><CiViewList size={20} /> {t("Hedar.my Orders")}</Dropdown.Item>
                <Dropdown.Item style={{ textAlign: "start", fontWeight: 600 }} onClick={() => nav(`/client/myAccount/AccountDetals`)} eventKey="3"><LuUserCog size={20} /> {t("Hedar.AccountDetals")}</Dropdown.Item>
                <Dropdown.Item style={{ display: "flex", gap: 5, fontWeight: 600, alignItems: "center" }} onClick={() => nav(`/client/myAccount/Wallet/${userData?.user?.userDetals?._id}`)} eventKey="4"><IoWalletOutline size={20} />  <div>{t("Hedar.Wallet")} :</div> <div style={{ fontSize: 14, alignContent: "center", color: "green" }}>{finshNumber(userData.user.userDetals?.pointsInvite ?? 0)}</div> </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={LogoutBtn} style={{ color: 'red', textAlign: "start", fontWeight: 600 }} eventKey="4"><TbLogout size={20} /> {t("Hedar.Logout")}</Dropdown.Item>
            </DropdownButton>
        </div>
        <Alert
            show={showAlirtMsg}
            variant={"success"}
            style={{ position: "fixed", top: '10px', left: "30px", minWidth: "20%", zIndex: "10" }}
            onClose={() => setShowAlirtMsg(false)} dismissible
        >
            <Alert.Heading style={{ textAlign: "center" }}>
                {t("Hedar.Copied successfully")}
            </Alert.Heading>
        </Alert>
    </>)
}

export default AccuntDropdownClient;