import { Accordion, Alert, Button, Form } from "react-bootstrap";
import Langu from "../../../components/languge/languge";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { decrementItems, incrementItems } from "../../../redux/counterSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { FaPlus, FaShareAlt } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import axios from "axios";
import { setFavorites } from "../../../redux/userSlice";
import { FaRegHeart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { ChatContext } from "../../../components/context/ChatContext";
import { useParams } from "react-router";
import CompleteInformation from "./CompleteInformation";
import { Product, UsersProps } from "../../../Interfaces";
import { OrderContext } from "../../../components/context/OrderContext";

interface InfoProductClientProps {
    data: Product
}

const InfoProductClient: React.FC<InfoProductClientProps> = ({ data }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const params = useParams()
    const chatContext = useContext(ChatContext);
    const { baseCurrency, currency } = chatContext!;
    const CarentItem = useSelector((state: RootState) => state.counterData);
    const userData = useSelector((state: RootState) => state.userData);
    const [dataUser, setDataUser] = useState<UsersProps>();
    const [dataProduct, setDataProduct] = useState<Product>();
    const [colorActive, setColorActive] = useState<string>(data?.colors?.[0]!);
    const [quantityItem, setQuantityItem] = useState(0);
    const [favorite, setFavorite] = useState<boolean>(dataUser?.favorite?.includes(data._id!)!);
    const [showAlertAdd, setShowAlertAdd] = useState<boolean>(false);
    const [showAlertDis, setShowAlertDis] = useState<boolean>(false);
    const [remind, setRemind] = useState<boolean>(data.remind?.includes(userData.user.userDetals?._id!)!);
    const [disableBtnRemind, setDisableBtnRemind] = useState<boolean>(false)
    const [comments, setComments] = useState<string>()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProduct/${params.id}`)
            .then((doc) => { setDataProduct(doc.data) })
            .catch((err) => console.log(err))
    }, [disableBtnRemind, params.id])

    useEffect(() => {
        setTimeout(() => {
            setColorActive(data?.colors?.[0]!)
            setFavorite(dataUser?.favorite?.includes(data?._id!)!)
        }, 500)
    }, [dataUser, data?._id, data?.colors])

    useEffect(() => {
        const item = CarentItem.cartItems.find((i) => ((i.data._id === dataProduct?._id) && (i.color === colorActive)));
        if (item) {
            setComments(item.comments)
            setQuantityItem(item.quantity);
        } else {
            setQuantityItem(0);
            setComments('')
        }
    }, [CarentItem, dataProduct, colorActive, data])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${userData.user.userDetals?._id}`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => { setDataUser(doc.data) })
            .catch((err) => console.log(err))
    }, [showAlertAdd, showAlertDis, userData.user.token, userData.user.userDetals?._id])

    const setShowAlertAd = () => {
        setShowAlertAdd(false)
        setShowAlertDis(true)
    }
    const setShowAlertDi = () => {
        setShowAlertDis(false)
        setShowAlertAdd(true)
    }

    const AddFavorite = async () => {
        await axios.put(`${process.env.REACT_APP_URL_API}/API/Users/PutAddFavorite/${userData.user.userDetals?._id}`, { FavoriteId: data._id, favorite },
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then(() => {
                favorite === true ? setShowAlertAd() : setShowAlertDi()
                dispatch(setFavorites(data?._id!))
            })
            .catch((err) => console.log(err))
    }

    const BtnRemind = async () => {
        setDisableBtnRemind(true)
        await axios.put(`${process.env.REACT_APP_URL_API}/API/Products/PutProductRemind/${data?._id!}`, { userId: userData.user.userDetals?._id },
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .catch((err) => console.log(err))
            .finally(() => setDisableBtnRemind(false))
    }

    useEffect(() => {
        setRemind(dataProduct?.remind?.includes(userData.user.userDetals?._id!)!)
    }, [dataProduct, userData.user.userDetals?._id])

    const finshNumber = (num: number) => {
        const Currency = currency === "ILS" ? "ILS" : currency === "USD" ? "USD" : "JOD"
        if (currency === "ILS") {
            const afterNum = (Number(num) * baseCurrency)
            return (afterNum.toFixed(2)) + " " + Currency
        }
        else {
            return (Number(num) * baseCurrency).toFixed(2) + " " + Currency
        }
    }

    const shareProduct = () => {
        const productUrl = window.location.href; // يحصل على رابط الصفحة الحالية
        const productText = "🔹 شاهد هذا المنتج الرائع: ";

        if (navigator.share) {
            // إذا كان الجهاز يدعم Web Share API (هاتف)
            navigator.share({
                title: "📢 منتج رائع متوفر الآن!",
                text: productText,
                url: productUrl
            })
                .catch((error) => console.log("حدث خطأ:", error));
        } else {
            // إذا كان الجهاز لا يدعم Web Share API (كمبيوتر)، استخدم روابط المشاركة المباشرة
            const shareOptions = `
                <a href="https://api.whatsapp.com/send?text=${encodeURIComponent(productText + productUrl)}" target="_blank">📲 واتساب</a> |
                <a href="https://twitter.com/intent/tweet?text=${encodeURIComponent(productText + productUrl)}" target="_blank">🐦 تويتر</a> |
                <a href="https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(productUrl)}" target="_blank">📘 فيسبوك</a> |
                <a href="https://t.me/share/url?url=${encodeURIComponent(productUrl)}&text=${encodeURIComponent(productText)}" target="_blank">📢 تيليجرام</a>
            `;

            // عرض خيارات المشاركة للمستخدم
            document.body.innerHTML += `<div style="margin-top: 10px;">${shareOptions}</div>`;
        }
    }

    return (<>
        <div style={{ padding: "10px 10px 0 10px", display: "flex", flexDirection: "column", gap: 10 }}>
            <div style={{ fontWeight: 700, fontSize: 22 }}><Langu {...data.product!} /></div>
            {/* {data.categorys &&
                Object.entries(data.categorys).map(([key, value]) => (
                    <div style={{ display: "flex", alignItems: "center" }} key={key}>
                        <p style={{ fontSize: 20 }}>{key} :</p>  <p style={{ padding: "0 5px" }}> {value} </p>
                    </div>
                ))
            } */}
            <div style={{ width: "100%", fontSize: "18px" }}>
                {
                    data.newPrice ?
                        <div style={{ display: "flex", width: "100%", justifyContent: "start", gap: '20px', fontWeight: 500, flexDirection: "column" }}>
                            <div>
                                {t('Market.price')} :
                            </div>
                            <div style={{ display: "flex", gap: 10 }}>
                                <div style={{ textDecoration: "line-through", color: "black", fontWeight: 700, fontSize: 25 }}>
                                    {finshNumber(data.price!)}
                                </div>
                                <div style={{ color: "red", fontWeight: 700, fontSize: 25 }}>
                                    {t('Market.newPrice')} : {finshNumber(data.newPrice!)}
                                </div>
                            </div>
                        </div>
                        :
                        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                            <div style={{ textAlign: "start", color: "black", fontWeight: 700 }}>
                                {t('Market.price')} :
                            </div>
                            <div style={{ textAlign: "start", color: "black", fontWeight: 700, fontSize: 25 }}>
                                {finshNumber(data.price!)}
                            </div>
                        </div>
                }
            </div>
            <div style={{ display: "flex" }}>
                {data.colors?.length! > 0 && data.colors?.map((color: string, index) => (
                    <Button
                        key={index}
                        onClick={() => setColorActive(color)}
                        style={{ backgroundColor: color, minWidth: 25, minHeight: 25, border: "1px solid #dddddd", margin: "10px 5px", boxShadow: colorActive === color ? `0px 0px 10px 5px ${colorActive}` : '' }}>
                    </Button>
                ))}
            </div>
            <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column" }}>
                <Form.Label style={{ fontWeight: 500 }}>{t("Market.If you have any comments")} :</Form.Label>
                <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "grid", gap: "10px" }}>
                    <Form.Control as="textarea" placeholder={t("Market.If you have any comments")} onChange={e => { setComments(e.target.value) }} value={comments} />
                </Form.Group>
            </div>
        </div>
        <div>
            <CompleteInformation data={dataProduct!} />
        </div>
        <div style={{ padding: 10, display: "flex", justifyContent: "space-around", margin: "20px 0" }}>
            <div>
                {quantityItem === 0 ?
                    data?.disable ?
                        remind ?
                            <Button bsPrefix="BtnBNB" disabled={disableBtnRemind} onClick={BtnRemind} >{t("Market.You will be reminded when available")}</Button>
                            :
                            <Button bsPrefix="BtnBNB" disabled={disableBtnRemind} onClick={BtnRemind} >{t("Market.Remind me when available")}</Button>
                        :
                        <Button bsPrefix="BtnBNB" onClick={() => dispatch(incrementItems({ quantity: 1, data, userId: userData.user.userDetals?._id!, color: colorActive, comments }))} >{t("Market.add to cart")}</Button>
                    :
                    <div style={{ display: "flex", gap: 5 }}>
                        <Button bsPrefix="BtnBNB" onClick={() => dispatch(incrementItems({ quantity: 1, data, userId: userData.user.userDetals?._id!, color: colorActive }))} disabled={quantityItem === data?.number}><FaPlus /></Button>
                        <div style={{ fontSize: "25px", width: "30px", textAlign: "center" }}>
                            {quantityItem}
                        </div>
                        <Button bsPrefix="BtnBNB" onClick={() => dispatch(decrementItems({ quantity: 1, data, userId: userData.user.userDetals?._id!, color: colorActive }))} ><FaMinus /></Button>
                    </div>
                }
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 30 }}>
                <div>
                    {favorite ?
                        <div>
                            <Button bsPrefix="BtnBNBIconFavorite" onClick={AddFavorite}><FaHeart size={"23px"} /></Button>
                        </div>
                        :
                        <div>
                            <Button bsPrefix="BtnBNBIconFavorite" onClick={AddFavorite}><FaRegHeart size={"23px"} /></Button>
                        </div>
                    }
                </div>
                <div>
                    <Button bsPrefix="BtnBNBIconFavorite" onClick={shareProduct}><FaShareAlt size={"23px"} /></Button>
                </div>
            </div>
        </div>
        <div>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{t("Market.Shipping and Returns")} </Accordion.Header>
                    <Accordion.Body>
                        شبسيشبسش سيش بشسي بشسي بيس بسيب شسيب سشيب شسيب شسيب شسيب سشيب سشيب شسيبسشيبصثب سيشب شسيب سشيب سشبصثضب سيش بقشثصبلسيش  بشب صثبسيب سشيب شصثبشسيب سشيبسيبصشث بسيش
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>


        <Alert show={showAlertAdd} variant='success' onClose={() => setShowAlertAdd(false)} style={{ position: "fixed", top: "90px", right: "50px", zIndex: 10 }} dismissible>
            <Alert.Heading>{t("Market.Added to favourites")}</Alert.Heading>
            {t("Market.The product has been added to favourites.")}
        </Alert>
        <Alert show={showAlertDis} variant='success' onClose={() => setShowAlertDis(false)} style={{ position: "fixed", top: "90px", right: "50px", zIndex: 10 }} dismissible>
            <Alert.Heading>{t("Market.Removed to favourites")}</Alert.Heading>
            {t("Market.Product removed to favourites")}
        </Alert>
    </>)
}

export default InfoProductClient;