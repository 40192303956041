import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { Button, Form, Modal } from "react-bootstrap";
import ModaleChangePassword from "./ModaleChangePassword";
import ModaleChangeEmail from "./ModaleChangeEmail";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { LoadingBtn } from "../../components/laoding/laoding";
import img from '../../files/HomeGuest/9.png'
import { UsersProps } from "../../Interfaces";
import { OrderContext } from "../../components/context/OrderContext";
import { setUser } from "../../redux/userSlice";


const AccountDetals: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData)
    let token = userData.user.token
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const ordercontext = useContext(OrderContext)
    const { isMobile } = ordercontext!
    const [dataUser, setDataUser] = useState<UsersProps>()
    const [showModaleChangePassword, setShowModalChangePassword] = useState<boolean>(false)
    const [showModaleDeleteAccount, setShowModalDeleteAccount] = useState<boolean>(false)
    const [showModaleChangeEmail, setShowModalChangeEmail] = useState<boolean>(false)
    const [userName, setUserName] = useState<string>()
    const [location, setLocation] = useState<string>()
    const [phone, setPhone] = useState<number | undefined>()
    const [statusBtnChange, setStatusBtnChange] = useState<any>(t("Home.Account.change"))
    const [statusBtnDeleteAccount, setStatusDeleteAccount] = useState<any>(t("Hedar.Delete account"))
    const [disabledBtn, setDisabledBtn] = useState<boolean>(false)
    const [disabledBtnDeleteAccount, setDisabledBtnDeleteAccount] = useState<boolean>(false)


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${userData.user.userDetals?._id}`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => setDataUser(doc.data))
            .catch((err) => console.log(err))
    }, [userData.user.userDetals?._id, userData.user.token])

    useEffect(() => {
        setUserName(dataUser?.userName!)
        setLocation(dataUser?.address?.country)
        setPhone(dataUser?.phone)
    }, [dataUser])

    const BtnChangeAccount = async () => {
        setStatusBtnChange(<LoadingBtn />)
        setDisabledBtn(true)
        await axios.put(`${process.env.REACT_APP_URL_API}/API/Users/PutUserId/${userData.user.userDetals?._id}`, { userName, phone, location },
            {
                headers: {
                    Authorization: "Bearar " + token,
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtnChange(t("Home.Account.change"))
                setDisabledBtn(false)
            })
    }

    const BtnDeleteAccount = async () => {
        setStatusDeleteAccount(<LoadingBtn />)
        setDisabledBtnDeleteAccount(true)
        await axios.delete(`${process.env.REACT_APP_URL_API}/API/Users/DeleteUser/${userData.user.userDetals?._id}`,
            {
                headers: {
                    Authorization: "Bearar " + token,
                }
            })
            .then(() => {
                dispatch(setUser({ refreshToken: undefined, token: undefined, userDetals: undefined }))
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusDeleteAccount(t("Hedar.Delete account"))
                setDisabledBtnDeleteAccount(false)
                setShowModalDeleteAccount(false)
            })
    }

    return (<>
        <div style={{ background: isMobile ? `url(${img}) no-repeat center` : `url(${img}) no-repeat center center`, backgroundSize: isMobile ? "150% 100%" : 'cover', minHeight: "20vh", width: '100%' }}>
            {/* <div className="nameOffer" style={{ top: "calc(10vh + 60px)" }}>{t("Home.ForYouMyLady")}</div> */}
        </div>
        <div style={{ display: "flex", justifyContent: "center", fontSize: "18px" }}>
            <div style={{ maxWidth: "1040px", width: "100%", padding: "20px", border: "1px solid #dddddd", borderRadius: "20px", margin: isMobile ? "50px 20px" : "50px", display: 'flex', flexDirection: 'column', gap: "5px", alignItems: "center" }}>
                <div style={{ width: "100%", maxWidth: "700px", textAlign: "center" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.name" >
                        <Form.Label>user name :</Form.Label>
                        <Form.Control style={{ textAlign: "center" }} type="text" placeholder="user name" autoFocus onChange={e => { setUserName(e.target.value) }} defaultValue={dataUser?.userName} />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px", textAlign: "center" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Category" style={{ width: "100%" }}>
                        <Form.Label>{t("Home.location")} :</Form.Label>
                        <Form.Select style={{ textAlign: "center" }} onChange={e => { setLocation(e.target.value) }} value={location}>
                            <option value={"West Bank"}>{t("Home.West Bank")}</option>
                            <option value={"Inside"}>{t("Home.Inside")}</option>
                            <option value={"Jordan"}>{t("Home.Jordan")}</option>
                        </Form.Select>
                    </Form.Group>
                </div>
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", width: "100%", maxWidth: 700 }}>
                    <div>
                        {t("Home.email")} : {dataUser?.email}
                    </div>
                    <div>
                        <Button bsPrefix="BrandShoppBrand" onClick={() => setShowModalChangeEmail(true)} style={{ backgroundColor: "white", fontSize: "16px", color: 'blue' }}>
                            {t("Home.Account.change")}
                        </Button>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", width: "100%", maxWidth: 700 }}>
                    <div>
                        {t("Hedar.Password")} : *********
                    </div>
                    <div>
                        <Button bsPrefix="BrandShoppBrand" onClick={() => setShowModalChangePassword(true)} style={{ backgroundColor: "white", fontSize: "16px", color: 'blue' }}>
                            {t("Home.Account.change")}
                        </Button>
                    </div>
                </div>
                <div style={{ width: "100%", maxWidth: "700px", textAlign: "center" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Phone">
                        <Form.Label>Phone :</Form.Label>
                        <Form.Control type="NUMBER" style={{ textAlign: "center" }} placeholder="Phone" onChange={e => { setPhone(parseFloat(e.target.value) || undefined) }} defaultValue={dataUser?.phone} />
                    </Form.Group>
                </div>
                <div>
                    <Button bsPrefix="BtnBNB" disabled={disabledBtn} onClick={BtnChangeAccount}>{statusBtnChange}</Button>
                </div>
                <div>
                    <Button variant="danger" onClick={() => setShowModalDeleteAccount(true)}>{t("Hedar.Delete account")}</Button>
                </div>
            </div>
            <ModaleChangePassword show={showModaleChangePassword} setShowModalChangePassword={setShowModalChangePassword} userId={dataUser?._id!} />
            <ModaleChangeEmail show={showModaleChangeEmail} setShowModalChangeEmail={setShowModalChangeEmail} />
            <Modal show={showModaleDeleteAccount} onHide={() => setShowModalDeleteAccount(false)}>
                <Modal.Header className="CloseBtn" closeButton>
                    <Modal.Title>
                        {t("Hedar.Delete account")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t("Hedar.When you delete your account")}
                    {/* When you delete your account, all your personal data associated with the account will be permanently removed from our servers within a maximum period of 30 days. This includes your email address, name, and usage history.

                    Please note that this action is irreversible.

                    If you encounter any issues during the deletion process, you can contact us at: [support@example.com]
                    عند حذف الحساب، سيتم إزالة جميع بياناتك الشخصية المرتبطة بالحساب بشكل نهائي من خوادمنا خلال فترة لا تتجاوز 30 يومًا، بما في ذلك البريد الإلكتروني، الاسم، وسجل الاستخدام.

                    يرجى ملاحظة أن هذا الإجراء لا يمكن التراجع عنه.

                    إذا واجهت أي مشكلة أثناء عملية الحذف، يمكنك التواصل معنا عبر البريد الإلكتروني: [support@hldshtech.com] */}
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="danger" disabled={disabledBtnDeleteAccount} onClick={BtnDeleteAccount}>{statusBtnDeleteAccount}</Button>
                    <Button variant="secondary" onClick={() => setShowModalDeleteAccount(false)}>
                        {t("Hedar.Close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    </>)
}

export default AccountDetals;