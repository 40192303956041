import { Offcanvas } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { useTranslation } from "react-i18next";
import SearchBar from "../../components/searchBar/SearchBar";

interface SearchProp {
    show: boolean;
    setShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
}


const Search: React.FC<SearchProp> = ({ show, setShowSearch }) => {
    const { t } = useTranslation()
    return (<>
        <Offcanvas show={show} onHide={() => setShowSearch(false)} style={{ maxWidth: "350px" }}>
            <Offcanvas.Header className="CloseBtn" style={{ display: 'flex', justifyContent: "space-between", borderBottom: "1px solid #e1e1e1" }} closeButton>
                <Offcanvas.Title>{t("Hedar.Search")}</Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body>
                <Form style={{ borderBottom: "1px solid #e1e1e1", justifyContent: "center", display: "flex" }}>
                    {/* تمرير دالة setSearchResults إلى SearchBar */}
                    <SearchBar />
                </Form>
            </Offcanvas.Body>
        </Offcanvas >
    </>)
}

export default Search;